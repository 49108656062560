import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class CarbonOpportunityResolver extends BaseResolver {
  constructor () {
    super('carbonopportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      BDMOpportunityId: item.BDMOpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      OpportunityStage: item.OpportunityStage,
      OpportunityStageName: item.OpportunityStageName,
      OpportunityOwnerId: item.OpportunityOwnerId,
      OpportunityOwnerName: item.OpportunityOwnerName,
      CarbonOpportunityId: item.CarbonOpportunityId,
      Probability: item.Probability,
      ClosedDate: item.ClosedDate,
      OpportunityCurrency: item.OpportunityCurrency,
      NewCNBNumber: item.NewCNBNumber,
      ProfitValue: item.ProfitValue,
      TotalExpense: item.TotalExpense,
      TotalProjectCost: item.TotalProjectCost,
      NetProfit: item.NetProfit,
      Service: item.Service,
      SiteDetails: item.SiteDetails,
      NetMargin: item.NetMargin,
      PO: item.PO,
      PONumber: item.PONumber,
      Fee: item.Fee,
      ReasonForLoss: item.ReasonForLoss,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,
      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
