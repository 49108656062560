import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class SECROpportunityResolver extends BaseResolver {
  constructor () {
    super('secropportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      BDMOpportunityId: item.BDMOpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      OpportunityStage: item.OpportunityStage,
      OpportunityStageName: item.OpportunityStageName,
      OpportunityOwnerId: item.OpportunityOwnerId,
      OpportunityOwnerName: item.OpportunityOwnerName,
      SECROpportunityId: item.SECROpportunityId,
      Probability: item.Probability,
      NewCNBNumber: item.NewCNBNumber,
      CNBNumber: item.CNBNumber,
      OpportunityCurrency: item.OpportunityCurrency,
      ProfitValue: item.ProfitValue,
      ClosedDate: item.ClosedDate,
      TotalExpense: item.TotalExpense,
      TotalProjectCost: item.TotalProjectCost,
      NetProfit: item.NetProfit,
      NoOfSites: item.NoOfSites,
      EsimatedNoOfSources: item.EsimatedNoOfSources,
      ServiceLevel: item.ServiceLevel,
      ReportingInterval: item.ReportingInterval,
      OtherServices: item.OtherServices,
      FYFromDate: item.FYFromDate,
      FYEndDate: item.FYEndDate,
      AcceptanceSignedDate: item.AcceptanceSignedDate,
      EstimatedBudget: item.EstimatedBudget,
      ServiceInYears: item.ServiceInYears,
      ReasonForLoss: item.ReasonForLoss,           
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,

      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
