
export const util = {

  convertToApiDate (date) {
    if (!date) {
      date = new Date()
    }
    if (typeof date !== 'object') {
      return date
    }
    const month = (date.getMonth() + 1).toString()
    const year = date.getFullYear().toString()
    const day = date.getDate().toString()
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  },

  convertTo4y2m2dDate (date) {
    debugger
    if (!date) {
      date = new Date()
    }
    if (typeof date !== 'object') {
      return date.substring(0,10)
    }
    debugger
    const month = (date.getMonth() + 1).toString()
    const year = date.getFullYear().toString()
    const day = date.getDate().toString()
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
  },

  convertToApiDateTime (date) {
    if (!date) {
      date = new Date()
    }
    if (typeof date !== 'object') {
      return date
    }
    const month = (date.getMonth() + 1).toString()
    const year = date.getFullYear().toString()
    const day = date.getDate().toString()
    const hour = date.getHours().toString()
    const min = date.getMinutes().toString()
    return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}T${hour.padStart(2, '0')}:${min.padStart(2, '0')}:00.000`
  },

  convertFromApiDate (date) {
    if (typeof date !== 'object') {
      date = new Date(date)
    }
    const dateStr = date.toDateString()
    const monthStr = dateStr.substring(4, 7)

    return `${monthStr} ${date.getDate()}, ${date.getFullYear()}`
  },

  convertFromApiMonth (date) {
    if (typeof date !== 'object') {
      date = new Date(date)
    }
    const dateStr = date.toDateString()
    const monthStr = dateStr.substring(4, 7)

    return `${monthStr} ${date.getFullYear()}`
  },

  convertFromApiDate2 (date) {
    if (typeof date !== 'object') {
      date = new Date(date)
    }
    const dateStr = date.toDateString()
    const monthStr = dateStr.substring(4, 7)

    return `${date.getDate()} ${monthStr} ${date.getFullYear()}`
  },

  convertFromApiDateTime (date) {
    if (typeof date !== 'object') {
      date = new Date(date)
    }
    const dateStr = date.toDateString()
    const monthStr = dateStr.substring(4, 7)
    const am_pm = date.getHours() >= 12 ? 'PM' : 'AM'
    const hour = date.getHours() > 12 ? (date.getHours() - 12).toString().padStart(2, '0') : date.getHours().toString().padStart(2, '0')
    const min = date.getMinutes().toString().padStart(2, '0')

    return `${monthStr} ${date.getDate()}, ${date.getFullYear()} ${hour}:${min}${am_pm}`
  },

  convertToDDMMYYYY (date) {
    if (typeof date !== 'object') {
      date = new Date(date)
    }
    let dd = date.getDate()
    let mm = date.getMonth() + 1 
    const yyyy = date.getFullYear()
    dd = dd < 10 ? '0'+dd : dd
    mm = mm < 10 ? '0'+mm : mm
    return `${dd}/${mm}/${yyyy}`
  },

  getTime (datetime) {
    if (typeof date !== 'object') {
      datetime = new Date(datetime)
    }
    const hour = datetime.getHours().toString().padStart(2, '0')
    const min = datetime.getMinutes().toString().padStart(2, '0')

    return `${hour}:${min}`
  },

  getTime12 (datetime) {
    if (typeof date !== 'object') {
      datetime = new Date(datetime)
    }
    const am_pm = datetime.getHours() >= 12 ? 'PM' : 'AM'
    const hour = datetime.getHours() > 12 ? (datetime.getHours() - 12).toString().padStart(2, '0') : datetime.getHours().toString().padStart(2, '0')
    const min = datetime.getMinutes().toString().padStart(2, '0')

    return `${hour}:${min}${am_pm}`
  },

  getPlainText (htmlContent = '') {
    if (!htmlContent) {
      return ''
    }
    return htmlContent.replace(/(\n)+/g, '').replace(/<style([\s\S]*?)<\/style>/gi, '')
      .replace(/<script([\s\S]*?)<\/script>/gi, '')
      .replace(/<\/div>/ig, '\n')
      .replace(/<\/li>/ig, '\n')
      .replace(/<li>/ig, '  *  ')
      .replace(/<\/ul>/ig, '\n')
      .replace(/<\/p>/ig, '\n')
      .replace(/<table(?:.|\n)+<\/table>/g, '')
      .replace(/<[^>]+>/ig, '')
      .replace(/<(?:.|\n)*?>/gm, '')
      .replace(/&nbsp;/g, ' ')
      .replace(/&euro;/g, '€')
      .replace(/&ndash;/g, '-')
      .replace(/&middot;/g, ' * ')
      .replace(/&mdash;/g, '-')
      .replace(/&iexcl;/g, '¡')
      .replace(/&quot;/g, '"')
      .replace(/&lsquo;/g, '‘')
      .replace(/&rsquo;/g, '’')
      .replace(/&amp;/g, '&')
      .replace(/&pound;/g, '£')
      .replace(/&cent;/g, '¢')
      .replace(/&yen;/g, '¥')
      .replace(/&hellip;/g, '…')
      .replace(/&Delta;/g, 'Δ')
      .replace(/&agrave;/g, 'à')
      .replace(/&aacute;/g, 'á')
      .replace(/&acirc;/g, 'â')
      .replace(/&egrave;/g, 'è')
      .replace(/&eacute;/g, 'é')
      .replace(/&ecirc;/g, 'ê')
      .replace(/&euml;/g, 'ë')
      .replace(/&iacute;/g, 'ì')
      .replace(/&igrave;/g, 'í')
      .replace(/&ntilde;/g, 'ñ')
      .replace(/&deg;/g, '°')
      .replace(/&plusmn;/g, '±')
  },

  groupBy (list, key) {
    return list.reduce((prev, curr) => {
      (prev[curr[key]] = prev[curr[key]] || []).push(curr)
      return prev
    }, {})
  },

  getAddress (street, city, state, postalcode, country) {
    return this.getPlainText([ street, city, state, country, postalcode ].filter(Boolean).join(' ')) 
  },

  getAddressFromGMap (map_result) {
    const address = {
      address: '',
      street: '',
      city: '',
      state: '',
      postalcode: '',
      country: ''
    }
    let street_number = null
    let premise = null
    let locality = null
    
    if (map_result && map_result.place &&  map_result.place.address_components) {
      address.address = map_result.place.formatted_address
      map_result.place.address_components.forEach(item => {
        if (item.types && item.types.includes('street_number')) {
          street_number = item.long_name
        } else if (item.types && item.types.includes('premise')) {
          premise = item.long_name
        } else if (item.types && item.types.includes('route')) {
          address.street = item.long_name
        } else if (item.types && item.types.includes('locality')) {
          locality = item.long_name
        } else if (item.types && item.types.includes('postal_town')) {
          address.city = item.long_name
        } else if (item.types && item.types.includes('administrative_area_level_1')) {
          address.state = item.long_name
        } else if (item.types && item.types.includes('country')) {
          address.country = item.long_name
        } else if (item.types && item.types.includes('postal_code')) {
          address.postalcode = item.long_name
        }
      })
      address.street = street_number ? `${street_number} ${address.street}` : address.street
      address.street = premise ? `${premise}, ${address.street}` : address.street
      address.city = locality ? `${locality} ${address.city}` : address.city
      return address
    } else {
      return null
    }
    
  },

  getMax (array, prop, isDate) {
    if (isDate) {
      return array.reduce((prev, current) => (new Date(prev[prop]) > new Date(current[prop])) ? prev : current)  
    } 
    return array.reduce((prev, current) => (prev[prop] > current[prop]) ? prev : current)
  },

  getRatio (a, b) {
    if (b === 0) {
      return 0
    } else {
      return Number.parseFloat(((a / parseFloat(b)) * 100).toFixed(2))
    }
  },

  deepEqual (x, y) {
    const ok = Object.keys
    const tx = typeof x
    const ty = typeof y
    return x && y && tx === 'object' && tx === ty ? (
      ok(x).length === ok(y).length &&
        ok(x).every(key => this.deepEqual(x[key], y[key]))
    ) : (x === y)
  },

  checkMPAN (mpan) {
    const primes =  [ 3, 5, 7, 13, 17, 19, 23, 29, 31, 37, 41, 43 ]
    let sum = 0

    if (mpan.length > 12) {
      const m = mpan.substring(mpan.length - 13, mpan.length)
      for (let i = 0; i < primes.length; i++) {
        sum += parseInt(m.charAt(i)) * primes[i]
      }
      return sum % 11 % 10 === parseInt(m.charAt(12))
    }
    return false
  },

  addDays (date, days) {
    const result = new Date(date)
    result.setDate(result.getDate() + days)
    return result
  },

  getCurrency (x) {
    return x >= 0 ? `£${ x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') }` : `-£${ x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',').replace('-','') }`
  },


  hexToRgb (hex) {
    const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
    hex = hex.replace(shorthandRegex, (m, r, g, b) => {
      return r + r + g + g + b + b
    })

    const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex)
    return result ? {
      r: parseInt(result[1], 16),
      g: parseInt(result[2], 16),
      b: parseInt(result[3], 16)
    } : null
  },

  lastDayoftheMonth (date) {
    if (typeof date !== 'object') {
      date = new Date(date)
    }
    return new Date(date.getFullYear(), date.getMonth() + 1, 0)
  },

  convertToApiDateNull (date) {
   
    if (typeof date !== 'object') {
      date = new Date(date)
    }
    const dateStr = date.toDateString()
    const monthStr = dateStr.substring(4, 7)
    const dd = date.getDate()
    const mm = date.getMonth() + 1 
    const yyyy = date.getFullYear()
    if (dd == 1 && mm == 1 && yyyy == 1)
    {
      return ''
    }
    else
    {
      return `${monthStr} ${date.getDate()}, ${date.getFullYear()}`
    }
  },
  

}