import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class TenantOpportunityResolver extends BaseResolver {
  constructor () {
    super('tenantopportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      BDMOpportunityId: item.BDMOpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      OpportunityStage: item.OpportunityStage,
      OpportunityStageName: item.OpportunityStageName,
      OpportunityOwnerId: item.OpportunityOwnerId,
      OpportunityOwnerName: item.OpportunityOwnerName,
      TenantOpportunityId: item.TenantOpportunityId,
      NewCNBNumber: item.NewCNBNumber,
      CNBNumber: item.CNBNumber,
      RelatedCustomer: item.RelatedCustomer,
      OpportunityCurrency : item.OpportunityCurrency ,
      ProfitValue: item.ProfitValue,
      ClosedDate: item.ClosedDate,
      Probability: item.Probability,
      TotalExpense: item.TotalExpense,
      TotalProjectCost: item.TotalProjectCost,
      NetProfit: item.NetProfit,
      NoOfBlocks: item.NoOfBlocks,
      NoOfMeters: item.NoOfMeters,
      TypeOfBuilding: item.TypeOfBuilding,
      NoOfProperties: item.NoOfProperties,
      NoOfRelatedSites: item.NoOfRelatedSites,
      Comments: item.Comments,
      AC: item.AC,
      Chilled: item.Chilled,
      ColdWater: item.ColdWater,
      Electricity: item.Electricity,
      Gas: item.Gas,
      Heat: item.Heat,
      HotWater: item.HotWater,
      HeatandHotWater: item.HeatandHotWater,
      ProposalComments: item.ProposalComments,
      SetupFeePerProperty: item.SetupFeePerProperty,
      AdminFeePerMeter: item.AdminFeePerMeter,
      AdminFeePerAdditionalMeter  : item.AdminFeePerAdditionalMeter  ,
      AnnualContractValue: item.AnnualContractValue,
      TotalBillableMeters: item.TotalBillableMeters,
      ContractInvoiceType: item.ContractInvoiceType,
      SiteBillingType: item.SiteBillingType,
      SetupFeeBilliedTo: item.SetupFeeBilliedTo,
      ContractInvoiceCycle: item.ContractInvoiceCycle,
      SiteBillingCycle: item.SiteBillingCycle,
      RelatedContractId: item.RelatedContractId,
      RelatedContract: item.RelatedContract,
      ClientNameOnBills: item.ClientNameOnBills,
      ActionComments: item.ActionComments,
      BackDatedBillingDetails: item.BackDatedBillingDetails,
      BillingStartDate     : item.BillingStartDate     ,
      BackdatedBillingRqd: item.BackdatedBillingRqd,
      MSNListProvided: item.MSNListProvided,
      StartReadingsProvided: item.StartReadingsProvided,
      AccountBalancesProvided: item.AccountBalancesProvided,
      PropertyAddressProvided: item.PropertyAddressProvided,
      ResidentListProvided: item.ResidentListProvided,
      AccountBalanceCarryOverProvided: item.AccountBalanceCarryOverProvided,
      
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,

      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
