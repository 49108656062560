import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class BusinessOpportunityResolver extends BaseResolver {
  constructor () {
    super('businessbpportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      CROpportunityId: item.CROpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      BusinessOpportunityId: item.BusinessOpportunityId,
      CNBNumber: item.CNBNumber,
      MajesticGroup: item.MajesticGroup,
      Type: item.Type,
      LeadSource: item.LeadSource,
      Probability: item.Probability,
      ClosedDate: item.ClosedDate,
      OpportunityCurrency: item.OpportunityCurrency,
      ProfitValue: item.ProfitValue,
      TotalExpense: item.TotalExpense,
      TotalProjectCost: item.TotalProjectCost,
      NetProfit: item.NetProfit,
      ElecProcurement: item.ElecProcurement,
      GasProcurement: item.GasProcurement,
      WaterProcurement: item.WaterProcurement,
      ElecSAM: item.ElecSAM,
      GasSAM: item.GasSAM,
      WaterSAM: item.WaterSAM,
      ElecAuditRecovery: item.ElecAuditRecovery,
      GasAuditRecovery: item.GasAuditRecovery,
      WaterAuditRecovery: item.WaterAuditRecovery,
      ElecCostMgmt: item.ElecCostMgmt,
      GasCostMgmt: item.GasCostMgmt,
      WaterCostMgmt: item.WaterCostMgmt,
      ElecSupplierMgmt: item.ElecSupplierMgmt,
      GasSupplierMgmt: item.GasSupplierMgmt,
      WaterSupplierMgmt: item.WaterSupplierMgmt,
      ElecPerformMonitor: item.ElecPerformMonitor,
      GasPerformMonitor: item.GasPerformMonitor,
      WaterPerformMonitor: item.WaterPerformMonitor,
      ElecFixedProcurement: item.ElecFixedProcurement,
      GasFixedProcurement: item.GasFixedProcurement,
      WaterFixedProcurement: item.WaterFixedProcurement,
      ElecRiskProcurement: item.ElecRiskProcurement,
      GasRiskProcurement: item.GasRiskProcurement,
      WaterRiskProcurement: item.WaterRiskProcurement,
      ElecBureauServices: item.ElecBureauServices,
      GasBureauServices: item.GasBureauServices,
      WaterBureauServices: item.WaterBureauServices,
      ElecCarbonMgmt: item.ElecCarbonMgmt,
      GasCarbonMgmt: item.GasCarbonMgmt,
      WaterCarbonMgmt: item.WaterCarbonMgmt,
      ElecCompAudits: item.ElecCompAudits,
      GasCompAudits: item.GasCompAudits,
      WaterCompAudits: item.WaterCompAudits,
      ElecEffAudits: item.ElecEffAudits,
      GasEffAudits: item.GasEffAudits,
      WaterEffAudits: item.WaterEffAudits,
      ElecMktIntelligence: item.ElecMktIntelligence,
      GasMktIntelligence: item.GasMktIntelligence,
      WaterMktIntelligence: item.WaterMktIntelligence,
      ElecEPBD: item.ElecEPBD,
      GasEPBD: item.GasEPBD,
      WaterEPBD: item.WaterEPBD,
      CurrentProvider: item.CurrentProvider,
      EstimatedBudget: item.EstimatedBudget,
      KeyChallenges: item.KeyChallenges,
      BDMEngaged: item.BDMEngaged,
      ProposalIssued: item.ProposalIssued,
      ClientSignedDate: item.ClientSignedDate,
      PONumber: item.PONumber,
      NetMargin: item.NetMargin,
      AgreedServiceStartDate: item.AgreedServiceStartDate,
      AgreedServiceEndDate: item.AgreedServiceEndDate,
      ReasonForWon: item.ReasonForWon,
      ReasonForLoss: item.ReasonForLoss,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,
      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
