import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class BDMOpportunityResolver extends BaseResolver {
  constructor () {
    super('bdmopportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      BDMOpportunityId: item.BDMOpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      OpportunityStage: item.OpportunityStage,
      OpportunityStageName: item.OpportunityStageName,
      OpportunityOwnerId: item.OpportunityOwnerId,
      OpportunityOwnerName: item.OpportunityOwnerName,
      Probability: item.Probability,
      ClosedDate: item.ClosedDate,
      OpportunityCurrency: item.OpportunityCurrency,
      NewCNBNumber: item.NewCNBNumber,
      NetMargin: item.NetMargin,
      ProfitValue: item.ProfitValue,
      TotalExpense: item.TotalExpense,
      TotalProjectCost: item.TotalProjectCost,
      NetProfit: item.NetProfit,
      ProposedServiceStartDate: item.ProposedServiceStartDate,
      ProposedServiceEndDate: item.ProposedServiceEndDate,
      ProposedNetProfitValue: item.ProposedNetProfitValue,
      ProposedNetContractValue: item.ProposedNetContractValue,
      ProposedMargin: item.ProposedMargin,     
      ProposedProcurement: item.ProposedProcurement,
      ProposedMarketIntelligence: item.ProposedMarketIntelligence,
      ProposedBureauandBillValidation: item.ProposedBureauandBillValidation,
      ProposedEnergyandCarbon: item.ProposedEnergyandCarbon,
      ProposedTMac: item.ProposedTMac,
      ProposedGroupServices: item.ProposedGroupServices,
      ActionProcurement: item.ActionProcurement,
      ActionMarketIntelligence: item.ActionMarketIntelligence,
      ActionBureauandBillValidation: item.ActionBureauandBillValidation,
      ActionEnergyandCarbon: item.ActionEnergyandCarbon,
      ActionTMac: item.ActionTMac,
      ActionGroupServices: item.ActionGroupServices,
      AgreedServiceStartDate: item.AgreedServiceStartDate,
      AgreedServiceEndDate: item.AgreedServiceEndDate,
      ClientSignedDate: item.ClientSignedDate,
      AgreedNetProfitValue: item.AgreedNetProfitValue,
      AgreedNetContractValue: item.AgreedNetContractValue,
      AgreedMargin: item.AgreedMargin,
      ReasonForLoss: item.ReasonForLoss,

      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,

      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
