var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal", class: _vm.isOpen ? "is-active" : "" },
    [
      _c("div", {
        staticClass: "modal-background",
        on: {
          click: function($event) {
            return _vm.$emit("closeModal")
          }
        }
      }),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "modal-card",
          style: _vm.width == 640 ? "" : "width:" + _vm.width + "px"
        },
        [
          _c("header", { staticClass: "modal-card-head p-md" }, [
            _c("p", { staticClass: "modal-card-title sub-title" }, [
              _vm._v("\n        " + _vm._s(_vm.title) + "\n      ")
            ]),
            _vm._v(" "),
            _vm.closeButton
              ? _c("button", {
                  staticClass: "delete",
                  on: {
                    click: function($event) {
                      return _vm.$emit("closeModal")
                    }
                  }
                })
              : _vm._e()
          ]),
          _vm._v(" "),
          _c(
            "section",
            { staticClass: "modal-card-body" },
            [_vm._t("body")],
            2
          ),
          _vm._v(" "),
          _vm.showFooter
            ? _c("footer", { staticClass: "modal-card-foot p-sm" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "columns flex-wrap" }, [
                    _c(
                      "div",
                      { staticClass: "column is-full text-right" },
                      [_vm._t("footer")],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "column is-full p-t-0" },
                      [
                        _vm.error
                          ? _c("Error", { attrs: { message: _vm.error } })
                          : _vm._e()
                      ],
                      1
                    )
                  ])
                ])
              ])
            : _vm._e()
        ]
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }