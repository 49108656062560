var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "loading" }, [
    _c("div", { staticClass: "loading-overlay" }),
    _vm._v(" "),
    _c("div", { staticClass: "loading-overlay-container" }, [
      _c("div", { staticClass: "loading-overlay-message" }, [
        _vm._v("\n      " + _vm._s(_vm.message) + "\n      "),
        _vm._m(0)
      ])
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "page-spinner-bar" }, [
      _c("div", { staticClass: "bounce1" }),
      _vm._v(" "),
      _c("div", { staticClass: "bounce2" }),
      _vm._v(" "),
      _c("div", { staticClass: "bounce3" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }