var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "feeback-module" }, [
    _c("div", { staticClass: "feedBackBtn" }, [
      _vm.showfeedBackBtn
        ? _c(
            "button",
            {
              staticClass: "button is-info is-small",
              on: {
                click: function($event) {
                  return _vm.openFeedBack()
                }
              }
            },
            [_vm._v("\n      Send FeedBack\n    ")]
          )
        : _vm._e()
    ]),
    _vm._v(" "),
    _vm.step1
      ? _c(
          "div",
          {
            ref: "draggableContainer",
            staticClass: "p-lg",
            attrs: { id: "draggable-container" }
          },
          [
            _c(
              "section",
              {
                staticClass: "modal-card-body",
                on: { mousedown: _vm.dragMouseDown }
              },
              [
                _c("div", [
                  _c("button", {
                    staticClass: "delete is-pulled-right",
                    on: {
                      click: function($event) {
                        return _vm.closeModal()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "feedback-logo" }, [
                    _vm._v("\n          FeedBack\n        ")
                  ])
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    "Click and drag on the page to help us better understand your feedback. You can move this dialog if it's in the way."
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-small",
                    class: _vm.isHighlight ? "is-info" : "",
                    on: {
                      click: function($event) {
                        return _vm.setMode()
                      }
                    }
                  },
                  [_vm._m(0)]
                ),
                _vm._v(" "),
                _c("label", [
                  _vm._v("Highlight areas relevant to your feedback.")
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button is-small",
                    class: !_vm.isHighlight ? "is-info" : "",
                    on: {
                      click: function($event) {
                        return _vm.setMode()
                      }
                    }
                  },
                  [_vm._m(1)]
                ),
                _vm._v(" "),
                _c("label", { staticClass: "lower" }, [
                  _vm._v(
                    "\n        Black out any personal information.\n      "
                  )
                ]),
                _vm._v(" "),
                _c("br"),
                _vm._v(" "),
                _c("div", [
                  _c(
                    "button",
                    {
                      staticClass: "button is-small is-primary is-pulled-right",
                      on: {
                        click: function($event) {
                          return _vm.next()
                        }
                      }
                    },
                    [_vm._v("\n          Next\n        ")]
                  ),
                  _vm._v(" "),
                  _c("div")
                ])
              ]
            )
          ]
        )
      : _vm._e(),
    _vm._v(" "),
    _c("div", { staticClass: "modal", class: _vm.step2 ? "is-active" : "" }, [
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "modal-card", staticStyle: { width: "900px" } },
        [
          _c("section", { staticClass: "modal-card-body" }, [
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column" }, [
                _c("div", [
                  _c("button", {
                    staticClass: "delete is-pulled-right",
                    on: {
                      click: function($event) {
                        return _vm.closeModal()
                      }
                    }
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "feedback-logo" }, [
                    _vm._v("\n                FeedBack\n              ")
                  ])
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c("div", { staticClass: "column" }, [
                _c("div", { staticClass: "field" }, [
                  _vm._m(2),
                  _vm._v(" "),
                  _c("div", { attrs: { dclass: "control" } }, [
                    _c("textarea", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.model.description,
                          expression: "model.description"
                        }
                      ],
                      staticStyle: { width: "100%" },
                      attrs: { rows: "4", maxlength: "1000" },
                      domProps: { value: _vm.model.description },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.model,
                            "description",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _vm._m(3),
                _vm._v(" "),
                _vm._m(4),
                _vm._m(5),
                _vm._m(6)
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "column" }, [
                _vm._m(7),
                _vm._v(" "),
                _c("div", [_c("img", { attrs: { src: _vm.imgUri } })])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "columns" }, [
              _c(
                "div",
                {
                  staticClass: "column",
                  staticStyle: { "margin-bottom": "20px" }
                },
                [
                  _c(
                    "button",
                    {
                      staticClass: "button is-small is-primary is-pulled-right",
                      attrs: { disabled: !_vm.isValid },
                      on: {
                        click: function($event) {
                          return _vm.submit()
                        }
                      }
                    },
                    [_vm._v("\n              Submit\n            ")]
                  ),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button is-small is-primary is-pulled-right",
                      staticStyle: { "margin-right": "20px" },
                      on: {
                        click: function($event) {
                          return _vm.closeModal()
                        }
                      }
                    },
                    [_vm._v("\n              Close\n            ")]
                  ),
                  _vm._v(" "),
                  _c("br")
                ]
              )
            ])
          ])
        ]
      )
    ])
  ])
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-eraser" }),
      _vm._v("\n          Highlight\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", [
      _c("i", { staticClass: "fa fa-pencil" }),
      _vm._v("\n          Black out\n        ")
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("label", [_c("b", [_vm._v("Description")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Additional info")])])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "feedback-browser-info" } }, [
      _c("span", [_vm._v("Browser Info")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "feedback-page-info" } }, [
      _c("span", [_vm._v("Page Info")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { attrs: { id: "feedback-timestamp" } }, [
      _c("span", [_vm._v("Time Stamp")])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", [_c("b", [_vm._v("Screenshot")])])
  }
]
render._withStripped = true

export { render, staticRenderFns }