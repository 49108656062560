import { BaseResolver } from './BaseResolver'
import { util } from '../util'

export class ElecHHTariffResolver extends BaseResolver {
  constructor () {
    super('elecHHTariff')
  }

  resolve (item) {
    return {
      TariffId: item.TariffId,
      ElecDetailId: item.ElecDetailId, 
      AccountId: item.AccountId,
      CustomerId: item.CustomerId,
      LocationId: item.LocationId,
      DetailId: item.DetailId,
      CNB: item.CNB,
      Supplier: item.Supplier,
      AccountNo: item.AccountNo,
      SiteRef: item.SiteRef,
      StartDate: item.StartDate ? new Date(item.StartDate) : null,
      EndDate: item.EndDate ? new Date(item.EndDate) : null,
      RegistrationDate: item.RegistrationDate ? new Date(item.RegistrationDate) : null,
      ContractType: item.ContractType,
      CCL: item.CCL,
      Standing: item.Standing,
      StandingPeriod: item.StandingPeriod,
      Vat1: item.Vat1,
      Vat2: item.Vat2,
      VatExemption: item.VatExemption,
      EnergyType: item.EnergyType,
      PaymentType: item.PaymentType,
      PaymentDuration: item.PaymentDuration,
      DUoS: item.DUoS,
      TUoS: item.TUoS,
      TUoSLoss: item.TUoSLoss,
      TUoSApplyDUoS: item.TUoSApplyDUoS,
      TUoSLossPercentage: item.TUoSLossPercentage,
      RO: item.RO,
      ROCharge: item.ROCharge,
      FIT: item.FIT,
      // FITCharge: item.FITCharge,
      FITChargeType: item.FITChargeType,
      AgreedAvailabilitykVa: item.AgreedAvailabilitykVa,
      AvailabilityCharge: item.AvailabilityCharge,
      CapacityChargeType: item.CapacityChargeType,
      ReactiveCharge: item.ReactiveCharge,
      ExtraAvailabilityCharge: item.ExtraAvailabilityCharge,
      ApplyEMRCharges: item.ApplyEMRCharges,
      CMSettlementLevy: item.CMSettlementLevy,
      CMSettlementLevyPeriod: item.CMSettlementLevyPeriod,
      CMObligationLevy: item.CMObligationLevy,
      CMObligationLevyPeriod: item.CMObligationLevyPeriod,
      UnitCharges: (item.UnitCharges || []),
      FixedCharges: (item.FixedCharges || []).map(f => {
        return {
          ChargeId: f.ChargeId,
          TariffId: f.TariffId,
          Code: f.Code,
          ChargeName: f.ChargeName,
          Type: f.Type,
          Price: f.Price,
          Period: f.Period,
          Periods: (f.Periods || []).map( p => {
            return {
              PeriodId: p.PeriodId,
              ChargeId: p.ChargeId,
              PeriodFrom: new Date(p.PeriodFrom),
              PeriodTo: new Date(p.PeriodTo),
              Price: p.Price
            }
          })
        }
      }),
      EMRCharges: (item.EMRCharges || []),
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdatedDate,
      BillExists: item.BillExists || false,
      BillExistsDate: new Date(item.BillExistsDate),
      ReconRO: item.ReconRO,
      ReconROFrequency: item.ReconROFrequency || 1,
      ReconROMonth: item.ReconROMonth || 1,
      ReconTUoS: item.ReconTUoS,
      ReconTUoSFrequency: item.ReconTUoSFrequency || 1,
      ReconTUoSMonth: item.ReconTUoSMonth || 1,
      ReconFIT: item.ReconFIT,
      ReconFITFrequency: item.ReconFITFrequency || 1,
      ReconFITMonth: item.ReconFITMonth || 1,
      ReconCMSSL: item.ReconCMSSL,
      ReconCMSSLFrequency: item.ReconCMSSLFrequency || 1,
      ReconCMSSLMonth: item.ReconCMSSLMonth || 1,
      ReconBSUoS: item.ReconBSUoS,
      ReconBSUoSFrequency: item.ReconBSUoSFrequency || 1,
      ReconBSUoSMonth: item.ReconBSUoSMonth || 1,
      ReconCMSEL: item.ReconCMSEL,
      ReconCMSELFrequency: item.ReconCMSELFrequency || 1,
      ReconCMSELMonth: item.ReconCMSELMonth || 1,
    }
  }

  getMeters (item) {
    const meters = []
    item.UnitCharges.map(uc => {
      if (meters.filter(x => x.MpanId === uc.MpanId).length === 0) {
        meters.push({
          MpanId: uc.MpanId,
          Mpan: uc.Mpan,
          UnitCharges: item.UnitCharges.filter(y => y.MpanId === uc.MpanId),
          isdisabled: true
        })
      }
    })
    return meters
  }
}
