var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("footer", [
    _c("div", { staticClass: "container" }, [
      _c("div", [
        _vm._v(
          "\n      " +
            _vm._s(new Date().getFullYear()) +
            " © All rights reserved\n       "
        ),
        _c("a", { attrs: { target: "_blank", href: "http://eic.co.uk/" } }, [
          _vm._v("\n        EIC Partnership Ltd.\n      ")
        ]),
        _vm._v("  | \n      Powered by "),
        _c("a", { attrs: { href: "http://eic.co.uk/", target: "_blank" } }, [
          _vm._v("\n        EIC\n      ")
        ])
      ])
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }