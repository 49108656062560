// Import Vue
import Vue from 'vue'
import VueRouter from 'vue-router'
// import VueExcelEditor from  'vue-excel-editor'

import store from './vuex/index.js'
import { http, util } from './services'
import VLoading from './components/shared/elements/Loading.vue'
import VPanel from './components/shared/elements/Panel.vue'
import VTable from './components/shared/elements/Table.vue'
import VSimpleTable from './components/shared/elements/SimpleTable.vue'
import VTimeline from './components/shared/elements/Timeline.vue'
import AppHeader from './components/shared/AppHeader.vue'
import AppFooter from './components/shared/AppFooter.vue'
import IdleVue from 'idle-vue'
import VueGmaps from 'vue-gmaps'
import VModal from './components/shared/elements/Modal.vue'
import Warning from './components/shared/elements/Warning.vue'
import DatePicker from 'vuejs-datepicker'
import VSelect from 'vue-select'
import VSelectSimple from './components/shared/elements/Select.vue'
import VDropCheck from './components/shared/elements/SelectDrop.vue'
import VDropAccount from './components/shared/elements/SelectAccountDrop.vue'
import MultiSelect  from './components/shared/elements/vue-multi-select.vue'
import VNumber from './components/shared/elements/Number.vue'
import VNumber2 from './components/shared/elements/Number2.vue'
import VAlert from './components/shared/elements/Alert.vue'
import VDate from './components/shared/elements/VDatePicker.vue'
import SvgIcon from './components/shared/elements/SvgIcon.vue'
import VueClipboard from 'vue-clipboard2'
import VCopy from './components/shared/elements/Copy.vue'
import VEdit from './components/shared/elements/Edit.vue'
import ChartJsDataLabels from 'chartjs-plugin-datalabels'

import { constants } from './services/constants'

import App from './App'
import routes from './route/index'
import { actions } from './vuex/constants/index.js'

const eventsHub = new Vue()

Vue.use(IdleVue, {
  eventEmitter: eventsHub,
  store,
  idleTime: 1000 * 60 * 120,
  startAtIdle: false
})

Vue.use(VueGmaps, {
  key: 'AIzaSyDtxM5_Q2nxUuiRg1uGBEOgDWGPoWb3Iik'
})

Vue.use(VueClipboard)

Vue.use(VueRouter)

Vue.use(ChartJsDataLabels)

// Vue.use(VueExcelEditor)

// Configure router
const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  mode: 'history'
})

router.beforeEach((to, from, next) => {
  if (store && store.getters.alertMessage) {
    store.dispatch(actions.alert.closeAlert)
  }
  next()
})

Vue.config.productionTip = false

new Vue({
  el: '#app',
  data () {
    return {
      Version: require('../package.json').version || '0',
      constants: constants
    }
  },
  beforeCreate () {
    http.init(this.$router)
  },
  beforeMount () {
    Vue.component('VLoading', VLoading),
    Vue.component('VPanel', VPanel),
    Vue.component('VTable', VTable),
    Vue.component('VSimpleTable', VSimpleTable),
    Vue.component('VTimeline', VTimeline),
    Vue.component('AppHeader', AppHeader),
    Vue.component('AppFooter', AppFooter),
    Vue.component('VModal', VModal),
    Vue.component('Warning', Warning),
    Vue.component('DatePicker', DatePicker),
    Vue.component('VSelect', VSelect),
    Vue.component('VSelectSimple', VSelectSimple),
    Vue.component('VDropCheck', VDropCheck),
    Vue.component('MultiSelect', MultiSelect),
    Vue.component('VDropAccount', VDropAccount),
    Vue.component('VNumber', VNumber),
    Vue.component('VNumber2', VNumber2),
    Vue.component('VAlert', VAlert),
    Vue.component('VDate', VDate),
    Vue.component('SvgIcon', SvgIcon),
    Vue.component('VCopy', VCopy),
    Vue.component('VEdit', VEdit)
  },
  store,
  render: h => h(App),
  router
})

Vue.filter('mark', (words, query) => {
  if (query.length > 0 && !!words) {
    const iQuery = new RegExp(query, 'ig')
    return words.toString().replace(iQuery, (matchedTxt) => {
      return ('<span class=\'mark\'>' + matchedTxt + '</span>')
    })
  } else {
    return words
  }
})

Vue.filter('formatDate', (value) => {
  if (value) {
    return util.convertFromApiDate(value)
  }
})

Vue.filter('formatDate2', (value) => {
  if (value) {
    return util.convertFromApiDate2(value)
  }
})

Vue.filter('formatDateTime', (value) => {
  if (value) {
    return util.convertFromApiDateTime(value)
  }
})

Vue.filter('formatDateNull', (value) => {
  if (value) {
    return util.convertToApiDateNull(value)
  }
})