<template>
  <div>
    <table class="vsimple-table vtable has-background-white">
      <thead :class="headerClass">
        <tr>
          <th v-for="(col, colIndex) in header" :key="colIndex" :style="{ width: col.width ? col.width : 'auto' }">
            <slot v-if="col.custom" :data="{ col }" name="header" />
            <template v-else-if="col.edit || col.delete">
              &nbsp;
            </template>
            <template v-else>
              {{ col.value }}
            </template>
          </th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(row, rowIndex) in content" :key="rowIndex" :class="!!emptyRowKey && row[emptyRowKey] === 0 ? 'emptyrow' : ''">
          <td v-for="(col, colIndex) in header" :key="colIndex">
            <slot v-if="col.custom" name="content" :data="{ row, col}" />
            <template v-else-if="col.edit || col.delete">
              <ul>
                <li v-if="col.onClick">
                  <a @click="col.onClick(row)">
                    <i class="fa fa-pencil-square-o" />
                  </a>
                </li>
                <li v-if="col.onDelete && col.delete">
                  <a @click="col.onDelete(row)">
                    <i class="fa fa-trash-o" />
                  </a>
                </li>
              </ul>
            </template>
            <template v-else-if="col.onClick">
              <a @click="col.onClick(row)">
                {{ row[col.key] }}
              </a>
            </template>
            <template v-else>
              <span v-if="col.type && col.type === 'Date'">
                {{ row[col.key] | formatDate() }}
                <small v-if="col.secondaryItem" class="p-l-xs">
                  {{ row[col.secondaryItem] }}
                </small>
              </span>
              <span v-else-if="col.type && col.type === 'nullDate'">
                {{ row[col.key] | formatDateNull() }}
                <small v-if="col.secondaryItem" class="p-l-xs">
                  {{ row[col.secondaryItem] }}
                </small>
              </span>
              <span v-else>
                {{ row[col.key] }}
                <small v-if="col.secondaryItem" class="p-l-xs">
                  <template v-if="col.secondaryItemType && col.secondaryItemType === 'DateTime'">
                    {{ row[col.secondaryItem] | formatDateTime() }}
                  </template>
                  <template v-else>
                    {{ row[col.secondaryItem] }}
                  </template>
                </small>
              </span>
            </template>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: 'VSimpleTable',
  props: {
    header: {
      type: Array,
      required: true
    },
    content: {
      type: Array,
      required: true
    },
    headerClass: {
      type: String,
      required: false,
      default: ''
    },
    emptyRowKey: {
      type: String,
      required: false,
      default: ''
    }
  }
}
</script>
<style lang="scss">
  .vsimple-table {
    li {
      display: table-cell;
      &:first-child {
        padding-right: 10px;
        a {
          position: relative;
          top: 1px;
        }
      }
    }
    .emptyrow {
      td {
        border: none;
        color: #e73d4a;
      }
    }
  }
</style>
