import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class ContactRoleResolver extends BaseResolver {
  constructor () {
    super('contactRole')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      ContactRoleId: item.ContactRoleId,
      ContactId: item.ContactId,
      ContactName: item.ContactName,
      RoleId: item.RoleId,
      RoleName: item.RoleName,
      IsPrimary: item.IsPrimary,
      AccountId: item.AccountId,
      AccountName: item.AccountName
    }
  }
}
