import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class ContactResolver extends BaseResolver {
  constructor () {
    super('contact')
  }

  resolve (item) {
    return {
      Id: item.Id,
      ContactType: item.ContactType,
      ContactOwner: item.ContactOwner,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      Salutation: item.Salutation,
      FirstName: item.FirstName,
      MiddleName: item.MiddleName,
      LastName: item.LastName,
      Title: item.Title,
      Department: item.Department,
      Assistant: item.Assistant,
      ReportsTo: item.ReportsTo,
      EICContactID: item.EICContactID,
      Industry: item.Industry,
      LeadSource: item.LeadSource,
      EMail: item.EMail,
      SecondaryEMail: item.SecondaryEMail,
      Phone: item.Phone,
      Mobile: item.Mobile,
      Fax: item.Fax,
      MailingStreet: item.MailingStreet,
      MailingCity: item.MailingCity,
      MailingState: item.MailingState,
      MailingCountry: item.MailingCountry,
      MailingPostCode: item.MailingPostCode,
      OtherStreet: item.OtherStreet,
      OtherCity: item.OtherCity,
      OtherState: item.OtherState,
      OtherCountry: item.OtherCountry,
      OtherPostCode: item.OtherPostCode,
      ContactCurrency: item.ContactCurrency,
      ContactActive: item.ContactActive,
      InActivityReason: item.InActivityReason,
      EstimatedAnnualUtilitiesSpend: item.EstimatedAnnualUtilitiesSpend,
      EstimatedMetersManaged: item.EstimatedMetersManaged      ,
      DoNotCall: item.DoNotCall,
      DoNotSolict: item.DoNotSolict,
      DoNotMail: item.DoNotMail,
      DoNotEMail: item.DoNotEMail,
      DoNotFax: item.DoNotFax,
      IsPrimary: item.IsPrimary,
      EMailOptOut: item.EMailOptOut,
      ManageElec: item.ManageElec,
      ManageGas: item.ManageGas,
      ManageWater: item.ManageWater,
      EMailPreferred: item.EMailPreferred,
      LetterPreferred: item.LetterPreferred,
      PhonePreferred: item.PhonePreferred,
      AllComms: item.AllComms,
      Customers: item.Customers,
      Leads: item.Leads,
      Offers: item.Offers,
      News: item.News,
      EventInvitations: item.EventInvitations,
      Facilities: item.Facilities,
      ShowVisitors: item.ShowVisitors,
      ElecProcurement: item.ElecProcurement,
      GasProcurement: item.GasProcurement,
      WaterProcurement: item.WaterProcurement,
      ElecSAM: item.ElecSAM,
      GasSAM: item.GasSAM,
      WaterSAM: item.WaterSAM,
      ElecAuditRecovery: item.ElecAuditRecovery,
      GasAuditRecovery: item.GasAuditRecovery,
      WaterAuditRecovery: item.WaterAuditRecovery,
      ElecCostMgmt: item.ElecCostMgmt,
      GasCostMgmt: item.GasCostMgmt,
      WaterCostMgmt: item.WaterCostMgmt,
      ElecSupplierMgmt: item.ElecSupplierMgmt,
      GasSupplierMgmt: item.GasSupplierMgmt,
      WaterSupplierMgmt: item.WaterSupplierMgmt,
      ElecPerformMonitor: item.ElecPerformMonitor,
      GasPerformMonitor: item.GasPerformMonitor,
      WaterPerformMonitor: item.WaterPerformMonitor,
      CouncilTaxSvc: item.CouncilTaxSvc,
      VoidPropMgmt: item.VoidPropMgmt,
      TenantBilling: item.TenantBilling,
      AgencyInvMgmt: item.AgencyInvMgmt,
      SiteWorks: item.SiteWorks,
      TenantEngagement: item.TenantEngagement,
      EnergyEffMgmt: item.EnergyEffMgmt,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,
      AccountWebsite: item.AccountWebsite,
      Name: item.FirstName != null ? `${item.FirstName} ${item.LastName}` : `${item.LastName}`,
      OtherAddress: util.getAddress(item.OtherStreet, item.OtherCity, item.OtherState, item.OtherCountry, item.OtherPostCode),
      MailingAddress: util.getAddress(item.MailingStreet, item.MailingCity, item.MailingState, item.MailingCountry, item.MailingPostCode)
    }
  }
}
