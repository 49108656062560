import { BaseResolver } from './BaseResolver'
import { util } from '../util'

export class GasTariffResolver extends BaseResolver {
  constructor () {
    super('gasTariff')
  }

  resolve (item) {
    return {
      TariffId: item.TariffId,
      GasDetailId: item.GasDetailId, 
      AccountId: item.AccountId,
      CustomerId: item.CustomerId,
      LocationId: item.LocationId,
      DetailId: item.DetailId,
      CNB: item.CNB,
      Supplier: item.Supplier,
      AccountNo: item.AccountNo,
      SiteRef: item.SiteRef,
      StartDate: item.StartDate ? new Date(item.StartDate) : null,
      EndDate: item.EndDate ? new Date(item.EndDate) : null,
      RegistrationDate: item.RegistrationDate ? new Date(item.RegistrationDate) : null,
      ContractType: item.ContractType,
      EnergyType: item.EnergyType,
      PaymentType: item.PaymentType,
      PaymentDuration: item.PaymentDuration,
      Charges: (item.Charges || []).map(charge => {
        return {
          Id: charge.Id,
          GasMeterId: charge.GasMeterId,
          TariffId: charge.TariffId,
          Mprn: charge.Mprn,
          UnitCharge: charge.UnitCharge,
          CCL: charge.CCL,
          // Standing: charge.Standing,
          // StandingPeriod: charge.StandingPeriod,
          Vat1: charge.Vat1,
          Vat2: charge.Vat2,
          VatExemption: charge.VatExemption,
          CreatedBy: charge.CreatedBy,
          CreatedDate: charge.CreatedDate,
          UpdatedBy: charge.UpdatedBy,
          UpdatedDate: charge.UpdatedDate,
          FixedCharges: (item.OtherCharges.filter(x => x.Type == 1 && x.GasMeterId == charge.GasMeterId) || []).map(otherCharge => {
            return {
              Id: otherCharge.Id,
              GasMeterId: otherCharge.GasMeterId,
              Code: otherCharge.Code,
              TariffId: charge.TariffId,
              ChargeName: otherCharge.ChargeName,
              Type: otherCharge.Type,
              Price: otherCharge.Price,
              Period: otherCharge.Period,
              Periods: (otherCharge.Periods || []).map( p => {
                return {
                  PeriodId: p.PeriodId,
                  ChargeId: p.ChargeId,
                  PeriodFrom: new Date(p.PeriodFrom),
                  PeriodTo: new Date(p.PeriodTo),
                  Price: p.Price
                }
              })
            }
          }),
          ConsumptionCharges: (item.OtherCharges.filter(x => x.Type == 2 && x.GasMeterId == charge.GasMeterId) || []).map(otherCharge => {
            return {
              Id: otherCharge.Id,
              GasMeterId: otherCharge.GasMeterId,
              Code: otherCharge.Code,
              TariffId: charge.TariffId,
              ChargeName: otherCharge.ChargeName,
              Type: otherCharge.Type,
              Price: otherCharge.Price,
              Period: otherCharge.Period,
              Periods: (otherCharge.Periods || []).map( p => {
                return {
                  PeriodId: p.PeriodId,
                  ChargeId: p.ChargeId,
                  PeriodFrom: new Date(p.PeriodFrom),
                  PeriodTo: new Date(p.PeriodTo),
                  Price: p.Price
                }
              })
            }
          }),
          isdisabled: true
        }
      }),
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdatedDate,
      BillExists: item.BillExists || false,
      BillExistsDate: new Date(item.BillExistsDate)
    }
  }
}
