var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isPagination
      ? _c("div", { staticClass: "container vpagination" }, [
          _c("div", { staticClass: "columns" }, [
            _c("div", { staticClass: "column is-four-fifths" }, [
              _c("span", [_vm._v("Page ")]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-small",
                  attrs: { disabled: _vm.currentPage === 1 },
                  on: {
                    click: function($event) {
                      return _vm.prevPage()
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-chevron-left" })]
              ),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.currentPage) +
                    " of " +
                    _vm._s(_vm.totalPages) +
                    " \n        "
                )
              ]),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-small",
                  attrs: { disabled: _vm.currentPage === _vm.totalPages },
                  on: {
                    click: function($event) {
                      return _vm.nextPage()
                    }
                  }
                },
                [_c("i", { staticClass: "fa fa-chevron-right" })]
              ),
              _vm._v(" "),
              _c("span", [_vm._v("| View ")]),
              _vm._v(" "),
              _c("div", { staticClass: "select" }, [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.pageSize,
                        expression: "pageSize"
                      }
                    ],
                    on: {
                      change: [
                        function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.pageSize = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function($event) {
                          return _vm.updateTable()
                        }
                      ]
                    }
                  },
                  _vm._l(_vm.pages, function(page) {
                    return _c("option", { key: page }, [
                      _vm._v(
                        "\n              " + _vm._s(page) + "\n            "
                      )
                    ])
                  }),
                  0
                )
              ]),
              _vm._v(" "),
              _c("span", [
                _vm._v(
                  "records | Found total " +
                    _vm._s(_vm.filteredContent.length) +
                    " records"
                )
              ]),
              _vm._v(" "),
              _vm.filteredContent.length !== _vm.content.length
                ? _c("span", [
                    _vm._v(
                      "\n          (filtered from " +
                        _vm._s(_vm.content.length) +
                        " total entries)\n        "
                    )
                  ])
                : _vm._e()
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "column" }, [
              _c("input", {
                directives: [
                  {
                    name: "model",
                    rawName: "v-model",
                    value: _vm.searchText,
                    expression: "searchText"
                  }
                ],
                ref: "searchText",
                staticClass: "input",
                attrs: { type: "text" },
                domProps: { value: _vm.searchText },
                on: {
                  input: function($event) {
                    if ($event.target.composing) {
                      return
                    }
                    _vm.searchText = $event.target.value
                  }
                }
              })
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _c("table", { staticClass: "vtable has-background-white" }, [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm.header, function(col, colIndex) {
            return _c(
              "th",
              {
                key: colIndex,
                class: { "clickable pos-rel": !(col.isSortable === false) },
                style: { width: col.width ? col.width : "auto" },
                on: {
                  click: function($event) {
                    return _vm.sortTable(col)
                  }
                }
              },
              [
                _vm._v("\n          " + _vm._s(col.value) + "\n          "),
                col.key == _vm.sortColumn
                  ? _c(
                      "span",
                      {
                        staticClass: "icon pos-abs r-0",
                        class: _vm.ascending ? "t-9" : "t-5"
                      },
                      [
                        _c("i", {
                          staticClass: "fa",
                          class: _vm.ascending
                            ? "fa-sort-asc"
                            : "fa-sort-desc t-t"
                        })
                      ]
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.getRows(), function(row, rowIndex) {
          return _c(
            "tr",
            { key: rowIndex },
            _vm._l(_vm.header, function(col, colIndex) {
              return _c(
                "td",
                { key: colIndex },
                [
                  col.custom
                    ? _vm._t("content", null, { data: { row: row, col: col } })
                    : [
                        col.onClick
                          ? [
                              _vm.mark
                                ? _c("a", {
                                    attrs: { tabindex: "0" },
                                    domProps: {
                                      innerHTML: _vm._f("mark")(
                                        row[col.key],
                                        _vm.searchText
                                      )
                                    },
                                    on: {
                                      keyup: function($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        ) {
                                          return null
                                        }
                                        return col.onClick(row)
                                      },
                                      click: function($event) {
                                        return col.onClick(row)
                                      }
                                    }
                                  })
                                : _c("a", {
                                    domProps: { innerHTML: row[col.key] },
                                    on: {
                                      click: function($event) {
                                        return col.onClick(row)
                                      }
                                    }
                                  })
                            ]
                          : [
                              _vm.mark && _vm.isPagination
                                ? _c("span", {
                                    domProps: {
                                      innerHTML: _vm._f("mark")(
                                        row[col.key],
                                        _vm.searchText
                                      )
                                    }
                                  })
                                : col.type && col.type === "Date"
                                ? _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm._f("formatDate")(row[col.key])
                                        ) +
                                        "\n                "
                                    ),
                                    col.secondaryItem
                                      ? _c("small", { staticClass: "p-l-xs" }, [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(row[col.secondaryItem]) +
                                              "\n                "
                                          )
                                        ])
                                      : _vm._e()
                                  ])
                                : _c("span", [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row[col.key]) +
                                        "\n                "
                                    ),
                                    col.secondaryItem
                                      ? _c(
                                          "small",
                                          { staticClass: "p-l-xs" },
                                          [
                                            col.secondaryItemType &&
                                            col.secondaryItemType === "DateTime"
                                              ? [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm._f(
                                                          "formatDateTime"
                                                        )(
                                                          row[col.secondaryItem]
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                              : [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        row[col.secondaryItem]
                                                      ) +
                                                      "\n                  "
                                                  )
                                                ]
                                          ],
                                          2
                                        )
                                      : _vm._e()
                                  ])
                            ]
                      ]
                ],
                2
              )
            }),
            0
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }