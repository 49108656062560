import { BaseResolver } from './BaseResolver'
import { util } from '../../services'

export class WaterTariffResolver extends BaseResolver {
  constructor () {
    super('waterTariff')
  }

  resolve (item) {
    return {
      TariffId:item.TariffId,
      WaterDetailId:item.WaterDetailId,
      AccountId:item.AccountId,
      SiteId:item.SiteId,
      CNB:item.CNB,
      StartDate:item.StartDate,
      EndDate:item.EndDate,
      RetailSupplier:item.RetailSupplier,
      WholesaleSupplier:item.WholesaleSupplier,
      SiteRef:item.SiteRef,
      TariffGroupAccount:item.TariffGroupAccount,
      RegistrationDate:item.StartDate,
      FixedTariffs: item.FixedTariffs,
      UnitTariffs: item.UnitTariffs,
      WaterMeterTariffs:item.WaterMeterTariffs,
      Vat1:item.VAT1,
      Vat2:item.VAT2,
      BillExists:item.IsBilled,      
      IsUnMetered: item.IsUnMetered,
      IsCombinedTariff: item.IsCombinedTariff
    }
    /* return {
      trcid: item.trcid,
      tariffid: item.tariffid,
      tariffcode: item.tariffcode,
      tariffalias: item.tariffalias,
      EffFromDate: item.EffFromDate, 
      EffToDate: item.EffToDate,
      MeterChargeSize: item.MeterChargeSize,
      MeterId: item.MeterId,
      RSA: item.RSA,
      MeterPhysicalSize: item.MeterPhysicalSize,
      waterMetered: item.waterMetered,
      WasteMetered: item.WasteMetered,
      WaterUnmetered: item.WaterUnmetered,
      WasteUnmetered: item.WasteUnmetered,
      waterRV: item.waterRV,
      wasteRV: item.wasteRV,
      PropertyDrainage: item.PropertyDrainage,
      HighwayDrainage: item.HighwayDrainage,
      TradeEff: item.TradeEff,
      wtrTariffRates: (item.wtrTariffRates || []).map (tc => {
        return {
          chargeID: tc.chargeID,
          bandid: tc.bandid,
          ruleid: tc.ruleid,
          unitofmeasure: tc.unitofmeasure,
          unitrate: tc.unitrate,
          vatper: tc.vatper
        }
      }),
      spids: (item.spids || []).map (tc => {
        return {
          sid: tc.sid,
          tariffid: tc.tariffid,
          supplytype: tc.supplytype,
          retailtype: tc.retailtype,
          spid: tc.spid,
          supplierid: tc.supplierid,
          creadate: tc.creadate,
          creausr: tc.creausr,
          upddate: tc.upddate,
          updusr: tc.updusr
        }
      }),
    } */
  }
}
