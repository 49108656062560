<template>
  <VModel :is-open="isOpen" title="Warning" :show-footer="false">
    <template v-slot:body>
      {{ message }}
      <div class="text-right column p-0 p-r-sm">
        <button class="button is-small is-primary m-r-xs" @click="$emit('confirm', item)">
          Yes
        </button>
        <button class="button is-small" @click="$emit('cancel', item)">
          No
        </button>
      </div>
    </template>
  </VModel>
</template>

<script>
import VModel from './Modal.vue'
export default {
  name: 'Warning',
  components: {
    VModel
  },
  props: {
    item: {
      type: [ String, Number, Object ],
      required: true
    },
    isOpen: {
      type: Boolean,
      required: true
    },
    message: {
      type: String,
      required: false,
      default: 'Are you sure, you want to delete this item?'
    }
  }
}
</script>
