<template>
  <div v-if="message" class="notification is-danger is-light pos-rel">
    {{ message }}
    <span class="closeable" @click="close()">
      ×
    </span>
  </div>
</template>

<script>
import { actions } from '../../../vuex/constants'
export default {
  name: 'VError',
  props: {
    message: {
      type: String,
      required: true
    }
  },
  methods: {
    close () {
      this.$store.dispatch(actions.alert.closeAlert)
    }
  }
}
</script>
<style lang="scss" scoped>
#app {
  .notification.is-danger.is-light {
    background-color: #feecf0;
    padding: 8px 15px;
    color: #e73d4a;
    // white-space: pre;
  }
  .closeable {
    position: absolute;
    top: 3px;
    right: 11px;
    font-size: 21px; 
    color: #ccc;
    white-space: nowrap;
    &:hover {
      color: #8c8c8c;
    }
  }
}
</style>