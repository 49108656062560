import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class MidMarketOpportunityResolver extends BaseResolver {
  constructor () {
    super('midmarketopportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      CROpportunityId: item.CROpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      RenewalOpportunityId: item.RenewalOpportunityId,
      ClosedDate: item.ClosedDate,
      OpportunityStage: item.OpportunityStage,
      OpportunityStageName: item.OpportunityStageName,
      OpportunityCurrency: item.OpportunityCurrency,
      OpportunityOwnerId: item.OpportunityOwnerId,
      OpportunityOwnerName: item.OpportunityOwnerName,
      PrimaryCampaignSource: item.PrimaryCampaignSource,
      Probability: item.Probability,
      AnalyseError: item.AnalyseError        ,
      AnnualConsumption: item.AnnualConsumption      ,
      NumberOfSitesinPrepare: item.NumberOfSitesinPrepare,
      Utility: item.Utility,
      CommissionType1: item.CommissionType1,
      CommissionType1Rate: item.CommissionType1Rate,
      CommissionType2: item.CommissionType2       ,
      CommissionType2Rate: item.CommissionType2Rate,
      ExpectedAnnualRevenue: item.ExpectedAnnualRevenue,
      ProfitValue: item.ProfitValue,
      NetProfit: item.NetProfit,
      TotalProjectCost: item.TotalProjectCost,
      TotalExpense: item.TotalExpense,
     
      PrevCNBNumber: item.PrevCNBNumber,
      CNBNumber: item.CNBNumber,
      OJEUReference: item.OJEUReference,
      TerminationSentDate: item.TerminationSentDate,
      CurrentProviderAccountId: item.CurrentProviderAccountId,
      QuoteValidDate: item.QuoteValidDate,
      Analyst: item.Analyst,
      AcceptanceSignedDate: item.AcceptanceSignedDate,
      AcceptanceConfirmedDate: item.AcceptanceConfirmedDate,
      NewSupplierAccountId: item.NewSupplierAccountId,
      NewSupplierContractReference: item.NewSupplierContractReference,
      NewContractStartDate: item.NewContractStartDate,
      NewContractExpiryDate: item.NewContractExpiryDate,
      AnnualSavings: item.AnnualSavings,
      VATDecRqd: item.VATDecRqd,
      VATDecSent: item.VATDecSent,
      NumberOfSitesinAction: item.NumberOfSitesinAction,
      TariffLoadedDate: item.TariffLoadedDate,
      CNBScannedDate: item.CNBScannedDate,
      AchievedCommission: item.AchievedCommission,
      NextStep: item.NextStep,
      Description: item.Description,
      LeadSource: item.LeadSource,
      ReasonForLoss: item.ReasonForLoss,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,
      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
