import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class AuditResolver extends BaseResolver {
  constructor () {
    super('audit')
  }

  resolve (item) {
    return {
      Type: item.TableName,
      TypeId: item.PK,
      Field: item.FieldName,
      OldValue: item.OldValue,
      NewValue: item.NewValue,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdateDate
    }
  }
}
