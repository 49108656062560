import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class OpeningHourResolver extends BaseResolver {
  constructor () {
    super('openingHour')
  }

  resolve (item) {
    return {
      Id: item.Id,
      SiteId: item.SiteId,
      Day: item.Day,
      Active: item.Active,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdatedDate ? util.convertFromApiDateTime(item.UpdatedDate) : null,
      TimeSpan: (item.TimeSpan || []).map(time => {
        return {
          Id: time.Id,
          TimeFrom: time.DateTimeFrom ? util.getTime(time.DateTimeFrom) : null,
          TimeTill: time.DateTimeTill ? util.getTime(time.DateTimeTill) : null,
          TimeFrom12: time.DateTimeFrom ? util.getTime12(time.DateTimeFrom) : null,
          TimeTill12: time.DateTimeTill ? util.getTime12(time.DateTimeTill) : null
        }
      })
    }
  }
}
