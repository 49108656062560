import { actions, mutations } from '../constants'
import { http } from '../../services'
import permission from '../../assets/config/permission.json'

export default {
  state: {
    token: localStorage.getItem('token') || '',
    refreshtoken: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).refresh_token : null,
    expires: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth'))['.expires'] : null,
    userName: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).userName : null,
    firstName: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).firstName : null,
    lastName: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).lastName : null,
    Role: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).role : null,
    Feedback: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).feedback : null,
    Permission: localStorage.getItem('auth') ? permission.find(x => x.Name === JSON.parse(localStorage.getItem('auth')).role) || [] : [],
    screenAccess: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).screenAccess : null,
    Zendesk: localStorage.getItem('auth') ? JSON.parse(localStorage.getItem('auth')).zendesk === 'True' : false
  },

  actions: {
    [actions.auth.login] ({ commit }, credentials) {
      return http.post(`token`,`username=${credentials.username}&password=${credentials.password}&grant_type=password`,{
        headers: {
          'Content-Type': 'multipart/form-data; charset=utf-8; boundary="Boundary"',
          'enctype': 'multipart/form-data'
        }
      }).then(response => {
        commit(mutations.auth.login, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.auth.refresh] ({ commit, state }) {
      return http.post(`token`,`refresh_token=${state.refreshtoken}&grant_type=refresh_token`,{
        headers: {
          'Content-Type': 'multipart/form-data; charset=utf-8; boundary="Boundary"',
          'enctype': 'multipart/form-data'
        }
      }).then(response => {
        commit(mutations.auth.login, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.auth.logout] ({ commit }) {
      commit(mutations.auth.logout)
    }
  },

  mutations: {
    [mutations.auth.login] (state, data) {
      localStorage.setItem('auth', JSON.stringify(data))
      localStorage.setItem('token', data.access_token)
      state.token = data.access_token
      state.userName = data.userName
      state.firstName = data.firstName
      state.lastName = data.lastName
      state.refreshtoken = data.refresh_token
      state.expires = data['.expires']
      state.Role = data.role
      state.Permission = permission.find(x => x.Name === data.role) || []
      state.screenAccess = data.screenAccess
      state.Zendesk = data.Zendesk === 'True'
    },

    [mutations.auth.logout] (state) {
      localStorage.removeItem('token')
      localStorage.removeItem('auth')
      localStorage.removeItem('ei')
      localStorage.removeItem('et')
      localStorage.removeItem('gi')
      localStorage.removeItem('wtdtid')
      state.token = ''
      state.refreshtoken = ''
      state.expires = ''
      state.Role = ''
      state.Permission = []
      state.screenAccess = []
      state.Zendesk = false
      state.elecOtherCharges = []
      state.gasOtherCharges = []
      state.unitCharges = [],
      state.supplyType = [],
      state.transporterType = [],
      state.accountOwners = [],
      state.accountTypes = [],
      state.LDZExitZone = [],
      state.LDZExitZoneDescription = [],
      state.msndetails = [],
      state.wtrCharges = []
    }
  },

  getters: {
    isAuthenticated: state => !!state.token,
    userName: state => state.userName,
    firstName: state => state.firstName,
    lastName: state => state.lastName,
    Role: state => state.Role,
    Permission: state => state.Permission,
    getToken: state => state.token,
    expires: state => state.expires,
    screenAccess: state => state.screenAccess,
    Zendesk: state => state.Zendesk,
    Feedback: state => state.Feedback
  }
}
