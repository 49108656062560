<template>
  <div class="vpanel" :class="isOpen ? '': 'collapse'">
    <div class="vpanel-heading"> 
      <a v-if="isClosable" @click="togglePanel()">
        <slot name="heading" />
      </a>
      <slot v-else name="heading" />
    </div>
    <transition enter-active-class="enter-active"
                leave-active-class="leave-active"
                @before-enter="beforeEnter"
                @enter="enter"
                @after-enter="afterEnter"
                @before-leave="beforeLeave"
                @leave="leave"
                @after-leave="afterLeave"
    >
      <div v-if="isOpen" class="vpanel-content">
        <div class="p-md">  
          <slot name="content" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
export default {
  name: 'VPanel',
  props: {
    isClosable: {
      type: Boolean,
      required: false,
      default: true
    },
    defaultOpen: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      isOpen: this.defaultOpen
    }
  },
  methods: {
    togglePanel () {
      this.isOpen = !this.isOpen
      this.$emit('panelToggle', this.isOpen)
    },

    beforeEnter (element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = '0px'
        }

        element.style.display = null
      })
    },
    enter (element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = `${element.scrollHeight}px`
        })
      })
    },
    afterEnter (element) {
      element.style.height = null
    },
    beforeLeave (element) {
      requestAnimationFrame(() => {
        if (!element.style.height) {
          element.style.height = `${element.offsetHeight}px`
        }
      })
    },
    leave (element) {
      requestAnimationFrame(() => {
        requestAnimationFrame(() => {
          element.style.height = '0px'
        })
      })
    },
    afterLeave (element) {
      element.style.height = null
    }
  }
}
</script>

<style lang="scss">

</style>