export class BaseResolver {
  constructor (type) {
    this.type = type
  }
  resolve (item) {
    const mappedItem = {
      id: item.Id,
      name: item.Name,
      description: item.Description,
      parentId: item.ParentId
    }
  }
}
