var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("span", {
    class: {
      active: _vm.isActive,
      "has-hover-state": _vm.hasHoverState,
      "dark-hover-color": _vm.isDarkHoverBgColor,
      "bg-color": _vm.hasHoverBg
    },
    style: {
      top: _vm.posRelTop,
      left: _vm.posRelLeft,
      position: _vm.posRelTop ? "relative" : null
    },
    domProps: { innerHTML: _vm._s(_vm.iconHtml) }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }