const AAHED = () => import('@/components/more/AAHED')
const CCL = () => import('@/components/more/CCL')

const GasFlex = () => import('@/components/more/GasFlex')
const GasBaseUnit = () => import('@/components/more/GasBaseUnitRate')
const ElecFlex = () => import('@/components/more/ElecFlex')

const Supplier = () => import('@/components/more/Supplier')
const DNO = () => import('@/components/more/DNO')
const FITCharges = () => import('@/components/more/FITCharges')
const FITYearlyCharges = () => import('@/components/more/FITYearlyCharges')
const EMRCharges = () => import('@/components/more/EMRCharges')
const MeterEMRCharges = () => import('@/components/more/MeterEMRCharges')
const ROCharges = () => import('@/components/more/ROCharges')
const Triad = () => import('@/components/more/Triad')
const BSUoS = () => import('@/components/more/BSUoS')
const DUoS = () => import('@/components/more/DUoS')
const DUoSLoss = () => import('@/components/more/DUoSLoss')
const CMCharges = () => import('@/components/more/CMCharges')
const SiteDownload = () => import('@/components/more/SiteDownload')
const ElecDetailTemplateDownload = () => import('@/components//more/ElecDetailTemplateDownload')
const NHHElecDetailTemplateUpload = () => import('@/components//more/NHHElecDetailTemplateUpload')
const HHElecDetailTemplateDownload = () => import('@/components//more/HHElecDetailTemplateDownload')
const HHElecDetailTemplateUpload = () => import('@/components//more/HHElecDetailTemplateUpload')
const GasDetailTemplateDownload = () => import('@/components//more/GasDetailTemplateDownload')
const GasElecDetailTemplateUpload = () => import('@/components//more/GasElecDetailTemplateUpload')
const HHDownload = () => import('@/components/more/HHDownload')
const NHHDownload = () => import('@/components/more/NHHDownload')
const GasDownload = () => import('@/components/more/GasDownload')
const SiteUpload = () => import('@/components/more/SiteUpload')
const TempUpload = () => import('@/components/more/TempUpload')
const HHUpload = () => import('@/components/more/HHUpload')
const NHHUpload = () => import('@/components/more/NHHUpload')
const GasUpload = () => import('@/components/more/GasUpload')
const SiteReportDownload = () => import('@/components/more/SiteReportDownload')

const HHData = () => import('@/components/more/hhdata')
const HHDataChannel = () => import('@/components/more/hhdatachannel')
const HHDataUploadTemplateBased = () => import('@/components/more/HHDataUploadTemplateBased')
const GasAMRChargesUpload = () => import('@/components/more/GasAMRChargesUpload')
const GasAMRChargesDownload = () => import('@/components/more/GasAMRChargesDownload')
const NhhTariffUpload = () => import('@/components/more/NhhTariffUpload')
const HHTariffUpload = ()  => import('@/components/more/HHTariffUpload')
const GasTariffUpload = () => import('@/components/more/GasTariffUpload')

const ReportAccountGroup = () => import('@/components/more/ReportAccountGroup')

const TUoSLoss = () => import('@/components/more/TUoSLoss')
const TUoSTariff = () => import('@/components/more/tuostariff')

const DUoSTimeBandUpload = () => import('@/components/more/DUoSTimeBandUpload')
const DUoSLVHVUpload = () => import('@/components/more/DUoSLVHVUpload')
const DUoSLLFUpload = () => import('@/components/more/DUoSLLFUpload')
const Tuoslossupload = () => import('@/components/more/TuoslossUpload')
const BSUoSupload = () => import('@/components/more/BSUoSupload')
const GasBaseUnitRateUpload = () => import('@/components/more/GasBaseUnitRateUpload')


const routes = [
  {
    path: '/more/aahed',
    name: 'AAHED',
    component: AAHED
  },
  {
    path: '/more/ccl',
    name: 'CCL',
    component: CCL
  },
  {
    path: '/more/gasflex',
    name: 'GasFlex',
    component: GasFlex
  },
  {
    path: '/more/gasbaseunit',
    name: 'GasBaseUnit',
    component: GasBaseUnit
  },
  {
    path: '/more/elecflex',
    name: 'ElecFlex',
    component: ElecFlex
  },
  {
    path: '/more/triad',
    name: 'Triad',
    component: Triad
  },
  {
    path: '/more/bsuos',
    name: 'BSUoS',
    component: BSUoS
  },
  {
    path: '/more/bsuosupload',
    name: 'BSUoSupload',
    component: BSUoSupload
  },
  {
    path: '/more/tuosloss',
    name: 'TUoSLoss',
    component: TUoSLoss
  },
  {
    path: '/more/tuostariff',
    name: 'TUoSTariff',
    component: TUoSTariff
  },
  {
    path: '/more/tuoslossupload',
    name: 'Tuoslossupload',
    component: Tuoslossupload
  },
  {
    path: '/more/dno',
    name: 'DNO',
    component: DNO
  },
  {
    path: '/more/duos',
    name: 'DUoS',
    component: DUoS
  },
  {
    path: '/more/duosloss',
    name: 'DUoSLoss',
    component: DUoSLoss
  },
  {
    path: '/more/duosbandupload',
    name: 'DUoSTimeBandUpload',
    component: DUoSTimeBandUpload
  },
  {
    path: '/more/duoslvhvload',
    name: 'DUoSLVHVUpload',
    component: DUoSLVHVUpload
  },
  {
    path: '/more/duosllfload',
    name: 'DUoSLLFUpload',
    component: DUoSLLFUpload
  },
  {
    path: '/more/gasbaseunitrateupload',
    name: 'GasBaseUnitRateUpload',
    component: GasBaseUnitRateUpload
  },
  {
    path: '/more/hhdata',
    name: 'HHdata',
    component: HHData
  },
  {
    path: '/more/ReportAccountGroup',
    name: 'ReportAccountGroup',
    component: ReportAccountGroup
  },
  {
    path: '/more/hhdatachannel',
    name: 'HHDataChannel',
    component: HHDataChannel
  },
  {
    path: '/more/hhuploadtemplatebased',
    name: 'HHDataUploadTemplateBased',
    component:   HHDataUploadTemplateBased
  },
  {
    path: '/more/gasamrchargesupload',
    name: 'GasAMRChargesUpload',
    component:   GasAMRChargesUpload
  },
  {
    path: '/more/gasamrchargesdownload',
    name: 'GasAMRChargesDownload',
    component:   GasAMRChargesDownload
  },
  {
    path: '/more/NhhTariffupload',
    name: 'NhhTariffUpload',
    component:   NhhTariffUpload
  },
  {
    path: '/more/HHTariffupload',
    name: 'HHTariffUpload',
    component:   HHTariffUpload
  },
  
  {
    path: '/more/GasTariffUpload',
    name: 'GasTariffUpload',
    component: GasTariffUpload
  },
  {
    path: '/more/Supplier',
    name: 'Supplier',
    component: Supplier
  },
  {
    path: '/more/fit',
    name: 'FITCharges',
    component: FITCharges
  },
  {
    path: '/more/fityearly',
    name: 'FITYearlyCharges',
    component: FITYearlyCharges
  },
  {
    path: '/more/emr',
    name: 'EMRCharges',
    component: EMRCharges
  },
  {
    path: '/more/meteremr',
    name: 'MeterEMRCharges',
    component: MeterEMRCharges
  },
  {
    path: '/more/rocharge',
    name: 'ROCharges',
    component: ROCharges
  },
  {
    path: '/more/cmcharge',
    name: 'CMCharges',
    component: CMCharges
  },
  {
    path: '/more/SiteDownload',
    name: 'SiteDownload',
    component: SiteDownload
  },
  {
    path: '/more/SiteReportDownload',
    name: 'SiteReportDownload',
    component: SiteReportDownload
  },
  {
    path: '/more/ElecDetailTemplateDownload',
    name: 'ElecDetailTemplateDownload',
    component: ElecDetailTemplateDownload
  },
  {
    path: '/more/NHHElecDetailTemplateUpload',
    name: 'NHHElecDetailTemplateUpload',
    component: NHHElecDetailTemplateUpload
  },
  {
    path: '/more/HHElecDetailTemplateUpload',
    name: 'HHElecDetailTemplateUpload',
    component: HHElecDetailTemplateUpload
  },
  {
    path: '/more/GasElecDetailTemplateUpload',
    name: 'GasElecDetailTemplateUpload',
    component: GasElecDetailTemplateUpload
  },
  {
    path: '/more/HHElecDetailTemplateDownload',
    name: 'HHElecDetailTemplateDownload',
    component: HHElecDetailTemplateDownload
  },
  {
    path: '/more/GasDetailTemplateDownload',
    name: 'GasDetailTemplateDownload',
    component: GasDetailTemplateDownload
  },
  {
    path: '/more/HHDownload',
    name: 'HHDownload',
    component: HHDownload
  },{
    path: '/more/NHHDownload',
    name: 'NHHDownload',
    component: NHHDownload
  },{
    path: '/more/GasDownload',
    name: 'GasDownload',
    component: GasDownload
  },{
    path: '/more/SiteUpload',
    name: 'SiteUpload',
    component: SiteUpload
  },{
    path: '/more/TempUpload',
    name: 'TempUpload',
    component: TempUpload
  },{
    path: '/more/HHUpload',
    name: 'HHUpload',
    component: HHUpload
  },{
    path: '/more/NHHUpload',
    name: 'NHHUpload',
    component: NHHUpload
  },{
    path: '/more/GasUpload',
    name: 'GasUpload',
    component: GasUpload
  },
]

export default routes