var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("table", { staticClass: "vsimple-table vtable has-background-white" }, [
      _c("thead", { class: _vm.headerClass }, [
        _c(
          "tr",
          _vm._l(_vm.header, function(col, colIndex) {
            return _c(
              "th",
              {
                key: colIndex,
                style: { width: col.width ? col.width : "auto" }
              },
              [
                col.custom
                  ? _vm._t("header", null, { data: { col: col } })
                  : col.edit || col.delete
                  ? [_vm._v("\n             \n          ")]
                  : [
                      _vm._v(
                        "\n            " + _vm._s(col.value) + "\n          "
                      )
                    ]
              ],
              2
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        _vm._l(_vm.content, function(row, rowIndex) {
          return _c(
            "tr",
            {
              key: rowIndex,
              class:
                !!_vm.emptyRowKey && row[_vm.emptyRowKey] === 0
                  ? "emptyrow"
                  : ""
            },
            _vm._l(_vm.header, function(col, colIndex) {
              return _c(
                "td",
                { key: colIndex },
                [
                  col.custom
                    ? _vm._t("content", null, { data: { row: row, col: col } })
                    : col.edit || col.delete
                    ? [
                        _c("ul", [
                          col.onClick
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function($event) {
                                        return col.onClick(row)
                                      }
                                    }
                                  },
                                  [
                                    _c("i", {
                                      staticClass: "fa fa-pencil-square-o"
                                    })
                                  ]
                                )
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          col.onDelete && col.delete
                            ? _c("li", [
                                _c(
                                  "a",
                                  {
                                    on: {
                                      click: function($event) {
                                        return col.onDelete(row)
                                      }
                                    }
                                  },
                                  [_c("i", { staticClass: "fa fa-trash-o" })]
                                )
                              ])
                            : _vm._e()
                        ])
                      ]
                    : col.onClick
                    ? [
                        _c(
                          "a",
                          {
                            on: {
                              click: function($event) {
                                return col.onClick(row)
                              }
                            }
                          },
                          [
                            _vm._v(
                              "\n              " +
                                _vm._s(row[col.key]) +
                                "\n            "
                            )
                          ]
                        )
                      ]
                    : [
                        col.type && col.type === "Date"
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm._f("formatDate")(row[col.key])) +
                                  "\n              "
                              ),
                              col.secondaryItem
                                ? _c("small", { staticClass: "p-l-xs" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row[col.secondaryItem]) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : col.type && col.type === "nullDate"
                          ? _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(
                                    _vm._f("formatDateNull")(row[col.key])
                                  ) +
                                  "\n              "
                              ),
                              col.secondaryItem
                                ? _c("small", { staticClass: "p-l-xs" }, [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(row[col.secondaryItem]) +
                                        "\n              "
                                    )
                                  ])
                                : _vm._e()
                            ])
                          : _c("span", [
                              _vm._v(
                                "\n              " +
                                  _vm._s(row[col.key]) +
                                  "\n              "
                              ),
                              col.secondaryItem
                                ? _c(
                                    "small",
                                    { staticClass: "p-l-xs" },
                                    [
                                      col.secondaryItemType &&
                                      col.secondaryItemType === "DateTime"
                                        ? [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  _vm._f("formatDateTime")(
                                                    row[col.secondaryItem]
                                                  )
                                                ) +
                                                "\n                "
                                            )
                                          ]
                                        : [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(row[col.secondaryItem]) +
                                                "\n                "
                                            )
                                          ]
                                    ],
                                    2
                                  )
                                : _vm._e()
                            ])
                      ]
                ],
                2
              )
            }),
            0
          )
        }),
        0
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }