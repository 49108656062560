<template>
  <div class="feeback-module">
    <div class="feedBackBtn">
      <button v-if="showfeedBackBtn" class="button is-info is-small" @click="openFeedBack()">
        Send FeedBack
      </button>
    </div>
    <div v-if="step1" id="draggable-container" ref="draggableContainer" class="p-lg">
      <section class="modal-card-body" @mousedown="dragMouseDown">
        <div>
          <button class="delete is-pulled-right" @click="closeModal()" />
          <div class="feedback-logo">
            FeedBack
          </div>
        </div>
        <p>Click and drag on the page to help us better understand your feedback. You can move this dialog if it's in the way.</p>
        <br>
        <button class="button is-small" :class="isHighlight ? 'is-info' : ''" @click="setMode()">
          <span>
            <i class="fa fa-eraser" />
            Highlight
          </span>
        </button>
        <label>Highlight areas relevant to your feedback.</label>
        <br>
        <br>
        <button class="button is-small" :class="!isHighlight ? 'is-info' : ''" @click="setMode()">
          <span>
            <i class="fa fa-pencil" />
            Black out
          </span>
        </button>
        <label class="lower">
          Black out any personal information.
        </label>
        <br>
        <div>
          <button class="button is-small is-primary is-pulled-right" @click="next()">
            Next
          </button>
          <div />
        </div>
      </section>
    </div>
    <div class="modal" :class="step2 ? `is-active` : ``">
      <div class="modal-background" />
      <div class="modal-card" style="width: 900px">
        <section class="modal-card-body">
          <div class="columns">
            <div class="column">
              <div>
                <button class="delete is-pulled-right" @click="closeModal()" />
                <div class="feedback-logo">
                  FeedBack
                </div>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column">
              <div class="field">
                <label>
                  <b>Description</b>
                </label>
                <div dclass="control">
                  <textarea v-model="model.description" rows="4" maxlength="1000" style="width: 100%;" />
                </div>
              </div>
              <div>
                <b>Additional info</b>
              </div>
              <div id="feedback-browser-info">
                <span>Browser Info</span>
              </div><div id="feedback-page-info">
                <span>Page Info</span>
              </div><div id="feedback-timestamp">
                <span>Time Stamp</span>
              </div>
            </div>
            <div class="column">
              <div>
                <b>Screenshot</b>
              </div>
              <div>
                <img :src="imgUri">
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column" style="margin-bottom:20px">
              <button class="button is-small is-primary is-pulled-right" :disabled="!isValid" @click="submit()">
                Submit
              </button> 
              <button class="button is-small is-primary is-pulled-right" style="margin-right:20px" @click="closeModal()">
                Close
              </button> 
              <br>
            </div>
          </div>
        </section>
      </div>
    </div>
  </div>
</template>

<script>
import Feedback from 'screenshot-feedback'
import { http, util } from '../../services'
import { actions } from '../../vuex/constants'

export default {
  name: 'Feedback',
  data () {
    return {
      showfeedBackBtn: true,
      isHighlight : true,
      step1 : false,
      step2: false,
      imgUri: null,
      feedback : null,
      model: {
        description: null
      },
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
  computed: {
    isValid () {
      return this.model.description && this.model.description.length > 4
    }
  },
  mounted () {
    this.feedback = new Feedback({
      borderColor:'#64b7cc',
      zIndex:200,
      parent:document.querySelector('#app'),
      html2canvas:{
        logging:false,
        useCROS:true
      }
    })
  },
  methods: {
    openFeedBack () {
      this.step1 = true
      this.showfeedBackBtn = false
      this.feedback.open()
    },

    setMode () {
      this.isHighlight = !this.isHighlight
      this.feedback.setBlackMode(!this.isHighlight)
    },

    next () {
      this.step1 = false
      setTimeout(() => { 
        this.feedback.close().then(canvas => {
          this.imgUri = canvas.toDataURL()
          this.step2 = true
        })
      }, 100)
    },

    closeModal () {
      if ( this.step1) {
        this.feedback.close()
      }
      this.step1 = false
      this.step2 = false
      this.showfeedBackBtn = true
    },

    dragMouseDown: function (event) {
      event.preventDefault()
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      document.onmousemove = this.elementDrag
      document.onmouseup = this.closeDragElement
    },
    elementDrag: function (event) {
      event.preventDefault()
      this.positions.movementX = this.positions.clientX - event.clientX
      this.positions.movementY = this.positions.clientY - event.clientY
      this.positions.clientX = event.clientX
      this.positions.clientY = event.clientY
      this.$refs.draggableContainer.style.top = (this.$refs.draggableContainer.offsetTop - this.positions.movementY) + 'px'
      this.$refs.draggableContainer.style.left = (this.$refs.draggableContainer.offsetLeft - this.positions.movementX) + 'px'
    },
    closeDragElement () {
      document.onmouseup = null
      document.onmousemove = null
    },

    getBrowser () {
      const { userAgent } = navigator
      if (userAgent.includes('Firefox/')) {
        return 'Firefox'
      } else if (userAgent.includes('Edg/')) {
        return 'Edg'
      } else if (userAgent.includes('Chrome/')) {
        return 'Chrome'
      } else if (userAgent.includes('Safari/')) {
        return 'Safari'
      } else {
        return 'N/A'
      }

    },

    submit () {
      if (!this.isValid) {
        return
      }
      const formData = new FormData()
      formData.append('file', this.imgUri)
      formData.append('description', this.model.description)
      formData.append('browser', this.getBrowser())
      formData.append('url', this.$route.fullPath )
      http.post(`feedback/submit`, formData, {
        headers: { 'Content-Type': 'multipart/form-data' }
      }).then(() => {
        this.closeModal()
        this.$router.push({ path: `/myfeedbacks` })
      }).catch(error => {
        this.closeModal()
        this.loading = false
        this.error = error.ErrorDescription ? error.ErrorDescription : error
        this.$store.dispatch(actions.alert.showAlert, this.error)
      })
    }
  },
}
</script>

<style lang="scss">
.feeback-module{
  color: #606c71;
  .button {
    border-radius: 0 !important;
  }
  .columns {
    margin-bottom: 0;
    .column {
      padding-bottom: 0;
    }
  }
  .field > label {
    padding-left: 0;
  }
  textarea {
    height: 100px;
    resize: none;
    border-color: #dbdbdb;
    padding: 8px;
    &:focus {
      outline: 0;
      border: 1px solid #64b7cc;
    }
  }
  .feedBackBtn {
    position:fixed;
    bottom:0;
    right:20px;
  }
  #draggable-container {
    border: 1px solid rgba(0, 0, 0, 0.333);
    position: fixed;
    bottom: 40px;
    right: 40px;
    z-index: 200;
    width: 600px;
    height: 264px;
  }
  .feedback-logo {
      width: 34px;
      height: 32px;
      padding-left: 40px;
      margin-bottom: 16px;
      font-size: 16px;
      font-weight: normal;
      line-height: 32px;
      background: url(/src/assets/images/feeback_icons.png) no-repeat;
  }
  #feedback-browser-info,
  #feedback-page-info,
  #feedback-timestamp
   {
    margin-top: 20px;
    span {
      padding-left: 10px;
      margin: 8px 0;
      margin-left: 26px;
      font-size: 14px;
      background: url(/src/assets/images/feeback_icons.png) -30px -32px no-repeat;
    }
  }
}
</style>