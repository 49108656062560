<template>
  <div class="select" :class="isFullwidth ? 'is-fullwidth' : ''">
    <select :value="value" 
            :disabled="disabled"
            :tabindex="tabindex"
            @change="$emit('change')"
            @keydown.tab="$emit('ontab', tabindex)"
            @input="$emit('input', $event.target.value === '' ? null : $event.target.value)"
    >
      <option v-if="showDefault" :value="null">
        {{ defaultValue }}
      </option>
      <option v-for="(option, index) in options" :key="index" :value="option[id]">
        {{ option[label] }}
      </option>
    </select>
  </div>
</template>

<script>
export default {
  name: 'VSelect',
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },
    value: {
      type: [ String, Number ],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: 'Name'
    },
    id: {
      type: String,
      required: false,
      default: 'Id'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isFullwidth: {
      type: Boolean,
      required: false,
      default: false
    },
    tabindex: {
      type: Number,
      required: false,
      default: null
    },
    showDefault: {
      type: Boolean,
      required: false,
      default: true
    },
    defaultValue: {
      type: String,
      required: false,
      default: 'Select'
    }
    // disabledOptions: {
    //   type: Array,
    //   required: false,
    //   default: () => []
    // }
  }
}
</script>

<style lang="scss" scoped>
  .select {
    select:focus {
      outline: 1px dotted;
    }
  }
</style>

