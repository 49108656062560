<template>
  <a ref="edit" class="button is-light is-small vedit" @click="edit">
    <i class="fa fa-edit" />
  </a>
</template>

<script>
export default {
  name: 'VEdit',
  props: {
    meterId: {
      type: Number,
      require: true,
      default: null
    }
  },
  methods: {
    edit () {
      this.$emit('edit', this.meterId)
    }
  },
}
</script>

<style lang="scss">
 #app {
   .vedit {
     cursor: hand;
     z-index: 1;
   }
 }
</style>