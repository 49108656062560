var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "modal", class: _vm.time >= 1000 ? "is-active" : "" },
    [
      _c("div", { staticClass: "modal-background" }),
      _vm._v(" "),
      _c("div", { staticClass: "modal-card" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("section", { staticClass: "modal-card-body" }, [
          _c("p", [_vm._v("You have left this browser idle for 20 minutes.")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "You will be automatically redirected to Login page after " +
                _vm._s(_vm.time / 1000) +
                " seconds."
            )
          ])
        ])
      ])
    ]
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "modal-card-head" }, [
      _c("p", { staticClass: "modal-card-title sub-title" }, [
        _vm._v("\n        Session Expired Waring\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }