<template>
  <DatePicker 
    ref="dp1"
    v-model="value" 
    :typeable="typeable" 
    :input-class="`input`" 
    :disabled="disabled" 
    :placeholder="placeholder"
    :format="format"
    :disabled-dates="disabledDates"
    @selected="selected"
    @keyup.native.down="showCalendar"
  />
  <!-- @focusin.native="onfocusin" -->
</template>

<script>
export default {
  name: 'VDatePicker',
  props: {
    model: {
      type: [ Date , String ],
      required: false,
      default: null
    },
    typeable: {
      type: Boolean,
      required: false,
      default: true
    },
    inputClass: {
      type: String,
      required: false,
      default: ''
    },
    tabindex: {
      type: Number,
      required: false,
      default: null
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    disabledDates: {
      type: Object,
      required: false,
      default: () => {}
    },
    placeholder: {
      type: String,
      required: false,
      default: ''
    },
    format: {
      type: [ String, Function ],
      required: false,
      default: 'dd MMM yyyy'
    }
  },
  data () {
    return {
      value: this.model
    }
  },
  watch: {
    model (newValue, oldValue) {
      this.value = newValue
    }
  },
  mounted () {
    if (this.tabindex && !this.disabled) {
      this.$el.getElementsByClassName('input')[0].tabIndex = this.tabindex
    }
  },
  methods: {
    selected (date) {
      this.$emit('selected', date)
    },
    showCalendar (){
      setTimeout((ev) => {
        this.$refs.dp1.isOpen || this.$refs.dp1.showCalendar(ev)
      }, 50)
    }
  },

}
</script>

<style lang="sass" scoped>

</style>