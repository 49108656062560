var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("VModel", {
    attrs: { "is-open": _vm.isOpen, title: "Warning", "show-footer": false },
    scopedSlots: _vm._u([
      {
        key: "body",
        fn: function() {
          return [
            _vm._v("\n    " + _vm._s(_vm.message) + "\n    "),
            _c("div", { staticClass: "text-right column p-0 p-r-sm" }, [
              _c(
                "button",
                {
                  staticClass: "button is-small is-primary m-r-xs",
                  on: {
                    click: function($event) {
                      return _vm.$emit("confirm", _vm.item)
                    }
                  }
                },
                [_vm._v("\n        Yes\n      ")]
              ),
              _vm._v(" "),
              _c(
                "button",
                {
                  staticClass: "button is-small",
                  on: {
                    click: function($event) {
                      return _vm.$emit("cancel", _vm.item)
                    }
                  }
                },
                [_vm._v("\n        No\n      ")]
              )
            ])
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }