<template>
  <a ref="copy" v-clipboard:copy="value" v-clipboard:success="success" class="button is-light is-small vcopy">
    <i class="fa fa-copy" />
  </a>
</template>

<script>
export default {
  name: 'VCopy',
  props: {
    value: {
      type: [ String, Number, Date ],
      default: ''
    },
  },
  methods: {
    success () {
      this.$refs.copy.setAttribute('data-tooltip','copied')
      setTimeout(() => { this.$refs.copy.removeAttribute('data-tooltip') }, 500)
    }
  },
}
</script>

<style lang="scss">
 #app {
   .vcopy {
     cursor: grab;
     z-index: 1;
   }
 }
</style>