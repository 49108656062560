<template>
  <div class="login">
    <VLoading v-if="loading" />
    <section v-if="isAllowed" class="hero is-fullheight has-background-white-ter	">
      <div class="hero-body p-t-0">
        <div class="container">
          <div class="columns is-centered">
            <div class="column is-5-tablet is-4-desktop is-4-widescreen">
              <figure class="image m-t-sm m-b-sm">
                <a>
                  <img class="logoImg" :src="`/src/assets/images/${subdomain}_logo.png`">
                </a>
              </figure>
              <div class="box">
                <div class="p-b-sm">
                  <h1 class="subtitle is-4 has-text-weight-normal">
                    Login to your account
                  </h1>
                  <div class="control has-icons-left">
                    <input ref="username" v-model="credentials.username" type="text" placeholder="User name" class="input colbor" :disabled="loading" @keyup.enter="login">
                    <span class="icon is-small is-left">
                      <i class="fa fa-user" />
                    </span>
                  </div>
                </div>
                <div class="p-b-sm">
                  <div class="control has-icons-left">
                    <input ref="password" v-model="credentials.password" type="password" placeholder="Password" class="input colbor" @keyup.enter="login">
                    <span class="icon is-small is-left">
                      <i class="fa fa-lock" />
                    </span>
                  </div>
                </div>
                <div class="p-b-sm is-horizontal pos-rel">
                  <input id="ckremeberme" class="is-checkradio" type="checkbox" name="ckremeberme" :disabled="true">
                  <label for="ckremeberme">
                    Remember me
                  </label>
                  <span class="pos-abs r-0">
                    <button class="button is-primary" @click="login()">
                      Login
                    </button>
                  </span>
                </div>
                <div class="p-t-sm">
                  <hr class="dropdown-divider">
                </div>
                <div>
                  <article class="message" :class="{'is-primary' : !!errorMessage === false, 'is-danger' : !!errorMessage}">
                    <div v-if="!!isChrome === false && !!errorMessage === false" class="message-body">
                      Better user experience try in Chrome
                    </div>
                    <div v-else-if="!!errorMessage === false" class="message-body">
                      Please enter your user name and password.
                    </div>
                    <div v-else class="message-body">
                      {{ errorMessage }}
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <div v-else class="container">
      <article class="message is-primary" style="width: 75%;position: fixed;left: 50%; top:25%;margin-left: -37.5%;">
        <div v-if="!!errorMessage === false" class="message-body">
          Website not supported in this browser. Supported Browsers - Chrome, Edge and Firefox. Better user experience use Chrome Browser.
        </div>
      </article>
    </div>
  </div>
</template>
<script>
import { actions } from '../../vuex/constants'
import { http } from '../../services'

export default {
  name: 'Login',
  data () {
    return {
      loading: false,
      subdomain: '',
      credentials: {
        username: null,
        password: null
      },
      errorMessage: null
    }
  },
  computed: {
    isChrome () {
      // return !!window.chrome && (!!window.chrome.webstore || !!window.chrome.runtime)
      return navigator.userAgent.indexOf('Chrome') != -1
    },
    isAllowed () {
      const isFirefox = typeof InstallTrigger !== 'undefined'
      const isIE = /*@cc_on!@*/false || !!document.documentMode
      const isEdge = !isIE && !!window.StyleMedia
      return isFirefox || this.isChrome || isEdge
    }
  },
  created () {
    this.subdomain = location.hostname.split('.').shift()
  },
  mounted () {
    this.$refs.username.focus(),
    this.clearCache()
  },
  methods: {
    login () {
      if (!this.validate()) {
        return
      }
      this.loading = true
      this.$store.dispatch(actions.auth.login, this.credentials)
        .then(() => {
          this.errorMessage = null
          this.$router.push({ path: '/account' })
        }, error => {
          this.errorMessage = error.error_description ? error.error_description : error
          this.loading = false
        })
    },
    validate () {
      if (!this.credentials.username) {
        this.errorMessage = 'Please enter username'
        this.$refs.username.focus()
        return false
      }
      if (!this.credentials.password) {
        this.errorMessage = 'Please enter password'
        this.$refs.password.focus()
        return false
      }
      return true
    },
    clearCache () {
      this.loading = true
      http.get(`appversion`)
        .then(response => {
          this.loading = false
          if (response !==  this.$root.Version) {
            window.location.reload()
          }
        }).catch(error => {
          this.loading = false
          console.log(error)
        })
    }
  }
}
</script>

<style lang="scss">
  .login {
    .image > a > img {
      width: auto;
    }
  }
</style>