import { actions, mutations } from '../constants'
import { http } from '../../services'

export default {
  state: {
    customers: null,
    sites: null,
    inactivesites: null,
    activeinactivesites: null
  },

  actions: {
    [actions.customer.getAllCustomers] ({ state, commit }) {
      if (state.customers && state.customers.length > 0) {
        return state.customers
      }
      return http.get(`customer/basic`).then(response => {
        commit(mutations.customer.getAllCustomers, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.customer.getAllSites] ({ state, commit }) {
      if (state.sites && state.sites.length > 0) {
        return state.sites
      }
      return http.get(`site/basic`).then(response => {
        commit(mutations.customer.getAllSites, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.customer.getAllInActiveSites] ({ state, commit }) {
      if (state.inactivesites && state.inactivesites.length > 0) {
        return state.inactivesites
      }
      return http.get(`site/inactivesite`).then(response => {
        commit(mutations.customer.getAllInActiveSites, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.customer.getBothActiveInActiveSites] ({ state, commit }) {
      if (state.activeinactivesites && state.activeinactivesites.length > 0) {
        return state.activeinactivesites
      }
      return http.get(`site/activeinactivesite`).then(response => {
        commit(mutations.customer.getBothActiveInActiveSites, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    }
  },

  mutations: {
    [mutations.customer.getAllCustomers] (state, data) {
      state.customers = data
    },

    [actions.customer.getAllSites] (state, data) {
      state.sites = data 
    },

    [actions.customer.getAllInActiveSites] (state, data) {
      state.inactivesites = data 
    },

    [actions.customer.getBothActiveInActiveSites] (state, data) {
      state.activeinactivesites = data 
    }
  },

  getters: {
    customers: state => state.customers,
    sites: state => state.sites,
    inactivesites: state => state.inactivesites,
    activeinactivesites: state => state.activeinactivesites
  }
}
