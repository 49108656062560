<template>
  <div v-if="site" class="site-header p-t-md p-b-md">
    <div class="media pos-rel">
      <div class="media-left">
        <figure class="image is-32x32 has-background-grey-lighter	">
          <img src="/src/assets/images/accounts.png">
        </figure>
      </div>
      <div class="media-content">
        <p>
          Account
        </p>
        <p class="media-heading">
          <router-link :to=" { path: `/account/${site.accountId}`, query: { tabIndex : 3 } }">
            {{ site.accountName }}
          </router-link>
        </p>
        <div v-if="site" class="pos-rel">
          <div class="pos-abs has-text-left box p-xs p-l-sm p-r-sm siteInfo">
            <div>
              Account Id : {{ site.accountId }}
            </div>
            <!--             <div v-if="isMonarch">
              Location Id : {{ site.locationId }}
            </div>
            <div v-else>
              Location Id : {{ site.id }}
            </div> -->
            <div>
              Site Id : {{ site.id }}
            </div>
            <div>
              Location Id : {{ site.locationId }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="columns">
      <div class="column p-l-0">
        <div class="field">
          <label class="p-b-0">
            <small>
              Site Name
            </small>
          </label>
          <div class="control" :title="site.siteRef">
            {{ site.siteRef }}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="p-b-0">
            <small>
              Status
            </small>
          </label>
          <div class="control">
            {{ site.statusText }}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="p-b-0">
            <small>
              Start Date
            </small>
          </label>
          <div class="control">
            {{ site.startDate | formatDate() }}
          </div>
        </div>
      </div>
      <div class="column">
        <div class="field">
          <label class="p-b-0">
            <small>
              Account Owner
            </small>
          </label>
          <div class="control">
            {{ site.accountOwner }}
          </div>
        </div>
      </div>
      <div class="column is-offset">
        <div class="tabs">
          <ul>
            <li :class="tabIndex == 1 ? 'is-active' : ''">
              <router-link :to="`/site/${site.id}`" tabindex="0">
                <i class="fa fa-map-marker" />
                &nbsp;Site
              </router-link>
            </li>
            <li :class="tabIndex == 2 ? 'is-active' : ''">
              <router-link :to="`/site/${site.id}/elec`" tabindex="0">
                <i class="fa fa-plug" />
                &nbsp;Electricity
              </router-link>
            </li>
            <li :class="tabIndex == 3 ? 'is-active' : ''">
              <router-link :to="`/site/${site.id}/gas`" tabindex="0">
                <i class="fa fa-fire" />
                &nbsp;Gas
              </router-link>
            </li>
            <li :class="tabIndex == 4 ? 'is-active' : ''">
              <router-link :to="`/site/${site.id}/water`" tabindex="0">
                <i class="fa fa-tint" />              
                &nbsp;
                Water
              </router-link>
            </li>
          </ul>
        </div>
      </div>
    </div>  
  </div>
</template>

<script>
import { actions } from '../../vuex/constants'
import { mapState } from 'vuex'

export default {
  name:'InvoiceValidationHeader',
  props: {
    siteId: {
      type: [ String, Number ],
      required: true
    },
    tabIndex: {
      type: Number,
      required: true
    }
  },
  computed: {
    ...mapState({
      site: state => state.validation.site
    }),

    isESS () {
      return [ 'uat-essutility', 'ess-tbd','dev-ess','ess' ].includes(this.subdomain)
    },
    isEIC () {
      return [ 'uat-eic', 'eic', 'dev5', 'dev-eic' ].includes(this.subdomain)
    },
    isMonarch () {
      return [ 'uat-monarch', 'monarchmatrix','dev-monarch' ].includes(this.subdomain)
    },
    isProd () {
      return [ 'ess', 'monarchmatrix','smithbellerby' ].includes(this.subdomain)
    },
  },
  
  created () {
    this.subdomain = location.hostname.split('.').shift()    
  },

  mounted () {
    this.$store.dispatch(actions.validation.getSite, { id: this.$route.params.id, refresh: false })
  }
}
</script>

<style lang="scss">
  .site-header {
    border-bottom:1px solid #ddd;
    .columns {
      .control {
        overflow: hidden;
        text-overflow: ellipsis;
        max-width: 200px;
        white-space: nowrap;
      }
    }
    .tabs {
      position: relative;
      bottom: -38px;
      ul {
        border: none;
        li {
          a {
            color: #337ab7;
            border-bottom:1px solid #ddd;
          }
          &:hover {
            background: #eee; 
          }
          &.is-active > a {
            background: #fff;
            color: #333;
            font-weight: normal;
            border: 1px solid #ddd;
            border-bottom: none;
          }
        }
      }
    }
  }
</style>