<!-- eslint-disable no-mixed-spaces-and-tabs -->
<template>
  <div class="accounts">
    <div class="p-t-md p-b-md page-title has-background-white">
      <div class="container pos-rel">
        <h1>
          <SvgIcon class="navbar-item-svg" pos-rel-top="3px" icon="clients" size="md" fill="#aaafb7" :has-hover-bg="true" />
          Accounts
          <small>&nbsp;customer information</small>
        </h1>
        <!-- <div class="pos-abs r-0 t-0" @click="isShowModal =true">
          <a class="button is-light is-small" style="border-radius:20px;" @click="showModal()">
            <i class="fa fa-search" />
            &nbsp;&nbsp;Advanced Search
          </a>
        </div> -->
      </div>
    </div>
    <VLoading v-if="loading" />
   
    <div class="container p-t-md">
      <div class="column text-right">
        <button class="button is-primary is-small" @click="AddAcc">
          Add Account
        </button>
      </div>
      <VTable v-if="header.length" :header="header" :content="content" />
    </div>
    <VModal :is-open="isShowModal" :close-button="true" :show-footer="false" title="Advanced Search" :error="alertMessage" @closeModal="closeModal()"> 
      <template v-slot:body>
        <div class="container advanceModal">
          <div class="columns">
            <div class="column">
              <div class="field is-horizontal">
                <!--  <div v-if="isMonarchDomain" class="select">
                  <select v-model="modal.searchType">
                    <option value="2">
                      Site Name
                    </option>
                    <option value="3">
                      Postcode
                    </option>
                    <option value="4">
                      MPAN (Last 13 character)
                    </option>
                    <option value="5">
                      MPRN
                    </option>
                    <option value="6">
                      Invoice No
                    </option>
                    <option value="7">
                      Net
                    </option>
                    <option value="8">
                      Total
                    </option>
                    <option value="9">
                      Account No
                    </option>
                    <option value="10">
                      Address
                    </option>
                    <option value="11">
                      Site Reference
                    </option>
                    <option value="12">
                      Account Reference
                    </option>
                    <option value="13">
                      Account Alias
                    </option>
                  </select>
                </div> -->
                <div class="select">
                  <select v-model="modal.searchType">
                    <option value="1">
                      Location Id
                    </option>
                    <option value="14">
                      Site Id
                    </option>
                    <option value="2">
                      Site Name
                    </option>
                    <option value="3">
                      Postcode
                    </option>
                    <option value="4">
                      MPAN (Last 13 character)
                    </option>
                    <option value="5">
                      MPRN
                    </option>
                    <option value="6">
                      Invoice No
                    </option>
                    <option value="7">
                      Net
                    </option>
                    <option value="8">
                      Total
                    </option>
                    <option value="9">
                      Account No
                    </option>
                    <option value="10">
                      Address
                    </option>
                    <option value="11">
                      Site Reference
                    </option>
                    <option value="12">
                      Account Reference
                    </option>
                    <option value="13">
                      Account Alias
                    </option>
                  </select>
                </div>
                <div style="height:2.2em;padding: 6px;margin-left: 10px;">
                  Equal to
                </div>
                <div class="m-l-sm">
                  <input ref="searchText" v-model="modal.searchText" class="input searchText" @keyup.enter="search()">
                </div>
                <div class="m-l-sm">
                  <button class="button is-primary"
                          :disabled="!enableSearch || !modal.searchType"
                          @click="search()"
                  >
                    Search
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <div class="container">
          <VTable v-if="modal.Messages && modal.Messages.length" :header="modalheader" :content="modal.Messages" :is-pagination="false">
            <template v-slot:content="{ data }">
              <div class="columns">
                <template v-if="data.col.key =='Status'">
                  <SvgIcon v-if="data.row.Status" pos-rel-left="12px" pos-rel-top="10px" class="navbar-item-svg" icon="tick" size="sm" fill="#48c78e" :has-hover-state="false" />
                  <SvgIcon v-else pos-rel-left="12px" pos-rel-top="10px" class="navbar-item-svg" icon="cancel" size="sm" fill="#f14668" :has-hover-state="false" />
                </template>
                <template v-if="data.col.key =='ElecMeterStatus'">
                  <SvgIcon v-if="data.row.ElecMeterStatus === 'Active'" pos-rel-left="12px" pos-rel-top="10px" class="navbar-item-svg" icon="tick" size="sm" fill="#48c78e" :has-hover-state="false" />
                  <SvgIcon v-else pos-rel-left="12px" pos-rel-top="10px" class="navbar-item-svg" icon="cancel" size="sm" fill="#f14668" :has-hover-state="false" />
                </template>
                <template v-if="data.col.key =='GasMeterStatus'">
                  <SvgIcon v-if="data.row.GasMeterStatus" pos-rel-left="12px" pos-rel-top="10px" class="navbar-item-svg" icon="tick" size="sm" fill="#48c78e" :has-hover-state="false" />
                  <SvgIcon v-else pos-rel-left="12px" pos-rel-top="10px" class="navbar-item-svg" icon="cancel" size="sm" fill="#f14668" :has-hover-state="false" />
                </template>
              </div>
            </template>
          </VTable>
          <div v-if="modal.Messages && modal.Messages.length === 0" class="p-sm" style="border:1px solid #e7ecf1;">
            No records found!
          </div>
        </div>
      </template>
    </VModal>  
  </div>
</template>

<script>
import { http } from '../../services'
import { AccountResolver } from '../../services/resolvers'
import { mapGetters } from 'vuex'
import { actions } from '../../vuex/constants'
import VAjaxTable from '../shared/elements/AjaxTable'

const accountResolver = new AccountResolver()

export default {
  name: 'Accounts',
  data () {
    return {
      loading: true,    
      header: this.getHeader(),
      content: [],
      isShowModal: false,
      modalheader: this.getModalHeader(),
      modal: {
        searchType: '4',
        searchText: null,
        Messages: null
      }
    }
  },
  computed: {
    ...mapGetters([ 'gi','ei','alertMessage' ]),
    enableSearch () {
      return this.modal.searchType && this.modal.searchText &&this.modal.searchText.length > 0
    },
    isMonarchDomain () {
      return [ 'uat-monarch', 'monarchmatrix','dev-monarch' ].includes(this.subdomain)
    },
  },
  created () {
    this.subdomain = location.hostname.split('.').shift()
  },
  mounted () {
    this.getContent()
    this.highlight('')

  },
  methods: {
    AddAcc () {
      this.$router.push({ path: `/AddAccount` })
    },
    getHeader () {
      return [
        { key: 'Name', value: 'Name', onClick: (item) => this.showItem (item) },
        { key: 'AccountNumber', value: 'Account Number', width: '12%' },
        { key: 'Phone', value: 'Phone', width: '20%' },
        { key: 'AccountOwner', value: 'Account Owner', width: '20%' }
      ]
    },
    getContent () {
      this.loading = true
      return http.get(`customer`).then(response => {
        this.loading = false
        this.content = (response || []).map(item => {
          return accountResolver.resolve(item)
        })
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },
    showItem (item) {
      this.$router.push({ path: `/account/${item.Id}` })
    },
   
    highlight (itemToHighlight) {
      if (!this.searchFilter) {
        return itemToHighlight
      }
      return itemToHighlight.replace(new RegExp(this.searchFilter, 'ig'), match => {
        return '<strong">' + this.searchFilter + '</strong>' + (match.replace(this.searchFilter, ''))
      })
    },

    showModal () {
      this.isShowModal = true
      if (this.modal.Messages && this.modal.Messages.length === 0) {
        this.modal.Messages = null
      }
    },

    closeModal () {
      this.isShowModal = false
    },
    getModalHeader () {
      return [
        { key: 'AccountName', value: 'Customer', onClick: (item) => this.showCustomer (item) },
        { key: 'SiteId', value: 'Site Id', onClick: (item) => this.showSite(item) },
        { key: 'LocationId', value: 'Location Id', onClick: (item) => this.showSite (item)  },
        { key: 'SiteName', value: 'Site Name' },
        { key: 'Address', value: 'Address' },
        { key: 'Status', value: 'Status', width: '10%', custom: true },
        { key: 'ElecMeterStatus', value: 'Elect Details Status',width: '10%', custom: true },
        { key: 'GasMeterStatus', value: 'Gas Details Status',width: '10%', custom: true },
      ]
    },
    search () {
      this.loading = true
      return http.post(`globalsearch`, {
        LocationId: this.modal.searchType === '1' ? this.modal.searchText : null,
        SiteName: this.modal.searchType === '2' ? this.modal.searchText : null,
        Postcode: this.modal.searchType === '3' ? this.modal.searchText : null,
        Mpan: this.modal.searchType === '4' ? this.modal.searchText : null,
        Mprn: this.modal.searchType === '5' ? this.modal.searchText : null,
        InvoiceNo: this.modal.searchType === '6' ? this.modal.searchText : null,
        Net: this.modal.searchType === '7' ? this.modal.searchText : null,
        Total: this.modal.searchType === '8' ? this.modal.searchText : null,
        AccountNumber: this.modal.searchType === '9' ? this.modal.searchText : null,
        Address: this.modal.searchType === '10' ? this.modal.searchText : null,
        SiteRef: this.modal.searchType === '11' ? this.modal.searchText : null,
        AccountReference: this.modal.searchType === '12' ? this.modal.searchText : null,
        AccountAlias: this.modal.searchType === '13' ? this.modal.searchText : null
        
      }).then(response => {
        this.loading = false
        this.modal.Messages = (response || [])
        console.log('global search response ', response)
        console.log('modal messages', this.modal.Messages[0])
        
        if ( this.modal.Messages[0].ElecDetailId !== null && this.modal.Messages.length === 1)
        {
          this.showElec(this.modal.Messages[0]) 
        }
       
        else if (this.modal.Messages[0].GasDetailId !== null && this.modal.Messages.length === 1)
        {
          this.showGas(this.modal.Messages[0])
        }
        
        else if (this.modal.Messages.length === 1){
          this.showSite(this.modal.Messages[0])
        }
        
      
      }).catch(error => {
        this.loading = false
        console.log(error)
      })
    },

    showCustomer (item) {
      this.$router.push({ path: `/account/${item.AccountId}` })
    },

    showSite (item) {
      this.$router.push({ path: `/site/${item.SiteId}` })
    },
    showElec (item) {
      this.$router.push({ path: `/site/${item.SiteId}/elec/${item.ElecDetailId}` })
    },

    showGas (item) {
      this.$router.push({ path: `/site/${item.SiteId}/gas/${item.GasDetailId}` })
    },

  }
}
</script>

/* <style lang="scss">
#app {
  .accounts {
    .modal-card {
      width: 1050px;
      .searchText, button {
        height: 2.2em;
      }
      
    }
  }
}
</style> */
