
import axios from 'axios'
import store from '../vuex/index.js'
import * as config from '../assets/config/config'

const remotepath = process.env.NODE_ENV === 'development' ? 
  config.API_DEV : process.env.NODE_ENV === 'production' ? config.API_PROD : process.env.NODE_ENV === 'staging' ? config.API_TEST : config.API_TRAINING

const apipath = remotepath + 'api/'

export const http = {
  init (router) {

    // TODO - Use environment files once we completely migrate to the new UI
    /* eslint-disable no-undef */
    axios.defaults.baseURL = remotepath || ''
    axios.defaults.withCredentials = true

    // Setup authorization header
    axios.interceptors.request.use(config => {
      config.baseURL = remotepath
      if (config.url !== 'token') {
        config.baseURL = apipath
      }
      if (config.url === 'appversion') {
        return config
      }
      else if (config.url !== 'token' && !config.localAssetRequest && !config.public) {
        const token = store.getters.isAuthenticated
        if (!store.getters.isAuthenticated) {
          router.push({ path: '/login' })
          return
        } else {
          const token = window.localStorage.getItem('token')
          config.headers.Authorization = `Bearer ${token}`
          if (getExpiryMinutes(store.getters.expires) < 20) {
            store.dispatch('refresh')
          }
        }
      }
      if (config.localAssetRequest) {
        config.baseURL = '/'
      }
      return config
    })

    // Get the token from the response header
    axios.interceptors.response.use(
      response => response.data,
      error => {
        if (error.message && error.message === 'Network Error'){
          // router.push({ path: '/login' })
          return Promise.reject('Web server is down. Please contact support team!')
        }
        if (error.response && error.response.status === 401) {
          router.push({ path: '/noaccess' })
          return Promise.reject('Authorization has been denied for this request.')
        } else {
          if (error.response.status === 500 && error.response.data.ErrorCode && error.response.data.ErrorCode === 1000) {
            return Promise.reject(error.response.data.ErrorDescription)
          } else {
            return Promise.reject(error.response ? error.response.data : error)
          }
        }
      })
  },

  request (method, url, data, config) {
    // store.dispatch(actions.feedback.hideError)
    return makeHttpRequest(method, url, data, config)
  },

  get (...args) {
    return this.request('GET', ...args)
  },

  post (...args) {
    return this.request('POST', ...args)
  },

  patch (url, data) {
    return this.request('PATCH', url, data, {
      headers: {
        'Content-Type': 'application/json-patch+json'
      }
    })
  },

  put (...args) {
    return this.request('PUT', ...args)
  },

  delete (...args) {
    return this.request('DELETE', ...args)
  },

  options (...args) {
    return this.request('OPTIONS', ...args)
  },

  head (...args) {
    return this.request('HEAD', ...args)
  },

  getLocalAsset (url) {
    return axios.request({
      method: 'GET',
      url,
      localAssetRequest: true
    })
  },

  downloadUrl (url) {
    const downloadUrl = 
    window.location.href = apipath + url
  },
}

/**
 * Make http request using axios.
 *
 * @param {String} method
 * @param {String} url
 * @param {Object} data
 * @return {AxiosPromise}
 */
function makeHttpRequest (method, url, data, config) {
  return axios.request(Object.assign({ method, url, data }, config))
}

function getExpiryMinutes (date) {
  const diffMs = (new Date(date) - new Date())
  return Math.round(((diffMs % 86400000) % 3600000) / 60000)
}