var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "login" },
    [
      _vm.loading ? _c("VLoading") : _vm._e(),
      _vm._v(" "),
      _vm.isAllowed
        ? _c(
            "section",
            { staticClass: "hero is-fullheight has-background-white-ter\t" },
            [
              _c("div", { staticClass: "hero-body p-t-0" }, [
                _c("div", { staticClass: "container" }, [
                  _c("div", { staticClass: "columns is-centered" }, [
                    _c(
                      "div",
                      {
                        staticClass:
                          "column is-5-tablet is-4-desktop is-4-widescreen"
                      },
                      [
                        _c("figure", { staticClass: "image m-t-sm m-b-sm" }, [
                          _c("a", [
                            _c("img", {
                              staticClass: "logoImg",
                              attrs: {
                                src:
                                  "/src/assets/images/" +
                                  _vm.subdomain +
                                  "_logo.png"
                              }
                            })
                          ])
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "box" }, [
                          _c("div", { staticClass: "p-b-sm" }, [
                            _c(
                              "h1",
                              {
                                staticClass:
                                  "subtitle is-4 has-text-weight-normal"
                              },
                              [
                                _vm._v(
                                  "\n                  Login to your account\n                "
                                )
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "div",
                              { staticClass: "control has-icons-left" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.credentials.username,
                                      expression: "credentials.username"
                                    }
                                  ],
                                  ref: "username",
                                  staticClass: "input colbor",
                                  attrs: {
                                    type: "text",
                                    placeholder: "User name",
                                    disabled: _vm.loading
                                  },
                                  domProps: { value: _vm.credentials.username },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.login.apply(null, arguments)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.credentials,
                                        "username",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm._m(0)
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "p-b-sm" }, [
                            _c(
                              "div",
                              { staticClass: "control has-icons-left" },
                              [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.credentials.password,
                                      expression: "credentials.password"
                                    }
                                  ],
                                  ref: "password",
                                  staticClass: "input colbor",
                                  attrs: {
                                    type: "password",
                                    placeholder: "Password"
                                  },
                                  domProps: { value: _vm.credentials.password },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.login.apply(null, arguments)
                                    },
                                    input: function($event) {
                                      if ($event.target.composing) {
                                        return
                                      }
                                      _vm.$set(
                                        _vm.credentials,
                                        "password",
                                        $event.target.value
                                      )
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _vm._m(1)
                              ]
                            )
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "p-b-sm is-horizontal pos-rel" },
                            [
                              _c("input", {
                                staticClass: "is-checkradio",
                                attrs: {
                                  id: "ckremeberme",
                                  type: "checkbox",
                                  name: "ckremeberme",
                                  disabled: true
                                }
                              }),
                              _vm._v(" "),
                              _c("label", { attrs: { for: "ckremeberme" } }, [
                                _vm._v(
                                  "\n                  Remember me\n                "
                                )
                              ]),
                              _vm._v(" "),
                              _c("span", { staticClass: "pos-abs r-0" }, [
                                _c(
                                  "button",
                                  {
                                    staticClass: "button is-primary",
                                    on: {
                                      click: function($event) {
                                        return _vm.login()
                                      }
                                    }
                                  },
                                  [
                                    _vm._v(
                                      "\n                    Login\n                  "
                                    )
                                  ]
                                )
                              ])
                            ]
                          ),
                          _vm._v(" "),
                          _vm._m(2),
                          _vm._v(" "),
                          _c("div", [
                            _c(
                              "article",
                              {
                                staticClass: "message",
                                class: {
                                  "is-primary": !!_vm.errorMessage === false,
                                  "is-danger": !!_vm.errorMessage
                                }
                              },
                              [
                                !!_vm.isChrome === false &&
                                !!_vm.errorMessage === false
                                  ? _c("div", { staticClass: "message-body" }, [
                                      _vm._v(
                                        "\n                    Better user experience try in Chrome\n                  "
                                      )
                                    ])
                                  : !!_vm.errorMessage === false
                                  ? _c("div", { staticClass: "message-body" }, [
                                      _vm._v(
                                        "\n                    Please enter your user name and password.\n                  "
                                      )
                                    ])
                                  : _c("div", { staticClass: "message-body" }, [
                                      _vm._v(
                                        "\n                    " +
                                          _vm._s(_vm.errorMessage) +
                                          "\n                  "
                                      )
                                    ])
                              ]
                            )
                          ])
                        ])
                      ]
                    )
                  ])
                ])
              ])
            ]
          )
        : _c("div", { staticClass: "container" }, [
            _c(
              "article",
              {
                staticClass: "message is-primary",
                staticStyle: {
                  width: "75%",
                  position: "fixed",
                  left: "50%",
                  top: "25%",
                  "margin-left": "-37.5%"
                }
              },
              [
                !!_vm.errorMessage === false
                  ? _c("div", { staticClass: "message-body" }, [
                      _vm._v(
                        "\n        Website not supported in this browser. Supported Browsers - Chrome, Edge and Firefox. Better user experience use Chrome Browser.\n      "
                      )
                    ])
                  : _vm._e()
              ]
            )
          ])
    ],
    1
  )
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fa fa-user" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("span", { staticClass: "icon is-small is-left" }, [
      _c("i", { staticClass: "fa fa-lock" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "p-t-sm" }, [
      _c("hr", { staticClass: "dropdown-divider" })
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }