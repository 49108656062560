import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class SiteEmployeeDetailResolver extends BaseResolver {
  constructor () {
    super('siteEmployeeDetail')
  }

  resolve (item) {
    return {
      Id: item.Id,
      SiteId: item.SiteId,
      PeriodFrom: item.PeriodFrom,
      PeriodTo: item.PeriodTo,
      Employees: item.Employees,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdatedDate
    }
  }
}
