//NHH Reports
const ApprovalReportElec = () => import('@/components/report/elec/ApprovalReport')
const ConsumptionReportElec = () => import('@/components/report/elec/ConsumptionReport')
const NHHTariffSummaryReport = () => import('@/components/report/elec/NHHTariffSummaryReport')

//HH Reports
const ApprovalReportHHElec = () => import('@/components/report/hhelec/ApprovalReport')
const HHTariffSummaryReport = () => import('@/components/report/hhelec/HHTariffSummaryReport')

//Gas Reports
const ApprovalReportGas = () => import('@/components/report/gas/ApprovalReport')
const GasTariffSummaryReport = () => import('@/components/report/gas/GasTariffSummaryReport')

//Admin Reports
const UnReport = () => import('@/components/report/UnReport')
const Commission = () => import('@/components/report/Commission')
const NewCommission = () => import('@/components/report/NewCommission')

//Common Reports
const PreApprovalReport = () => import('@/components/report/common/Pre-ApprovalReport')
const MonthlyConsumptionReport = () => import('@/components/report/common/MonthlyConsumptionReport')
const BillCountReport = () => import('@/components/report/common/BillCountReport')
const SR183 = () => import('@/components/report/common/SR183')
const SR160A = () => import('@/components/report/common/SR160A')
const SR168 = () => import('@/components/report/common/SR168')
const SR164 = () => import('@/components/report/common/SR164')
const SR181 = () => import('@/components/report/common/SR181')
const HHData = () => import('@/components/report/common/HHData')
const TR301 = () => import('@/components/report/common/TR301')
const SG184 = () => import('@/components/report/common/SG184')
const SG180 = () => import('@/components/report/common/SG180')
const SG181 = () => import('@/components/report/common/SG181')
const SG182 = () => import('@/components/report/common/SG182')
const SG185 = () => import('@/components/report/common/SG185')
const GR202 = () => import('@/components/report/common/GR202')
const TR335 = () => import('@/components/report/common/TR335')
const SD368 = () => import('@/components/report/common/SD368')
const SD369 = () => import('@/components/report/common/SD369')
const SR529 = () => import('@/components/report/common/SR529')
const SR529W = () => import('@/components/report/common/SR529W')
const SR130 = () => import('@/components/report/common/SR130')
const BillHistory = () => import('@/components/report/common/BillHistory')
const ExportTariff = () => import('@/components/report/common/ExportTariff')
const SR104E = () => import('@/components/report/common/SR104')
const SR104G = () => import('@/components/report/common/SR104')
const SR104W = () => import('@/components/report/common/SR104')
const RecentContracts = () => import('@/components/report/common/RecentContracts')
const SiteList = () => import('@/components/report/common/SiteList')
const CCL = () => import('@/components/report/common/CCL')
const Registrations = () => import('@/components/report/common/Registrations')
const Contract = () => import('@/components/report/common/Contract')
const AllContracts = () => import('@/components/report/common/AllContracts')
const NoticeLetter = () => import('@/components/report/common/NoticeLetter')
const BusinessClosed = () => import('@/components/report/common/BusinessClosed')
const AccountClass = () => import('@/components/report/common/AccountClass')
const AccountOwner = () => import('@/components/report/common/AccountOwner') 
const MatrixData = () => import('@/components/report/common/MatrixData')
const SupplierAnalysis = () => import('@/components/report/common/SupplierAnalysis')
const SpecialReport = () => import('@/components/report/common/SpecialReport')
const DataCollection = () => import('@/components/report/common/DataCollection')
const CapacityMarket = () => import('@/components/report/common/CapacityMarket')
const WaterTender =() => import('@/components/report/common/WaterTender')

//Extra Reports
const AccuralReport_Elec = () => import('@/components/report/common/AccuralReport_Elec')
const AccuralReport_Gas = () => import('@/components/report/common/AccuralReport_Gas')
const UtilDataReport = () => import('@/components/report/common/UtilDataReport_Elec')
const UtilDataReport_Gas = () => import('@/components/report/common/UtilDataReport_Gas')
const Mon_ApprovalReport = () => import('@/components/report/common/Mon_ApprovalReport')
const Mon_ApprovalReport_consumption = () => import('@/components/report/common/Mon_ApprovalReport_consumption')
const ConsumptionReport_CNB  = () => import('@/components/report/common/ConsumptionReport_CNB')

// SBB Reports
const SummaryReportElec = () => import('@/components/report/elec/SBB/SummaryReport')
const SummaryRptFrmAppRpt = () => import('@/components/report/elec/SBB/SummaryRptFrmAppRpt')
const NBCRptFrmAppRpt = () => import('@/components/report/SBB/NBCRptFrmAppRpt')
const EppingRptFrmAppRpt = () => import('@/components/report/elec/SBB/EppingReportAppRpt')
const EppingApprovalReport = () => import('@/components/report/elec/SBB/EppingApprovalReport')
const ThreadNeedleAppRpt = () => import('@/components/report/elec/SBB/ThreadNeedleAppRpt')
const ThreadNeedleGasAppRpt = () => import('@/components/report/gas/SBB/ThreadNeedleGasAppRpt')
const NonThreadNeedleAppRpt = () => import('@/components/report/elec/SBB/NonThreadNeedleAppRpt')
const NonThreadNeedleGasAppRpt = () => import('@/components/report/gas/SBB/NonThreadNeedleGasAppRpt')
//26/07 Ponni - Epping report for SBB gas 
const EppingRptFrmAppRptGas = () => import('@/components/report/gas/SBB/EppingReportAppRpt')
const EppingApprovalReportGas = () => import('@/components/report/gas/SBB/EppingApprovalReport')

//EIC Specific Report
const ApprovalReportEIC = () => import('@/components/report/eic/ApprovalReport')


//Contract Report
const contReport = () => import('@/components/more/contreport')


//Monarch Specific report
const ApprovalReportMonarch = () => import('@/components/report/monarch/ApprovalReport')

const routes = [
  {
    path: '/report/elec/approvalreport',
    name: 'ApprovalReportElec',
    component: ApprovalReportElec
  },
  {
    path: '/report/elec/consumptionreport',
    name: 'ConsumptionReportElec',
    component: ConsumptionReportElec
  },
  {
    path: '/report/elec/nhhtariffsummaryreport',
    name: 'NHHTariffSummaryReport',
    component: NHHTariffSummaryReport
  },
  {
    path: '/report/elec/hhtariffsummaryreport',
    name: 'HHTariffSummaryReport',
    component: HHTariffSummaryReport
  },
  {
    path: '/report/elec/gastariffsummaryreport',
    name: 'GasTariffSummaryReport',
    component: GasTariffSummaryReport
  },
  {
    path: '/report/elec/summaryreport',
    name: 'SummaryReportElec',
    component: SummaryReportElec
  },
  {
    path: '/report/elec/summaryrptfrmapprpt',
    name: 'SummaryRptFrmAppRpt',
    component: SummaryRptFrmAppRpt
  },
  {
    path: '/report/nbcrptfrmapprpt/:type',
    name: 'NBCRptFrmAppRpt',
    component: NBCRptFrmAppRpt
  },
  {
    path: '/report/elec/eppingrptfrmapprpt',
    name: 'EppingRptFrmAppRpt',
    component: EppingRptFrmAppRpt
  },
  {
    path: '/report/elec/eppingapprovalreport',
    name: 'EppingApprovalReport',
    component: EppingApprovalReport
  },
  //26/07 Ponni - Epping report for SBB gas
  {
    path: '/report/gas/eppingapprovalreport',
    name: 'EppingApprovalReport',
    component: EppingApprovalReportGas
  },
  {
    path: '/report/gas/eppingrptfrmapprpt',
    name: 'EppingRptFrmAppRpt',
    component: EppingRptFrmAppRptGas
  },
  {
    path: '/report/elec/hhapprovalreport',
    name: 'ApprovalReportHHElec',
    component: ApprovalReportHHElec
  },
  {
    path: '/report/gas/approvalreport',
    name: 'ApprovalReportGas',
    component: ApprovalReportGas
  },
  {
    path: '/report/elec/threadneedleapprpt',
    name: 'ThreadNeedleAppRpt',
    component: ThreadNeedleAppRpt
  },
  {
    path: '/report/gas/sbb/threadneedlegasapprpt',
    name: 'ThreadNeedleGasAppRpt',
    component: ThreadNeedleGasAppRpt
  }, 
  {
    path: '/report/elec/nonthreadneedleapprpt',
    name: 'NonThreadNeedleAppRpt',
    component: NonThreadNeedleAppRpt
  },
  {
    path: '/report/gas/sbb/nonthreadneedlegasapprpt',
    name: 'NonThreadNeedleGasAppRpt',
    component: NonThreadNeedleGasAppRpt
  }, 
  {
    path: '/report/unreport',
    name: 'UnReport',
    component: UnReport
  },
  {
    path: '/report/Commission',
    name: 'Commission',
    component: Commission,
    meta: { type: 'Commission' }
  },
  {
    path: '/report/NewCommission',
    name: 'NewCommission',
    component: NewCommission,
    meta: { type: 'NewCommission' }
  },
  {
    path: '/more/contReport',
    name: 'contReport',
    component: contReport
  },
  {
    path: '/report/DataCollection',
    name: 'DataCollection',
    component: DataCollection,
    meta: { type: 'DataCollection' }
  },
  {
    path: '/report/eic/approvalreport/:type',
    name: 'ApprovalReportEIC',
    component: ApprovalReportEIC
  },
  {
    path: '/report/preapprovalreport/:type',
    name: 'PreApprovalReport',
    component: PreApprovalReport
  },
  {
    path: '/report/monthlyconsumptionreport/:type',
    name: 'MonthlyConsumptionReport',
    component: MonthlyConsumptionReport
  },
  {
    path: '/report/billcountreport',
    name: 'BillCountReport',
    component: BillCountReport
  },
  {
    path: '/report/sr183',
    name: 'SR183',
    component: SR183,
    meta: { type: 'sr183' }
  },
  {
    path: '/report/sr160A',
    name: 'SR160A',
    component: SR160A,
    meta: { type: 'sr160A' }
  },
  {
    path: '/report/sr168',
    name: 'SR168',
    component: SR168,
    meta: { type: 'sr168' }
  },
  {
    path: '/report/sr164',
    name: 'HHData',
    component: SR164,
    meta: { type: 'sr164' }
  },
  {
    path: '/report/sr181',
    name: 'HHData',
    component: SR181,
    meta: { type: 'sr181' }
  },
  {
    path: '/report/sr104e/:type',
    name: 'SR104E',
    component: SR104E,
    meta: { type: 'sr104e' }
  },
  {
    path: '/report/sr104g/:type',
    name: 'SR104G',
    component: SR104G,
    meta: { type: 'sr104g' }
  },
  {
    path: '/report/sr104w/:type',
    name: 'SR104W',
    component: SR104W,
    meta: { type: 'sr104w' }
  },
  {
    path: '/report/hhdata',
    name: 'HHData',
    component: HHData,
    meta: { type: 'hhdata' }
  },
  {
    path: '/report/tr301',
    name: 'TR301',
    component: TR301,
    meta: { type: 'tr301' }
  },
  {
    path: '/report/sg184',
    name: 'SG184',
    component: SG184,
    meta: { type: 'sg184' }
  },
  {
    path: '/report/SG180',
    name: 'SG180',
    component: SG180,
    meta: { type: 'SG180' }
  },
  {
    path: '/report/SG181',
    name: 'SG181',
    component: SG181,
    meta: { type: 'SG181' }
  },
  {
    path: '/report/SG182',
    name: 'SG182',
    component: SG182,
    meta: { type: 'SG182' }
  },  {
    path: '/report/SG185',
    name: 'SG185',
    component: SG185,
    meta: { type: 'SG185' }
  },
  {
    path: '/report/SG185',
    name: 'SG185',
    component: SG185,
    meta: { type: 'SG185' }
  },
  {
    path: '/report/gr202',
    name: 'GR202',
    component: GR202,
    meta: { type: 'gr202' }
  },
  {
    path: '/report/tr335',
    name: 'TR335',
    component: TR335,
    meta: { type: 'tr335' }
  },
  {
    path: '/report/sd368',
    name: 'SD368',
    component: SD368,
    meta: { type: 'sd368' }
  },
  {
    path: '/report/sd369',
    name: 'SD369',
    component: SD369,
    meta: { type: 'sd369' }
  },
  {
    path: '/report/billhistory',
    name: 'BillHistory',
    component: BillHistory,
    meta: { type: 'billhistory' }
  },
  {
    path: '/report/capacitymarket',
    name: 'CapacityMarket',
    component: CapacityMarket,
    meta: { type: 'CapacityMarket' }
  },
  {
    path: '/report/WaterTender',
    name: 'WaterTender',
    component: WaterTender,
    meta: { type: 'WaterTender' }
  },
  {
    path: '/report/utildatareport_Elec',
    name: 'UtilDataReport',
    component: UtilDataReport,
    meta: { type: 'UtilDataReport' }
  },  
  {
    path: '/report/utildatareport_Gas',
    name: 'UtilDataReport_Gas',
    component: UtilDataReport_Gas,
    meta: { type: 'UtilDataReport_Gas' }
  },
  {
    path: '/report/accuralreport_Elec',
    name: 'AccuralReport_Elec',
    component: AccuralReport_Elec,
    meta: { type: 'AccuralReport_Elec' }
  },  
  {
    path: '/report/accuralreport_Gas',
    name: 'AccuralReport_Gas',
    component: AccuralReport_Gas,
    meta: { type: 'AccuralReport_Gas' }
  }, 
  {
    path: '/report/ConsumptionReport_CNB',
    name: 'ConsumptionReport_CNB',
    component: ConsumptionReport_CNB,
    meta: { type: 'ConsumptionReport_CNB' }
  },
  {
    path: '/report/RecentContracts',
    name: 'RecentContracts',
    component: RecentContracts,
    meta: { type: 'RecentContracts' }
  },
  {
    path: '/report/SiteList',
    name: 'SiteList',
    component: SiteList,
    meta: { type: 'SiteList' }
  },  
  {
    path: '/report/CCL',
    name: 'CCL',
    component: CCL,
    meta: { type: 'CCL' }
  },
  {
    path: '/report/Registrations',
    name: 'Registrations',
    component: Registrations,
    meta: { type: 'Registrations' }
  },
  {
    path: '/report/Contract',
    name: 'Contract',
    component: Contract,
    meta: { type: 'Contract' }
  },  
  {
    path: '/report/AllContracts',
    name: 'AllContracts',
    component: AllContracts,
    meta: { type: 'AllContracts' }
  },
  {
    path: '/report/NoticeLetter',
    name: 'NoticeLetter',
    component: NoticeLetter,
    meta: { type: 'NoticeLetter' }
  },
  {
    path: '/report/BusinessClosed',
    name: 'BusinessClosed',
    component: BusinessClosed,
    meta: { type: 'BusinessClosed' }
  },  
  {
    path: '/report/AccountClass',
    name: 'AccountClass',
    component: AccountClass,
    meta: { type: 'AccountClass' }
  },  
  {
    path: '/report/AccountOwner',
    name: 'AccountOwner',
    component: AccountOwner,
    meta: { type: 'AccountOwner' }
  }, 
  {
    path: '/report/MatrixData',
    name: 'MatrixData',
    component: MatrixData,
    meta: { type: 'MatrixData' }
  },
  {
    path: '/report/exporttariff',
    name: 'ExportTariff',
    component: ExportTariff,
    meta: { type: 'exporttariff' }
  },
  {
    path: '/report/sr529',
    name: 'SR529',
    component: SR529,
    meta: { type: 'sr529' }
  },
  {
    path: '/report/sr529w',
    name: 'SR529W',
    component: SR529W,
    meta: { type: 'sr529w' }
  },
  {
    path: '/report/sr130',
    name: 'SR130',
    component: SR130,
    meta: { type: 'sr130' }
  },
  {
    path: '/report/SupplierAnalysis',
    name: 'SupplierAnalysis',
    component: SupplierAnalysis,
    meta: { type: 'SupplierAnalysis' }
  },
  {
    path: '/report/monarch/approvalreport/:type',
    name: 'ApprovalReportMonarch',
    component: ApprovalReportMonarch
  },
  {
    path: '/report/Mon_ApprovalReport',
    name: 'Mon_ApprovalReport',
    component: Mon_ApprovalReport,
    meta: { type: 'Mon_ApprovalReport' }
  },
  {
    path: '/report/Mon_ApprovalReport_consumption',
    name: 'Mon_ApprovalReport_consumption',
    component: Mon_ApprovalReport_consumption,
    meta: { type: 'Mon_ApprovalReport_consumption' }
  },
  {
    path: '/report/SpecialReport',
    name: 'SpecialReport',
    component: SpecialReport,
    meta: { type: 'SpecialReport' }
  },
]

export default routes
