import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class EduOpportunityResolver extends BaseResolver {
  constructor () {
    super('eduopportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      BDMOpportunityId: item.BDMOpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      OpportunityStage: item.OpportunityStage,
      OpportunityStageName: item.OpportunityStageName,
      OpportunityOwnerId: item.OpportunityOwnerId,
      OpportunityOwnerName: item.OpportunityOwnerName,           
      EducationOpportunityId: item.EducationOpportunityId,
      ReferenceNumber: item.ReferenceNumber,
      EduOppReferenceNumber: item.EduOppReferenceNumber,
      Probability: item.Probability,
      ClosedDate: item.ClosedDate,
      OpportunityCurrency: item.OpportunityCurrency,
      PrimaryCampaignSource: item.PrimaryCampaignSource,
      ProfitValue: item.ProfitValue,
      TotalExpense: item.TotalExpense,
      TotalProjectCost: item.TotalProjectCost,
      NetProfit: item.NetProfit,
      NoOfSites: item.NoOfSites,
      EstimatedAnnualUtilSpend: item.EstimatedAnnualUtilSpend,
      isNHH: item.isNHH,
      isHH: item.isHH,
      isWater: item.isWater,
      isGas: item.isGas,
      CurrentProvider: item.CurrentProvider,
      EstimatedBudget: item.EstimatedBudget,
      KeyChallenges: item.KeyChallenges,
      ElecProcurement: item.ElecProcurement,
      GasProcurement: item.GasProcurement,
      WaterProcurement: item.WaterProcurement,
      ElecSAM: item.ElecSAM,
      GasSAM: item.GasSAM,
      WaterSAM: item.WaterSAM,
      ElecAuditRecovery: item.ElecAuditRecovery,
      GasAuditRecovery: item.GasAuditRecovery,
      WaterAuditRecovery: item.WaterAuditRecovery,
      ElecCostMgmt: item.ElecCostMgmt,
      GasCostMgmt: item.GasCostMgmt,
      WaterCostMgmt: item.WaterCostMgmt,
      ElecSupplierMgmt: item.ElecSupplierMgmt,
      GasSupplierMgmt: item.GasSupplierMgmt,
      WaterSupplierMgmt: item.WaterSupplierMgmt,
      ElecPerformMonitor: item.ElecPerformMonitor,
      GasPerformMonitor: item.GasPerformMonitor,
      WaterPerformMonitor: item.WaterPerformMonitor,
      NextStep: item.NextStep,
      Description: item.Description,
      LeadSource: item.LeadSource,
      ReasonForLoss: item.ReasonForLoss,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,
      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
