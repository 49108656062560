import { BaseResolver } from './BaseResolver'
import { util } from '../util'

export class ElecTariffResolver extends BaseResolver {
  constructor () {
    super('elecTariff')
  }

  resolve (item) {
    return {
      TariffId: item.TariffId,
      ElecDetailId: item.ElecDetailId, 
      AccountId: item.AccountId,
      CustomerId: item.CustomerId,
      LocationId: item.LocationId,
      DetailId: item.DetailId,
      CNB: item.CNB,
      Supplier: item.Supplier,
      AccountNo: item.AccountNo,
      SiteRef: item.SiteRef,
      StartDate: item.StartDate ? new Date(item.StartDate) : null,
      EndDate: item.EndDate ? new Date(item.EndDate) : null,
      RegistrationDate: item.RegistrationDate ? new Date(item.RegistrationDate) : null,
      ContractType: item.ContractType,
      CCL: item.CCL,
      Standing: item.Standing,
      StandingPeriod: item.StandingPeriod,
      Vat1: item.Vat1,
      Vat2: item.Vat2,
      VatExemption: item.VatExemption,
      EnergyType: item.EnergyType,
      PaymentType: item.PaymentType,
      PaymentDuration: item.PaymentDuration,
      ApplyEMRCharges: item.ApplyEMRCharges,
      CMSettlementLevy: item.CMSettlementLevy,
      CMSettlementLevyPeriod: item.CMSettlementLevyPeriod,
      CMObligationLevy: item.CMObligationLevy,
      CMObligationLevyPeriod: item.CMObligationLevyPeriod,
      UnitCharges: (item.UnitCharges || []),
      FixedCharges: (item.OtherCharges.filter(x => x.Type == 1) || []).map(otherCharge => {
        return {
          Id: otherCharge.Id,
          Code: otherCharge.Code,
          TariffId: otherCharge.TariffId,
          ChargeName: otherCharge.ChargeName,
          Type: otherCharge.Type,
          Price: otherCharge.Price,
          Period: otherCharge.Period,
          Periods: (otherCharge.Periods || []).map( p => {
            return {
              PeriodId: p.PeriodId,
              ChargeId: p.ChargeId,
              PeriodFrom: new Date(p.PeriodFrom),
              PeriodTo: new Date(p.PeriodTo),
              Price: p.Price
            }
          })
        }
      }),
      ConsumptionCharges: (item.OtherCharges.filter(x => x.Type == 2) || []).map(otherCharge => {
        return {
          Id: otherCharge.Id,
          Code: otherCharge.Code,
          TariffId: otherCharge.TariffId,
          ChargeName: otherCharge.ChargeName,
          Type: otherCharge.Type,
          Price: otherCharge.Price,
          Period: otherCharge.Period,
          Periods: (otherCharge.Periods || []).map( p => {
            return {
              PeriodId: p.PeriodId,
              ChargeId: p.ChargeId,
              PeriodFrom: new Date(p.PeriodFrom),
              PeriodTo: new Date(p.PeriodTo),
              Price: p.Price
            }
          })
        }
      }),
      Charges: (item.Charges || []).map(charge => {
        return {
          Id: charge.Id,
          MpanId: charge.MpanId,
          TariffId: charge.TariffId,
          Mpan: charge.Mpan,
          Day: charge.Day,
          DFlag: charge.Day && charge.Day > 0 ? true : false,
          Night: charge.Night,
          NFlag: charge.Night && charge.Night > 0 ? true : false,
          Evening: charge.Evening,
          EFlag: charge.Evening && charge.Evening > 0 ? true : false,
          Other: charge.Other,
          OFlag: charge.Other && charge.Other > 0 ? true : false,
          Availability: charge.Availability,
          AvailabilityCharge: charge.Availability ? charge.AvailabilityCharge : null,
          AvailabilityUnits: charge.Availability ? charge.AvailabilityUnits : null,
          ExtraAvailabilityCharge: charge.Availability ? charge.ExtraAvailabilityCharge : null,
          FIT: charge.FIT,
          FitFlag: charge.FIT && charge.FIT > 0 ? true : false,
          DecJanPeak: charge.DecJanPeak,
          DJPeakFlag: charge.DecJanPeak && charge.DecJanPeak > 0 ? true : false,
          DecJanOffPeak: charge.DecJanOffPeak,
          DJOffPeakFlag: charge.DecJanOffPeak && charge.DecJanOffPeak > 0 ? true : false,
          NovFebPeak: charge.NovFebPeak,
          NFPeakFlag: charge.NovFebPeak && charge.NovFebPeak > 0 ? true : false,
          NovFebOffPeak: charge.NovFebOffPeak,
          NFOffPeakFlag: charge.NovFebOffPeak && charge.NovFebOffPeak > 0 ? true : false,
          DecToFebPeak: charge.DecToFebPeak,
          DTFPeakFlag: charge.DecToFebPeak && charge.DecToFebPeak > 0 ? true : false,
          DecToFebOffPeak: charge.DecToFebOffPeak,
          DTFOffPeakFlag: charge.DecToFebOffPeak && charge.DecToFebOffPeak > 0 ? true : false,
          CreatedBy: charge.CreatedBy,
          CreatedDate: charge.CreatedDate,
          UpdatedBy: charge.UpdatedBy,
          UpdatedDate: charge.UpdatedDate,
          isdisabled: true
        }
      }),
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdatedDate,
      BillExists: item.BillExists || false,
      BillExistsDate: new Date(item.BillExistsDate),
      IsFlex: item.IsFlex || false,
      ApplyHHUnitCharges: item.ApplyHHUnitCharges || false,
      EMRCharges: (item.EMRCharges || []),
      ReconRO: item.ReconRO,
      ReconROFrequency: item.ReconROFrequency || 1,
      ReconROMonth: item.ReconROMonth || 1,
      ReconTUoS: item.ReconTUoS,
      ReconTUoSFrequency: item.ReconTUoSFrequency || 1,
      ReconTUoSMonth: item.ReconTUoSMonth || 1,
      ReconFIT: item.ReconFIT,
      ReconFITFrequency: item.ReconFITFrequency || 1,
      ReconFITMonth: item.ReconFITMonth || 1,
      ReconCMSSL: item.ReconCMSSL,
      ReconCMSSLFrequency: item.ReconCMSSLFrequency || 1,
      ReconCMSSLMonth: item.ReconCMSSLMonth || 1,
      ReconBSUoS: item.ReconBSUoS,
      ReconBSUoSFrequency: item.ReconBSUoSFrequency || 1,
      ReconBSUoSMonth: item.ReconBSUoSMonth || 1,
      ReconCMSEL: item.ReconCMSEL,
      ReconCMSELFrequency: item.ReconCMSELFrequency || 1,
      ReconCMSELMonth: item.ReconCMSELMonth || 1,
    }
  }
}
