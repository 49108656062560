var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("input", {
    ref: "ref1",
    staticClass: "input",
    style: _vm.width,
    attrs: {
      id: _vm.id,
      placeholder: _vm.placeholder,
      tabindex: _vm.tabindex,
      min: _vm.min,
      max: _vm.max,
      type: "number"
    },
    domProps: { value: _vm.value },
    on: {
      keyup: function($event) {
        return _vm.filterInput($event)
      },
      input: function($event) {
        return _vm.filterInput($event)
      },
      change: function($event) {
        return _vm.$emit("change")
      }
    }
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }