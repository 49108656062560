import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class ESOSDeliveryOpportunityResolver extends BaseResolver {
  constructor () {
    super('esosdeliveryopportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      BDMOpportunityId: item.BDMOpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      OpportunityStage: item.OpportunityStage,
      OpportunityStageName: item.OpportunityStageName,
      OpportunityOwnerId: item.OpportunityOwnerId,
      OpportunityOwnerName: item.OpportunityOwnerName,
      ESOSDeliveryOpportunityId: item.ESOSDeliveryOpportunityId,
      Probability: item.Probability,
      ClosedDate: item.ClosedDate,
      NewCNBNumber: item.NewCNBNumber,
      CNBNumber: item.CNBNumber,
      LeadAssessor: item.LeadAssessor,
      OtherLeadAssessorId: item.OtherLeadAssessorId,
      OtherLeadAssessor: item.OtherLeadAssessor,
      DataAnalyst: item.DataAnalyst,
      OtherDataAnalystId: item.OtherDataAnalystId,
      OtherDataAnalyst: item.OtherDataAnalyst,
      Cost: item.Cost,
      TCV: item.TCV,
      ContractSignDate: item.ContractSignDate,
      ComplianceMethod: item.ComplianceMethod,
      TotalExpense: item.TotalExpense,
      TotalProjectCost: item.TotalProjectCost,
      ProfitValue: item.ProfitValue,
      NetProfit: item.NetProfit,
      NetMargin: item.NetMargin,
      NoOfSites: item.NoOfSites,
      NoOfSitesAudited: item.NoOfSitesAudited,
      NoOfAudits: item.NoOfAudits,
      NoOfSitesReported: item.NoOfSitesReported,
      NoOfSitesLeadAssessed: item.NoOfSitesLeadAssessed,
      AnalystNote: item.AnalystNote,
      IntroEMailSentDate: item.IntroEMailSentDate,
      KickOffCallDate: item.KickOffCallDate,
      DaysSinceContractSigned: item.DaysSinceContractSigned,
      LOA: item.LOA,
      SEUToolStatus: item.SEUToolStatus,
      DataCollectionStatus: item.DataCollectionStatus,
      TECDataStatus: item.TECDataStatus,
      AuditDataStatus: item.AuditDataStatus,
      SEUReportStatus: item.SEUReportStatus,
      DaysSinceKickOff: item.DaysSinceKickOff,
      EvidencePackCompleted: item.EvidencePackCompleted,
      FinalReportStatus: item.FinalReportStatus,
      ReasonForLoss: item.ReasonForLoss,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,

      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
