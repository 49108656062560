var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm.content.length
    ? _c("div", { staticClass: "vtimeline" }, [
        _c(
          "div",
          { staticClass: "timeline is-centered" },
          [
            _vm._m(0),
            _vm._v(" "),
            _vm._l(_vm.content, function(item) {
              return [
                item.index > 0 && item.year !== _vm.content[item.index - 1].year
                  ? _c(
                      "header",
                      { key: "h" + item.index, staticClass: "timeline-header" },
                      [
                        _c("span", { staticClass: "tag is-info" }, [
                          _vm._v(
                            "\n          " + _vm._s(item.year) + "\n        "
                          )
                        ])
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _c(
                  "div",
                  { key: "t" + item.index, staticClass: "timeline-item" },
                  [
                    _vm._m(1, true),
                    _vm._v(" "),
                    _c("div", { staticClass: "timeline-content" }, [
                      _c("p", { staticClass: "heading" }, [
                        _vm._v(
                          "\n            " +
                            _vm._s(item.createdDate) +
                            "\n            "
                        ),
                        _c("small", [
                          _vm._v(
                            "\n              " +
                              _vm._s(item.createdBy) +
                              "\n            "
                          )
                        ])
                      ]),
                      _vm._v(" "),
                      _c("p", [_vm._v(_vm._s(item.title))])
                    ])
                  ]
                )
              ]
            }),
            _vm._v(" "),
            _vm._m(2)
          ],
          2
        )
      ])
    : _vm._e()
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "timeline-header" }, [
      _c("span", { staticClass: "tag is-medium" }, [
        _vm._v("\n        End\n      ")
      ])
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "timeline-marker is-icon is-primary" }, [
      _c("i", { staticClass: "fa fa-flag" })
    ])
  },
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("header", { staticClass: "timeline-header" }, [
      _c("span", { staticClass: "tag is-medium" }, [
        _vm._v("\n        Start\n      ")
      ])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }