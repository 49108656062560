var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "container vpagination" }, [
      _c("div", { staticClass: "container" }, [_vm._t("default")], 2),
      _vm._v(" "),
      _c("div", { staticClass: "columns" }, [
        _c(
          "div",
          { staticClass: "column is-four-fifths" },
          [
            _vm.totalRecords > _vm.elementsPerPage
              ? [
                  _c("span", [_vm._v("Page ")]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button is-small",
                      attrs: { disabled: _vm.currentPage < 2 },
                      on: {
                        click: function($event) {
                          return _vm.$emit("prevPage")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-chevron-left" })]
                  ),
                  _vm._v(" "),
                  _c("span", [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.currentPage) +
                        " of " +
                        _vm._s(_vm.totalPages) +
                        " \n          "
                    )
                  ]),
                  _vm._v(" "),
                  _c(
                    "button",
                    {
                      staticClass: "button is-small",
                      attrs: { disabled: _vm.currentPage === _vm.totalPages },
                      on: {
                        click: function($event) {
                          return _vm.$emit("nextPage")
                        }
                      }
                    },
                    [_c("i", { staticClass: "fa fa-chevron-right" })]
                  ),
                  _vm._v(" "),
                  _c("span", [_vm._v("| View ")]),
                  _vm._v(" "),
                  _c("div", { staticClass: "select" }, [
                    _c(
                      "select",
                      {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.pageSize,
                            expression: "pageSize"
                          }
                        ],
                        on: {
                          change: [
                            function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.pageSize = $event.target.multiple
                                ? $$selectedVal
                                : $$selectedVal[0]
                            },
                            function($event) {
                              return _vm.$emit("pageSizeChange", _vm.pageSize)
                            }
                          ]
                        }
                      },
                      [
                        _c("option", [_vm._v("20")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("50")]),
                        _vm._v(" "),
                        _c("option", [_vm._v("100")])
                      ]
                    )
                  ]),
                  _vm._v(" "),
                  _c("span", [_vm._v("records | ")])
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("span", [
              _vm._v("Found total " + _vm._s(_vm.filteredRecords) + " records")
            ]),
            _vm._v(" "),
            _vm.filteredRecords !== _vm.totalRecords
              ? _c("span", [
                  _vm._v(
                    "\n          (filtered from " +
                      _vm._s(_vm.totalRecords) +
                      " total entries)\n        "
                  )
                ])
              : _vm._e()
          ],
          2
        ),
        _vm._v(" "),
        _c("div", { staticClass: "column" })
      ])
    ]),
    _vm._v(" "),
    _c("table", { staticClass: "vtable has-background-white" }, [
      _c("thead", [
        _c(
          "tr",
          _vm._l(_vm.header, function(col, colIndex) {
            return _c(
              "th",
              {
                key: colIndex,
                class: { "clickable pos-rel": !(col.isSortable === false) },
                style: { width: col.width ? col.width + "px" : "" },
                on: {
                  click: function($event) {
                    return _vm.$emit("sortTable", col, colIndex)
                  }
                }
              },
              [
                _vm._v("\n          " + _vm._s(col.value) + "\n          "),
                col.key == _vm.sortColumn
                  ? _c(
                      "span",
                      {
                        staticClass: "icon pos-abs r-0",
                        class: _vm.direction ? "t-9" : "t-5"
                      },
                      [
                        _c("i", {
                          staticClass: "fa",
                          class: _vm.direction
                            ? "fa-sort-asc"
                            : "fa-sort-desc t-t"
                        })
                      ]
                    )
                  : _vm._e()
              ]
            )
          }),
          0
        )
      ]),
      _vm._v(" "),
      _c(
        "tbody",
        [
          _vm._l(_vm.content, function(row, rowIndex) {
            return _c(
              "tr",
              { key: rowIndex },
              _vm._l(_vm.header, function(col, colIndex) {
                return _c(
                  "td",
                  { key: colIndex },
                  [
                    col.custom
                      ? _vm._t("content", null, {
                          data: { row: row, col: col }
                        })
                      : [
                          col.onClick
                            ? _c(
                                "a",
                                {
                                  attrs: { tabindex: "0" },
                                  on: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return col.onClick(row)
                                    },
                                    click: function($event) {
                                      return col.onClick(row)
                                    }
                                  }
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(row[col.key]) +
                                      "\n            "
                                  )
                                ]
                              )
                            : _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(row[col.key]) +
                                    "\n            "
                                )
                              ])
                        ]
                  ],
                  2
                )
              }),
              0
            )
          }),
          _vm._v(" "),
          _vm.totalRecords === 0
            ? _c("tr", [
                _c("td", { attrs: { colspan: _vm.header.length } }, [
                  _vm._v("\n          No records found!\n        ")
                ])
              ])
            : _vm._e()
        ],
        2
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }