<template>
  <div class="modal" :class="time >= 1000 ? `is-active` : ``">
    <div class="modal-background" />
    <div class="modal-card">
      <header class="modal-card-head">
        <p class="modal-card-title sub-title">
          Session Expired Waring
        </p>
        <!-- <button class="delete" aria-label="close" /> -->
      </header>
      <section class="modal-card-body">
        <p>You have left this browser idle for 20 minutes.</p>
        <p>You will be automatically redirected to Login page after {{ time / 1000 }} seconds.</p>
      </section>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      time: 1000 * 60,
      timerId: null
    }
  },
  computed: {
    second () {
      return this.time / 1000
    }
  },
  created () {
    this.timerId = setInterval(() => {
      this.time -= 1000
      if (!this.$store.state.idleVue.isIdle) {
        clearInterval(this.timerId)
      }  
      if (this.time < 1) {
        clearInterval(this.timerId)
        window.location.href = '/login' //relative to domain
        return false
        // this.$router.push({ path: `/login` })
      }
    }, 1000)
  }
}
</script>
