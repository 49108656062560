<template>
  <div class="modal" :class="isOpen ? `is-active` : ``">
    <div class="modal-background" @click="$emit('closeModal')" />
    <div class="modal-card" :style="width == 640? '' : `width:${width}px`">
      <header class="modal-card-head p-md">
        <p class="modal-card-title sub-title">
          {{ title }}
        </p>
        <button v-if="closeButton" class="delete" @click="$emit('closeModal')" />
      </header>
      <section class="modal-card-body">
        <slot name="body" />
      </section>
      <footer v-if="showFooter" class="modal-card-foot p-sm">
        <div class="container">
          <div class="columns flex-wrap">
            <div class="column is-full text-right">
              <slot name="footer" />
            </div>
            <div class="column is-full p-t-0">
              <Error v-if="error" :message="error" />
            </div>
          </div>
        </div>
      </footer>
    </div>
  </div>
</template>

<script>
import Error from './Error'

export default {
  name: 'VModal',
  components: {
    Error
  },
  props: {
    isOpen: {
      type: Boolean,
      required: true
    },
    title: {
      type: String,
      required: true
    },
    closeButton: {
      type: Boolean,
      required: false,
      default: false
    },
    showFooter: {
      type: Boolean,
      required: false,
      default: true
    },
    error: {
      type: String,
      required: false,
      default: null
    },
    width: {
      type: Number,
      required: false,
      default: 640
    }
  },
  data () {
    return {
      positions: {
        clientX: undefined,
        clientY: undefined,
        movementX: 0,
        movementY: 0
      }
    }
  },
}
</script>

<style lang="scss">
#app {
  .modal {
    .modal-card-title {
      font-size: 15px;
    }
    .vdp-datepicker__calendar {
      position: fixed !important;
    }
    .modal-card-foot {
      background: #fff;
    }
  }
}

</style>
