var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "div",
        { attrs: { id: "app" } },
        [
          _vm.isAuthenticated ? _c("AppHeader") : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "app-view has-background-white-ter" },
            [
              _c("div", { staticClass: "page" }, [
                _c(
                  "div",
                  { staticClass: "page-body" },
                  [
                    _vm.siteId
                      ? _c(
                          "div",
                          { staticClass: "container" },
                          [
                            _c("Header", {
                              attrs: {
                                "site-id": _vm.siteId,
                                "tab-index": _vm.tabIndex
                              }
                            })
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _c("router-view")
                  ],
                  1
                )
              ]),
              _vm._v(" "),
              _vm.isAuthenticated && _vm.isIdle ? _c("ModalIdle") : _vm._e()
            ],
            1
          ),
          _vm._v(" "),
          _vm.isAuthenticated ? _c("AppFooter") : _vm._e()
        ],
        1
      ),
      _vm._v(" "),
      _vm.isAuthenticated && _vm.Feedback !== "False"
        ? _c("FeedBack")
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }