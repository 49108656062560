import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class NoteResolver extends BaseResolver {
  constructor () {
    super('note')
  }

  resolve (item) {
    return {
      Id: item.Id,
      Type: item.Type,
      TypeId: item.TypeId,
      Content: item.Content,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdatedDate ? util.convertFromApiDateTime(item.UpdatedDate) : null
    }
  }
}
