<template>
  <div class="loading">
    <div class="loading-overlay" />
    <div class="loading-overlay-container">
      <div class="loading-overlay-message">
        {{ message }}
        <div class="page-spinner-bar">
          <div class="bounce1" />
          <div class="bounce2" />
          <div class="bounce3" />
        </div>
      </div>
    </div>
  </div>  
</template>

<script>
export default {
  name: 'Loading',
  props: {
    message: {
      type: String,
      required: false,
      default: 'executing...'
    }
  }
}
</script>

<style lang="scss" scoped>
  #app {
    .loading {
      .loading-overlay {
        z-index: 10001;
        border: none;
        margin: 0;
        padding: 0;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        position: fixed;
      }
      .loading-overlay-container {
        position: absolute;
        top: 15px;
        left: 10px;
        font-size: 18px;
        font-weight: 300;
        padding: 10px;
        .loading-overlay-message {
          .page-spinner-bar {
            position: fixed;
            z-index: 10051;
            width: 100px;
            top: 40%;
            left: 50%;
            margin-left: -55px;
            text-align: center;
            > div {
              background: #47cbd7;
              margin: 0 5px;
              width: 18px;
              height: 18px;
              display: inline-block;
              -webkit-animation: bounceDelay 1.4s infinite ease-in-out;
              animation: bounceDelay 1.4s infinite ease-in-out;
              -webkit-animation-fill-mode: both;
              animation-fill-mode: both;
              border-radius: 100%!important;
              &.bounce1 {
                -webkit-animation-delay: -.32s;
                animation-delay: -.32s;
              }
              &.bounce2 {
                -webkit-animation-delay: -.16s;
                animation-delay: -.16s;
              }
            }
            
          }
          color: #32c5d2;
          font-weight: 600;
        }
      }

    }
  }  
</style>
