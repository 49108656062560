import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class SiteOccupancyResolver extends BaseResolver {
  constructor () {
    super('siteOccupancy')
  }

  resolve (item) {
    return {
      Id: item.Id,
      SiteId: item.SiteId,
      PeriodFrom: item.PeriodFrom,
      PeriodTo: item.PeriodTo,
      OccupiedArea: item.OccupiedArea,
      OccupiedAreaUnit: item.OccupiedAreaUnit,
      NetInternalArea: item.NetInternalArea,
      NetInternalAreaUnit: item.NetInternalAreaUnit,
      GrossArea: item.GrossArea,
      GrossAreaUnit: item.GrossAreaUnit,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdatedDate
    }
  }
}
