import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class GasBillResolver extends BaseResolver {
  constructor () {
    super('gasBill')
  }

  resolve (item) {

    const bill = item.Bill || {}
    const calculated = item.Calculated || {}

    return {
      Bill: {
        BillId: bill.BillId,
        AccountId: bill.AccountId ,
        SiteId: bill.SiteId,
        GasDetailId: bill.GasDetailId,
        SupplierId: bill.SupplierId,
        TaxPointDate: bill.TaxPointDate,
        InvoiceNo: bill.InvoiceNo,
        FromDate: bill.FromDate ? new Date(bill.FromDate) : null,
        ToDate: bill.ToDate ? new Date(bill.ToDate) : null,
        InvoiceType: bill.InvoiceType,
        UnitCharge: bill.UnitCharge,
        Standing: bill.Standing,
        CCL: bill.CCL,
        Other: bill.Other,
        Net: bill.Net,
        Vat1: bill.Vat1,
        Vat2: bill.Vat2 || 0,
        Total: bill.Total,
        Adjustment: bill.Adjustment || 0,
        TotaInvoicedkWh: bill.TotaInvoicedkWh || 0,
        Approved: bill.Approved,
        ApprovedBy: bill.ApprovedBy,
        ApprovedDate: bill.ApprovedDate,
        ReportId: bill.ReportId,
        ReportBy: bill.ReportBy,
        ReportDate: bill.ReportDate,
        ZendeskId: bill.ZendeskId,
        ZendeskStatus: bill.ZendeskStatus,
        CreditNoteId: bill.CreditNoteId,
        CreatedBy: bill.CreatedBy,
        CreatedDate: bill.CreatedDate,
        UpdatedBy: bill.UpdatedBy,
        UpdatedDate: bill.UpdatedDate,
        IsHistoricData: bill.IsOldSystemData ? bill.IsOldSystemData : false,
        SupplierName: bill.SupplierName
      },
      Calculated: {
        BillId: calculated.BillId,
        UnitCharge: calculated.UnitCharge,
        Standing: calculated.Standing,
        CCL: calculated.CCL,
        Other: calculated.Other,
        Net: (calculated.Net + bill.Adjustment).toFixed(2),
        Vat1: calculated.Vat1,
        Vat2: calculated.Vat2,
        Total: (calculated.Total + bill.Adjustment).toFixed(2),
        Ratio: calculated.Ratio ? calculated.Ratio : util.getRatio(calculated.Net + bill.Adjustment,bill.Net)
      },
      meters: (item.Readings || []).map(m => {
        return {
          GasMeterId: m.GasMeterId,
          Mprn: m.Mprn,
          MSN: m.MSN,
          Periods: (m.Periods || []).map(p => {
            return {
              BillTariffId: p.BillTariffId,
              TariffId: p.TariffId,
              StartDate: p.StartDate ? new Date(p.StartDate) : null,
              EndDate: p.EndDate ? new Date(p.EndDate) : null,
              MeterReading: (p.MeterReading || []).map(r => {
                return {
                  Id: r.Id,
                  TariffId: p.TariffId,
                  BillTariffId: r.BillTariffId,
                  StartDate: r.StartDate ? new Date(r.StartDate) : null,
                  EndDate: r.EndDate ? new Date(r.EndDate) : null,
                  From: r.From,
                  To: r.To,
                  ReadType: r.ReadType,
                  CorFactor: r.CorFactor,
                  CalValue: r.CalValue,
                  disabledDates: {
                    from: new Date(r.EndDate),
                    to: new Date(r.StartDate)
                  }
                }
              })
            }
          }),
          FixedCharges: (item.FACCharges || []).filter(x => x.Type === 1 && x.GasMeterId === m.GasMeterId).map(f => {
            return {
              Id: f.Id,
              GasMeterId: f.GasMeterId,
              Code: f.Code,
              ChargeName: f.ChargeName,
              Price: f.Price,
              Calculated: f.Calculated
            }
          }),
          ConsumptionCharges: (item.FACCharges || []).filter(x => x.Type === 2 && x.GasMeterId === m.GasMeterId).map(f => {
            return {
              Id: f.Id,
              GasMeterId: f.GasMeterId,
              Code: f.Code,
              ChargeName: f.ChargeName,
              Price: f.Price,
              Calculated: f.Calculated,
            }
          })
        }
      })
    }
  }
}
