import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class AMROpportunityResolver extends BaseResolver {
  constructor () {
    super('amropportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      AMROpportunityId: item.AMROpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      RelatedCustomer: item.RelatedCustomer,
      TenantBillingOpportunityId: item.TenantBillingOpportunityId,
      ClosedDate: item.ClosedDate,
      OpportunityStage: item.OpportunityStage,
      OpportunityStageName: item.OpportunityStageName,
      OpportunityCurrency: item.OpportunityCurrency,
      OpportunityOwnerId: item.OpportunityOwnerId,
      OpportunityOwnerName: item.OpportunityOwnerName,
      Probability   : item.Probability   ,
      CNBNumber: item.CNBNumber,
      NewCNBNumber: item.NewCNBNumber,
      ProfitValue: item.ProfitValue,
      TotalExpense: item.TotalExpense,
      TotalProjectCost: item.TotalProjectCost,
      NetProfit: item.NetProfit,
      SiteSurveyDate: item.SiteSurveyDate,
      SurveyorId: item.SurveyorId,
      NoOfRelatedSites: item.NoOfRelatedSites,
      OpportunityValue: item.OpportunityValue,
      SiteSurveyFileUploaded: item.SiteSurveyFileUploaded,
      HWAMRQuotationUploaded: item.HWAMRQuotationUploaded,
      InvoiceNumber: item.InvoiceNumber,
      QuoteAcceptanceDate: item.QuoteAcceptanceDate,
      WorkScheduledDate: item.WorkScheduledDate,
      WorksReportUploaded: item.WorksReportUploaded,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,

      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
