import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class BillHistoryResolver extends BaseResolver {
  constructor () {
    super('billHistory')
  }

  resolve (item) {
    return {
      TariffType: item.TariffType,
      BillId: item.BillId,
      InvoiceNo: item.InvoiceNo,
      Approved: item.Approved,
      ZendeskId: item.ZendeskId,
      ZendeskStatus: item.ZendeskStatus,
      InvoiceType: item.InvoiceType,
      Supplier: item.Supplier,
      FromDate: item.FromDate ? new Date(item.FromDate) : null,
      ToDate: item.ToDate ? new Date(item.ToDate) : null,
      Period: item.FromDate && item.ToDate ? `${util.convertToDDMMYYYY(item.FromDate)} - ${util.convertToDDMMYYYY(item.ToDate)}` : null,
      Net: item.Net,
      Vat1: item.Vat1,
      Vat2: item.Vat2,
      Vat: item.Vat,
      Total: item.Total,
      BilledkWh: item.kWh,
      TotaInvoicedkWh: item.TotaInvoicedkWh,
      ApprovedDate: item.ApprovedDate ? `${util.convertToDDMMYYYY(item.ApprovedDate)}` : null,
      ReportId: item.ReportId,
      ReportDate: item.ReportDate ? `${util.convertToDDMMYYYY(item.ReportDate)}` : null,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdatedDate,
      CreditNoteId: item.CreditNoteId,
      Checked: false,
      InvoiceNoteExists: item.InvoiceNoteExists
    }
  }
}
