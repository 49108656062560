<template>
  <div>
    <div id="app">
      <AppHeader v-if="isAuthenticated" />
      <div class="app-view has-background-white-ter">
        <div class="page">
          <div class="page-body">
            <div v-if="siteId" class="container">
              <Header :site-id="siteId" :tab-index="tabIndex" />
            </div>
            <router-view />    
          </div>
        </div>
        <ModalIdle v-if="isAuthenticated && isIdle" />
      </div>
      <AppFooter v-if="isAuthenticated" />
    </div>
    <FeedBack v-if="isAuthenticated && Feedback !== 'False'" />
  </div>  
</template>
<script>
import { mapGetters } from 'vuex'
import ModalIdle from '@/components/shared/idle/ModalIdle'
import Header from './components/shared/ValidationHeader'
import FeedBack from '@/components/user/FeedBack'


export default {
  name: 'AppLayout',
  components: {
    ModalIdle,
    Header,
    FeedBack
  },
  data () {
    return {
      version: this.$root.Version
    }
  },
  computed: {
    ...mapGetters([ 'isAuthenticated', 'userName', 'Feedback' ]),
    isIdle () {
      return this.$store.state.idleVue.isIdle
    },
    siteId () {
      if (this.$route.path.includes('/site/')) {
        return this.$route.params ? this.$route.params.id : null
      } else {
        return null
      }
    },
    tabIndex () {
      if (this.siteId) {
        return this.$route.meta.tabIndex
      } else {
        return null
      }
    }
  }
}
</script>
<style lang="sass">
  @import "./assets/sass/app.scss";
</style>