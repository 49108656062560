var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { class: "notification is-light pos-rel " + _vm.className }, [
      _vm._v("\n    " + _vm._s(_vm.message) + "\n    "),
      _c(
        "span",
        {
          staticClass: "closeable",
          on: {
            click: function($event) {
              return _vm.close()
            }
          }
        },
        [_vm._v("\n      ×\n    ")]
      )
    ])
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }