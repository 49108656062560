var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      ref: "edit",
      staticClass: "button is-light is-small vedit",
      on: { click: _vm.edit }
    },
    [_c("i", { staticClass: "fa fa-edit" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }