import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class DECOpportunityResolver extends BaseResolver {
  constructor () {
    super('decopportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      BDMOpportunityId: item.BDMOpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      OpportunityStage: item.OpportunityStage,
      OpportunityStageName: item.OpportunityStageName,
      OpportunityOwnerId: item.OpportunityOwnerId,
      OpportunityOwnerName: item.OpportunityOwnerName,
      DECOpportunityId: item.DECOpportunityId,
      Probability: item.Probability,
      ClosedDate: item.ClosedDate,
      OpportunityCurrency: item.OpportunityCurrency,
      Service: item.Service,
      NewCNBNumber: item.NewCNBNumber,
      CNBNumber: item.CNBNumber,
      ProfitValue: item.ProfitValue,
      TotalExpense: item.TotalExpense,
      TotalProjectCost: item.TotalProjectCost,
      NetProfit: item.NetProfit,
      ERMRecordedErrors: item.ERMRecordedErrors,
      AnalyseError: item.AnalyseError,
      TenderSiteDetailCount: item.TenderSiteDetailCount,
      TenderSummaryCount: item.TenderSummaryCount,
      CertificateSiteDetailCount: item.CertificateSiteDetailCount,
      CertificateSummaryCount: item.CertificateSummaryCount,
      TotalMonarchFee: item.TotalMonarchFee,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,

      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
