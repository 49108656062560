var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "vpanel", class: _vm.isOpen ? "" : "collapse" },
    [
      _c(
        "div",
        { staticClass: "vpanel-heading" },
        [
          _vm.isClosable
            ? _c(
                "a",
                {
                  on: {
                    click: function($event) {
                      return _vm.togglePanel()
                    }
                  }
                },
                [_vm._t("heading")],
                2
              )
            : _vm._t("heading")
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "transition",
        {
          attrs: {
            "enter-active-class": "enter-active",
            "leave-active-class": "leave-active"
          },
          on: {
            "before-enter": _vm.beforeEnter,
            enter: _vm.enter,
            "after-enter": _vm.afterEnter,
            "before-leave": _vm.beforeLeave,
            leave: _vm.leave,
            "after-leave": _vm.afterLeave
          }
        },
        [
          _vm.isOpen
            ? _c("div", { staticClass: "vpanel-content" }, [
                _c("div", { staticClass: "p-md" }, [_vm._t("content")], 2)
              ])
            : _vm._e()
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }