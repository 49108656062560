import { BaseResolver } from './BaseResolver'
import { ContactResolver } from './ContactResolver'
import { util } from '../../services'

const contactResolver = new ContactResolver()

export class AccountResolver extends BaseResolver {
  constructor () {
    super('account')
  }

  resolve (item) {
    return {
      Id: item.Id,
      Name: item.Name,
      AccountType: item.AccountType,
      AccountOwnerId: item.AccountOwnerId,
      Description: item.Description,
      Type: item.Type,
      TypeId: item.TypeId,
      AccountIndustry: item.AccountIndustry,
      ParentAccount: item.ParentAccount,
      Website: item.Website,
      AccountCurrency: item.AccountCurrency,
      PrimaryContact: item.PrimaryContact,
      AccountSharing: item.AccountSharing,
      AccountSource: item.AccountSource,
      Industry: item.Industry,
      URN: item.URN,
      UKPRN: item.UKPRN,
      LocalAuthority: item.LocalAuthority,
      EstablishmentType: item.EstablishmentType,
      AccountNumber: item.AccountNumber,
      SICCodeSection: item.SICCodeSection,
      SICCodeDivision: item.SICCodeDivision,
      DecisionByParentAccount: item.DecisionByParentAccount,
      LastMeetingDate: item.LastMeetingDate,
      SubType: item.SubType,
      Fax: item.Fax,
      SICCode: item.SICCode,
      EICAccountID: item.EICAccountID,
      AccountSite: item.AccountSite,
      CoreServices: item.CoreServices,
      ElecUtility: item.ElecUtility,
      GasUtility: item.GasUtility,
      WaterUtility: item.WaterUtility,
      TelecomsUtility: item.TelecomsUtility,
      WasteUtility: item.WasteUtility,
      BusinessType: item.BusinessType,
      KindOfBusiness: item.KindOfBusiness,
      LOA: item.LOA,
      CopyBill: item.CopyBill,
      FuelType: item.FuelType,
      MeterPointIdentifier: item.MeterPointIdentifier,
      FinalScore: item.FinalScore,
      CheckedStatus: item.CheckedStatus,
      NumberObjection: item.NumberObjection,
      CreditCheck: item.CreditCheck,
      ContractLength: item.ContractLength,
      ProfileClass: item.ProfileClass,
      TariffName: item.TariffName,
      GNEDate: item.GNEDate,
      POADate: item.POADate,
      POAOutcome: item.POAOutcome,
      EarliestOppurtunityDate: item.EarliestOppurtunityDate,
      PriceR1: item.PriceR1,
      PriceR2: item.PriceR2,
      PriceR3: item.PriceR3,
      PriceSC: item.PriceSC,
      R1KWHS: item.R1KWHS,
      R2KWHS: item.R2KWHS,
      R3KWHS: item.R3KWHS,
      KWHSTotal: item.KWHSTotal,
      Margin: item.Margin,
      Phone: item.Phone,
      BillingStreet: item.BillingStreet,
      BillingCity: item.BillingCity,
      BillingState: item.BillingState,
      BillingCountry: item.BillingCountry,
      BillingPostalCode: item.BillingPostalCode,
      ShippingStreet: item.ShippingStreet,
      ShippingCity: item.ShippingCity,
      ShippingState: item.ShippingState,
      ShippingCountry: item.ShippingCountry,
      ShippingPostalCode: item.ShippingPostalCode,
      
      ElecProcurement: item.ElecProcurement,
      GasProcurement: item.GasProcurement,
      WaterProcurement: item.WaterProcurement,
      ElecSAM: item.ElecSAM,
      GasSAM: item.GasSAM,
      WaterSAM: item.WaterSAM,
      ElecAuditRecovery: item.ElecAuditRecovery,
      GasAuditRecovery: item.GasAuditRecovery,
      WaterAuditRecovery: item.WaterAuditRecovery,
      ElecCostMgmt: item.ElecCostMgmt,
      GasCostMgmt: item.GasCostMgmt,
      WaterCostMgmt: item.WaterCostMgmt,
      ElecSupplierMgmt: item.ElecSupplierMgmt,
      GasSupplierMgmt: item.GasSupplierMgmt,
      WaterSupplierMgmt: item.WaterSupplierMgmt,
      ElecPerformMonitor: item.ElecPerformMonitor,
      GasPerformMonitor: item.GasPerformMonitor,
      WaterPerformMonitor: item.WaterPerformMonitor,
      CouncilTaxSvc: item.CouncilTaxSvc,
      VoidPropMgmt: item.VoidPropMgmt,
      TenantBilling: item.TenantBilling,
      AgencyInvMgmt: item.AgencyInvMgmt,
      SiteWorks: item.SiteWorks,
      TenantEngagement: item.TenantEngagement,
      EnergyEffMgmt: item.EnergyEffMgmt,

      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,
      Status: item.Status,
      AccountOwner: item.AccountOwner,
      PoundRange: item.PoundRange,
      PercentRange: item.PercentRange,
      CustomerTypeId: item.CustomerTypeId,
      CustomerTypeRef: item.CustomerTypeRef,

      BillingAddress: util.getAddress(item.BillingStreet, item.BillingCity, item.BillingState, item.BillingPostalCode, item.BillingCountry),
      ShippingAddress: util.getAddress(item.ShippingStreet, item.ShippingCity, item.ShippingState, item.ShippingPostalCode, item.ShippingCountry),
      Contacts: (item.Contacts || []).map(map => contactResolver.resolve(map)),
      Notes: (item.Notes || []).reverse().map( (map, index) => {
        return {
          index: index,
          title: map.Title === 'Untitled Note' ? map.TextPreview : map.Title,
          textPreview: map.Title === 'Untitled Note'? null : map.TextPreview,
          createdBy: map.CreatedBy,
          createdDate: map.CreatedDate ? util.convertFromApiDateTime(map.CreatedDate) : null,
          year: map.CreatedDate ? (new Date(map.CreatedDate)).getFullYear() : null
        }
      })
    }
  }
}
