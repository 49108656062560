import { BaseResolver } from './BaseResolver'

export class ElecDetailResolver extends BaseResolver {
  constructor () {
    super('elecDetail')
  }

  resolve (item) {
    return {
      Id: item.Id,
      AccountId: item.AccountId, 
      SiteId: item.SiteId,
      TariffType: item.TariffType,
      StatusHistory: item.StatusHistory,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      UpdatedBy: item.UpdatedBy,
      UpdatedDate: item.UpdatedDate,
      tabIndex: 1,
      TariffExists: item.TariffExists,
      BillExists: item.BillExists,
      BillExistsDate: item.BillExistsDate,
      HHBillExistsDate: item.HHBillExistsDate,
      BillExistsFromDate: item.BillExistsFromDate,
      HHBillExistsFromDate: item.HHBillExistsFromDate,
      AccountReference: item.AccountReference == null ? (item.TariffType == 2 ? 'HH - ' + item.Id : 'NHH - ' + item.Id) : item.AccountReference,
      AccountAlias: item.AccountAlias == null ? (item.TariffType == 2 ? 'HH - ' + item.Id : 'NHH - ' + item.Id) : item.AccountAlias,
      AccountStatus: item.AccountStatus == null ? 1 : (item.AccountStatus == 'Active' ? 1 : 2),
      ActivatedDate: item.ActivatedDate == null ? item.CreatedDate : item.ActivatedDate,
      InactiveDate: item.AccountStatus != null && item.AccountStatus == 'Closed' ? item.InactiveDate : null,
      SiteStartDate: item.SiteHistoryStartDate == null ? item.SiteStartDate : item.SiteHistoryStartDate,
      Meters: (item.Meters || []).map(meter => {
        return {
          Id: meter.Id,
          MSN: meter.MSN,
          Mpan: meter.MPAN,
          Status: meter.Status,
          StartDate: meter.StartDate,
          EndDate: meter.EndDate,
          Meterref: meter.Meterref,
          ExpenseCode: meter.ExpenseCode,
          KeyRequired: meter.KeyRequired,
          LocationOf: meter.LocationOf,
          MeterUsageArea: meter.MeterUsageArea,
          SpecialRequirement: meter.SpecialRequirement,
          CreatedBy: meter.CreatedBy,
          CreatedDate: meter.CreatedDate,
          UpdatedBy: meter.UpdatedBy,
          UpdatedDate: meter.UpdatedDate,
          StatusHistory: meter.StatusHistory,
          Mpans: meter.Mpans,
          TariffType: item.TariffType,
          TariffExists: item.TariffExists,
          BillExists: item.BillExists,
          BillExistsDate: item.BillExistsDate,
          HHBillExistsDate: item.HHBillExistsDate,
          Unit: meter.Unit,
          EAC: item.EAC,
          EACList: item.EACList || [],
          ElecMeterType:item.ElecMeterType == 0 ? null : item.ElecMeterType,
          ElecDetailsId:meter.ElecDetailsId,
          Metertype:meter.Metertype,//== null ? 1 : meter.UtilityType,
          DNO: meter.DNO,
          SupplyVoltage: item.SupplyVoltage == null ? 1 : item.SupplyVoltage,
          AgreedCapacity: item.AgreedCapacity,
          AgreedCapacityList: item.AgreedCapacityList || [],
          CCLPercentage: item.CCLPercentage,
          CCLExemptionList: item.CCLExemptionList || [],
          VATPercentage: item.VATPercentage,
          VATExemptionList: item.VATExemptionList || [],
          
        }
      })
    }
  }
}
