import { actions, mutations } from '../constants'
import { http } from '../../services'
import { SiteResolver } from '../../services/resolvers'

const siteResolver = new SiteResolver()

export default {
  state: {
    site: null,
    gi: localStorage.getItem('gi') || null,
    ei: localStorage.getItem('ei') || null,
    et: localStorage.getItem('et') || null,
    gSuppliers: [],
    eSuppliers: [],
    retailWaterSuppliers: [],
    wholesaleWaterSuppliers: [],
    elecOtherCharges: [],
    oppContacts: [],
    oppContactRoles: [],
    waterFixedCharges: [],
    waterFixedChargesUnMetered: [],
    waterUnitCharges:[],
    waterUnitClass:[],
    gasOtherCharges: [],
    unitCharges: [],
    supplyType:[],
    transporterType:[],
    accountOwners:[],
    accountTypes:[],
    LDZExitZone:[],
    LDZExitZoneDescription:[],
    wtdtid: localStorage.getItem('wtdtid') || null,
    msndetails: [],
    wtrCharges: [],
    WaterMeterMake:[],
    WaterMeterSize: [],
    WaterPropertySize:[],
    WaterMeasureType:[],
    multipleWaterMeter: localStorage.getItem('multipleWaterMeter') || null,
  },

  actions: {
    [actions.validation.getSite] ({ state, commit }, params) {
      if (state.site && state.site.id && state.site.id.toString() === params.id.toString()) {
        if (params.refresh) {
          // Continue
        } else {
          return state.site
        }
      }
      return http.get(`site/${params.id}`).then(response => {
        commit(mutations.validation.getSite, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.gSuppliers] ({ state, commit }) {
      if (state.gSuppliers && state.gSuppliers.length > 0) {
        return state.gSuppliers
      }
      return http.get(`supplier/get/G`).then(response => {
        commit(mutations.validation.gSuppliers, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    [actions.validation.retailWaterSuppliers] ({ state, commit }) {
      if (state.retailWaterSuppliers && state.retailWaterSuppliers.length > 0) {
        return state.retailWaterSuppliers
      }
      return http.get(`supplier/get/RW`).then(response => {
        commit(mutations.validation.retailWaterSuppliers, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    [actions.validation.wholesaleWaterSuppliers] ({ state, commit }) {
      if (state.wholesaleWaterSuppliers && state.wholesaleWaterSuppliers.length > 0) {
        return state.wholesaleWaterSuppliers
      }
      return http.get(`supplier/get/WW`).then(response => {
        commit(mutations.validation.wholesaleWaterSuppliers, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.eSuppliers] ({ state, commit }) {
      if (state.eSuppliers && state.eSuppliers.length > 0) {
        return state.eSuppliers
      }
      return http.get(`supplier/get/E`).then(response => {
        commit(mutations.validation.eSuppliers, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.gi] ({ commit }, id) {
      commit(mutations.validation.gi, id)
    },

    [actions.validation.ei] ({ commit }, { ei, type }) {
      commit(mutations.validation.ei, { ei, type })
    },

    [actions.validation.elecOtherCharges] ({ state, commit }) {
      if (state.elecOtherCharges && state.elecOtherCharges.length > 0) {
        return state.elecOtherCharges
      }
      return http.get(`misc/tariffothercharges/1`).then(response => {
        commit(mutations.validation.elecOtherCharges, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.gasOtherCharges] ({ state, commit }) {
      if (state.gasOtherCharges && state.gasOtherCharges.length > 0) {
        return state.gasOtherCharges
      }
      return http.get(`misc/tariffothercharges/2`).then(response => {
        commit(mutations.validation.gasOtherCharges, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.unitCharges] ({ state, commit }) {
      if (state.unitCharges && state.unitCharges.length > 0) {
        return state.unitCharges
      }
      return http.get(`misc/tariffunitcharges`).then(response => {
        commit(mutations.validation.unitCharges, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.supplyType] ({ state, commit }) {
      if (state.supplyType && state.supplyType.length > 0) {
        return state.supplyType
      }
      return http.get(`supplyType`).then(response => {
        commit(mutations.validation.supplyType, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.transporterType] ({ state, commit }) {
      if (state.transporterType && state.transporterType.length > 0) {
        return state.transporterType
      }
      return http.get(`transporterType`).then(response => {
        commit(mutations.validation.transporterType, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.accountOwners] ({ state, commit }) {
      if (state.accountOwners && state.accountOwners.length > 0) {
        return state.accountOwners
      }
      return http.get(`accountOwners`).then(response => {
        commit(mutations.validation.accountOwners, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.accountTypes] ({ state, commit }) {
      if (state.accountTypes && state.accountTypes.length > 0) {
        return state.accountTypes
      }
      return http.get(`accountTypes`).then(response => {
        commit(mutations.validation.accountTypes, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    
    [actions.validation.LDZExitZone] ({ state, commit }) {
      if (state.LDZExitZone && state.LDZExitZone.length > 0) {
        return state.LDZExitZone
      }
      return http.get(`LDZExitZone/1`).then(response => { 
        commit(mutations.validation.LDZExitZone, response)
      }).catch(error => {
        return Promise.reject(error)
      })

    },
    
    
    [actions.validation.LDZExitZoneDescription] ({ state, commit }) {
      if (state.LDZExitZoneDescription && state.LDZExitZoneDescription.length > 0) {
        return state.LDZExitZoneDescription
      }
      return http.get(`LDZExitZone/2`).then(response => { 
        commit(mutations.validation.LDZExitZoneDescription, response)
      }).catch(error => {
        return Promise.reject(error)
      })

    },

    [actions.validation.wtdtid] ({ commit }, id) {
      commit(mutations.validation.wtdtid, id)
    },

    [actions.validation.multipleWaterMeter] ({ commit }, id) {
      commit(mutations.validation.multipleWaterMeter, id)
    },
    [actions.validation.msndetails] ({ state, commit }, params) {
      if (state.msndetails && state.msndetails.length > 0) {
        if (params.refresh) {
          // Continue
        } else {
          return state.msndetails
        }
      }
      return http.get(`/water/wtrmeterdetails/getmsn/0/${params.id}/${params.fromdt}/${params.todt}`).then(response => {
        commit(mutations.validation.msndetails, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
  
    [actions.validation.wtrCharges] ({ state, commit }) {
      if (state.wtrCharges && state.wtrCharges.length > 0) {
        return state.wtrCharges
      }
      return http.get(`/water/charges`).then(response => {
        commit(mutations.validation.wtrCharges, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    [actions.validation.WaterMeterMake] ({ state, commit }) {
      /* if (state.WaterMeterMake && state.WaterMeterMake.length > 0) {
        return state.WaterMeterMake
      } */
      return http.get(`/water/metermake`).then(response => {
        commit(mutations.validation.WaterMeterMake, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.WaterMeterSize] ({ state, commit }) {
      /* if (state.WaterMeterSize && state.WaterMeterSize.length > 0) {
        return state.WaterMeterSize
      } */
      return http.get(`/water/metersake`).then(response => {
        commit(mutations.validation.WaterMeterSize, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },

    [actions.validation.WaterPropertySize] ({ state, commit }) {
      if (state.WaterPropertySize && state.WaterPropertySize.length > 0) {
        return state.WaterPropertySize
      }
      return http.get(`/water/propertysize`).then(response => {
        commit(mutations.validation.WaterPropertySize, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    [actions.validation.WaterMeasureType] ({ state, commit }) {
      if (state.WaterMeasureType && state.WaterMeasureType.length > 0) {
        return state.WaterMeasureType
      }
      return http.get(`/water/measuretype`).then(response => {
        commit(mutations.validation.WaterMeasureType, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },    
    [actions.validation.oppContacts] ({ state, commit }) {
      if (state.oppContacts && state.oppContacts.length > 0) {
        return state.oppContacts
      }
      return http.get(`contact/getOppContacts`).then(response => {
        commit(mutations.validation.oppContacts, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    [actions.validation.oppContactRoles] ({ state, commit }) {
      if (state.oppContactRoles && state.oppContactRoles.length > 0) {
        return state.oppContactRoles
      }
      return http.get(`opportunity/getContactRoles`).then(response => {
        commit(mutations.validation.oppContactRoles, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    [actions.validation.waterFixedCharges] ({ state, commit }) {
      if (state.waterFixedCharges && state.waterFixedCharges.length > 0) {
        return state.waterFixedCharges
      }
      return http.get(`water/tariff/getfixedCharges`).then(response => {
        commit(mutations.validation.waterFixedCharges, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    [actions.validation.waterFixedChargesUnMetered] ({ state, commit }) {
      if (state.waterFixedChargesUnMetered && state.waterFixedChargesUnMetered.length > 0) {
        return state.waterFixedChargesUnMetered
      }
      return http.get(`water/tariff/getUnMeteredfixedCharges`).then(response => {
        commit(mutations.validation.waterFixedChargesUnMetered, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    [actions.validation.waterUnitCharges] ({ state, commit }) {
      if (state.waterUnitCharges && state.waterUnitCharges.length > 0) {
        return state.waterUnitCharges
      }
      return http.get(`water/tariff/getUnitCharges`).then(response => {
        commit(mutations.validation.waterUnitCharges, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
    [actions.validation.waterUnitClass] ({ state, commit }) {
      if (state.waterUnitClass && state.waterUnitClass.length > 0) {
        return state.waterUnitClass
      }
      return http.get(`water/tariff/getUnitClass`).then(response => {
        commit(mutations.validation.waterUnitClass, response)
      }).catch(error => {
        return Promise.reject(error)
      })
    },
  },

  mutations: {
    [mutations.validation.getSite] (state, data) {
      state.site = siteResolver.resolve(data) 
    },

    [actions.validation.gSuppliers] (state, data) {
      state.gSuppliers = data 
    },

    [actions.validation.retailWaterSuppliers] (state, data) {
      state.retailWaterSuppliers = data 
    }, 
    
    [actions.validation.wholesaleWaterSuppliers] (state, data) {
      state.wholesaleWaterSuppliers = data 
    },
    [actions.validation.eSuppliers] (state, data) {
      state.eSuppliers = data 
    },

    [mutations.validation.gi] (state, data) {
      state.gi = data
      localStorage.setItem('gi', data)
    },

    [mutations.validation.ei] (state, data) {
      state.ei = data.ei
      state.et = data.type,
      localStorage.setItem('ei', data.ei)
      localStorage.setItem('et', data.type)
    },

    [mutations.validation.elecOtherCharges] (state, data) {
      state.elecOtherCharges = data
    },

    [mutations.validation.gasOtherCharges] (state, data) {
      state.gasOtherCharges = data
    },

    [mutations.validation.unitCharges] (state, data) {
      state.unitCharges = data
    },

    [actions.validation.supplyType] (state, data) {
      state.supplyType = data 
    },
    
    [actions.validation.transporterType] (state, data) {
      state.transporterType = data 
    },
    
    [actions.validation.accountOwners] (state, data) {
      state.accountOwners = data 
    },

    [actions.validation.accountTypes] (state, data) {
      state.accountTypes = data 
    },
    
    [actions.validation.LDZExitZone] (state, data) {
      state.LDZExitZone = data 
    },

    [actions.validation.LDZExitZoneDescription] (state, data) {
      state.LDZExitZoneDescription = data 
    },

    [mutations.validation.wtdtid] (state, data) {
      state.wtdtid = data
      localStorage.setItem('wtdtid', data)
    },
    [mutations.validation.multipleWaterMeter] (state, data) {
      state.multipleWaterMeter = data
      localStorage.setItem('multipleWaterMeter', data)
    },

    [mutations.validation.msndetails] (state, data) {
      state.msndetails = data
    },

    [mutations.validation.wtrCharges] (state, data) {
      state.wtrCharges = data
    },

    [actions.validation.WaterMeterMake] (state, data) {
      state.WaterMeterMake = data 
    },

    [actions.validation.WaterMeterSize] (state, data) {
      state.WaterMeterSize = data 
    },

    [actions.validation.WaterPropertySize] (state, data) {
      state.WaterPropertySize = data 
    },

    [actions.validation.WaterMeasureType] (state, data) {
      state.WaterMeasureType = data 
    },
    [mutations.validation.oppContactRoles] (state, data) {
      state.oppContactRoles = data
    },
    [mutations.validation.waterFixedCharges] (state, data) {
      state.waterFixedCharges = data
    },
    [mutations.validation.waterFixedChargesUnMetered] (state, data) {
      state.waterFixedChargesUnMetered = data
    },
    [mutations.validation.waterUnitCharges] (state, data) {
      state.waterUnitCharges = data
    },
    [mutations.validation.waterUnitClass] (state, data) {
      state.waterUnitClass = data
    },
  },

  getters: {
    site: state => state.site,
    gi: state => state.gi,
    ei: state => state.ei,
    et: state => state.et,
    gSuppliers: state => state.gSuppliers,
    eSuppliers: state => state.eSuppliers,
    retailWaterSuppliers: state => state.retailWaterSuppliers,
    wholesaleWaterSuppliers: state => state.wholesaleWaterSuppliers,
    supplyType: state => state.supplyType,
    transporterType: state => state.transporterType,
    accountOwners: state => state.accountOwners,
    accountTypes: state => state.accountTypes,
    LDZExitZone: state => state.LDZExitZone,
    LDZExitZoneDescription: state => state.LDZExitZoneDescription,
    wtdtid: state => state.wtdtid,
    waterCharges: state => state.wtrCharges,
    WaterMeterMake: state => state.WaterMeterMake,
    WaterMeterSize: state => state.WaterMeterSize,
    WaterPropertySize: state => state.WaterPropertySize,
    WaterMeasureType: state => state.WaterMeasureType,
    multipleWaterMeter: state => state.multipleWaterMeter
  }
}
