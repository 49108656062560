<!-- eslint-disable vue/no-v-html -->
<template>
  <span :style="{ top: posRelTop, left: posRelLeft, position: posRelTop ? 'relative' : null }" 
        :class="{ 'active' : isActive, 'has-hover-state' : hasHoverState, 'dark-hover-color': isDarkHoverBgColor, 'bg-color': hasHoverBg }" 
        v-html="iconHtml" 
  />
</template>
<script>
export default {
  props: {
    icon: {
      type: String,
      required: true
    },

    isActive: {
      type: Boolean,
      required: false,
      default: false
    },

    fill: {
      type: String,
      required: false,
      default: '#000'
    },

    size: {
      type: String,
      required: false,
      default: 'sm'
    },

    dimension: {
      type: Number,
      required: false,
      default: 0
    },

    hasHoverState: {
      type: Boolean,
      required: false,
      default: true
    },

    isDarkHoverBgColor: {
      type: Boolean,
      required: false,
      default: false
    },

    rotationAngle: {
      type: String,
      required: false,
      default: '0'
    },

    posRelTop: {
      type: String,
      required: false,
      default: '0'
    },

    posRelLeft: {
      type: String,
      required: false,
      default: '0'
    },

    hasHoverBg: {
      type: Boolean,
      required: false,
      default: false
    }
  },

  data () {
    return {
      iconHtml: ''
    }
  },

  watch: {
    icon () {
      this.render()
    },

    fill () {
      this.render()
    },

    size () {
      this.render()
    },

    isActive () {
      this.render()
    },

    rotationAngle () {
      this.render()
    }
  },

  mounted () {
    this.render()
  },

  methods: {
    render () {
      try {
        this.iconFile = require(`../../../assets/images/svg-icons/${this.icon}.svg`)
      } catch (e) 
      {
        console.log(e)
      }
      if (!this.iconFile) {
        return
      }
      const fillColor = this.fill || (this.isActive ? '#38ced2' : '#ccc')
      let dim = 20
      if (this.dimension > 0) {
        dim = this.dimension
      } else if (this.size === 'xs') {
        dim = 15
      } else if (this.size === 'md') {
        dim = 25
      } else if (this.size === 'lg') {
        dim = 30
      } else if (this.size === 'xl') {
        dim = 80
      }
      this.iconHtml = this.iconFile
        .replace(/\$fill/g, fillColor)
        .replace(/\$width/g, dim)
        .replace(/\$height/g, dim)
      if (this.rotationAngle) {
        this.iconHtml = this.iconHtml.replace('<svg ', `<svg style="transform: rotate(${this.rotationAngle});" `)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
#app {
  span.has-hover-state {
    display: inline-block;
    padding: 3px;
    padding-bottom: 0;
    background-color: transparent;
    cursor: pointer;
    border-radius: 4px;
    -webkit-transition: background-color 0.3s ease-out;
    -moz-transition: background-color 0.3s ease-out;
    -o-transition: background-color 0.3s ease-out;
    transition: background-color 0.3s ease-out;
    &:hover:not(.active) {
      background-color: #ececec;
    }
    &.bg-color {
      background-color: #ececec;
    }
    &.dark-hover-color:hover:not(.active) {
      background-color: #383f53;
    }
  }

  svg {
    transition: transform 0.3s ease-in-out;
  }
}
</style>
