
<template>
  <div :class="isFullwidth ? 'is-fullwidth' : ''">
 
    <div class="dropdown is-hoverable">
      <span aria-haspopup="true" aria-controls="dropdown-menu">
        <span v-if="!isselected" class="vs__dropdown-toggle select" style="width:376.5px;">
          Select Sites
        </span>
        <span v-else class="vs__dropdown-toggle select" style="width:376.5px;">
          <div class="field is-horizontal">
            <div style="width: 250px;overflow:hidden;">
              {{ firstsite }}
            </div>
            <div style="color: grey;">
              <i :class="{ 'fa fa-plus': ismoreplus}" /> {{ more }} 
            </div>
          </div>
        </span>
      </span>
     
      <div id="dropdown-menu" class="dropdown-menu" role="menu">
        <div class="dropdown-content">
          <ul style="width:376.5px;max-height:200px;overflow-y:auto;">
            <li class="vs__dropdown-option">
              <input 
                v-model="selectAll"
                type="checkbox"
                :disabled="disabled"
                :tabindex="tabindex" 
                :value="null"
                @click="reset()"
              >  Select All
            </li>
          
          
            <div>
              <li v-for="option in options" :key="option.Id" class="vs__dropdown-option">
                <input 
                  v-model="option.Checked"
                  type="checkbox"
                  :disabled="disabled"
                  :tabindex="tabindex" 
                  :value="option"
                  @change="select"
                >
                {{ option[label] }} 
              </li>
            </div>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MultiSelect',
  props: {
    options: {
      type: Array,
      required: false,
      default: () => []
    },     
    value: {
      type: [ String, Number ],
      required: false,
      default: null
    },
    label: {
      type: String,
      required: false,
      default: 'Name'
    },
    selectAll:{
      type: Boolean,
      required: false,
      default: false
    },
    id: {
      type: String,
      required: false,
      default: 'Id'
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false
    },
    isFullwidth: {
      type: Boolean,
      required: false,
      default: false
    },
    tabindex: {
      type: Number,
      required: false,
      default: null
    },
    showDefault: {
      type: Boolean,
      required: false,
      default: true
    },   
    defaultValue: {
      type: String,
      required: false,
      default: 'Select'
    }
  },
  data: () => ({
    isDropped: false ,
  }),
  computed: {  
        
    isselected () {
      const length = this.options.filter((item) => {
        return item.Checked
      }).length
      return length > 0
    },
    ismoreplus () {
      const length = this.options.filter((item) => {
        return item.Checked
      }).length
      if (length > 1)
      {
        return true
      }
      else {
        return false
      }
    },
    more ()
    {
      const length = this.options.filter((item) => {
        return item.Checked
      }).length
      if (length > 1)
      {
        return length-1  + ' More'
      }
      else {
        return ' '
      }
    },
    firstsite ()
    {
      const length = this.options.filter((item) => {
        return item.Checked
      }).length
      return length > -1 ? this.options[0].Name : ''
    },
    optionnames ()
    {
      return this.options
    }
  },
  methods: {
    dropIt () {
      this.isDropped = !this.isDropped
    },
    reset () {
      
      if (!this.selectAll){
        this.options = this.options.map(a => Object.assign({ Id:a.Id, Name:a.Name,ParentId:a.ParentId,Checked:true }))      
      }
      else
      {
        this.options = this.options.map(a => Object.assign({ Id:a.Id, Name:a.Name,ParentId:a.ParentId,Checked:false }))      
      }
      this.$emit('result',this.options)
      
    },
    select ()
    { 
      this.options = this.options.slice().sort(
        (firstObject) =>
          (firstObject.Checked) ? -1 : 1 )
      
      this.$emit('result', this.options)
      this.selectAll = false
    }
  }
}
</script>

<style lang="scss" scoped>
  .vs__dropdown-toggle,.select {
    select:focus {
      outline: 1px dotted;
    }
  }

  .slide-enter, .slide-leave-to{
    transform: scaleY(0);
  }
</style>

