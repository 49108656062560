var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "a",
    {
      directives: [
        {
          name: "clipboard",
          rawName: "v-clipboard:copy",
          value: _vm.value,
          expression: "value",
          arg: "copy"
        },
        {
          name: "clipboard",
          rawName: "v-clipboard:success",
          value: _vm.success,
          expression: "success",
          arg: "success"
        }
      ],
      ref: "copy",
      staticClass: "button is-light is-small vcopy"
    },
    [_c("i", { staticClass: "fa fa-copy" })]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }