import { actions, mutations } from '../constants'

export default {
  state: {
    alertMessage: null
  },

  actions: {
    [actions.alert.showAlert] ({ commit }, message) {
      commit(mutations.alert.showAlert, message)
    },

    [actions.alert.closeAlert] ({ commit }) {
      commit(mutations.alert.closeAlert)
    }
  },

  mutations: {
    [mutations.alert.showAlert] (state, message) {
      state.alertMessage = message
    },

    [mutations.alert.closeAlert] (state) {
      state.alertMessage = null
    }
  },

  getters: {
    alertMessage: state => state.alertMessage
  }
}
