<template>
  <div>
    <div :class="`notification is-light pos-rel ${className}`">
      {{ message }}
      <span class="closeable" @click="close()">
        ×
      </span>
    </div>
  </div>
</template>

<script>
import { actions } from '../../../vuex/constants'
export default {
  name: 'VAlert',
  props: {
    message: {
      type: [ Number, String, Object ], 
      required: true
    },
    isError: {
      type : Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    className () {
      return this.isError ? 'is-danger' : 'is-success'
    }
  },
  methods: {
    close () {
      this.$store.dispatch(actions.alert.closeAlert)
    }
  },
}
</script>

<style lang="scss" scoped>
#app {
  .notification.is-light {
    padding: 8px 15px;
    &.is-danger {
      color: #e73d4a;
      background-color: #feecf0;
    }
    &.is-success {
      color: #48c774;
      background-color: #d0efdb;
    }
  }
  .closeable {
    position: absolute;
    top: 3px;
    right: 11px;
    font-size: 21px; 
    color: #ccc;
    &:hover {
      color: #8c8c8c;
    }
  }
}
</style>