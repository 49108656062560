
import base from './base'
import report from './report'
import billautomation from './billautomation'
import more from './more'

const routes = [
  ...base,
  ...report,
  ...billautomation,
  ...more
]

export default routes