import store from '../vuex'
import { actions } from '../vuex/constants'

import Accounts from '@/components/account/Accounts'
const AccountDetail = () => import('@/components/Account/AccountDetail')
const AddAccount = () => import('@/components/account/AddAccount')

const Contacts = () => import('@/components/contact/Contacts')
const ContactDetail = () => import('@/components/contact/ContactDetail')
// const AddAccounts = () => import('@/components/contact/AddAccounts')

const Opportunities = () => import('@/components/opportunity/Opportunities')
const CROpportunity = () => import('@/components/opportunity/CROpportunity')
const AddCROpportunity = () => import('@/components/opportunity/AddCROpportunity')
const AMROpportunity = () => import('@/components/opportunity/AMROpportunity')
const AddAMROpportunity = () => import('@/components/opportunity/AddAMROpportunity')
const BDMOpportunity = () => import('@/components/opportunity/BDMOpportunity')
const AddBDMOpportunity = () => import('@/components/opportunity/AddBDMOpportunity')
const DECOpportunity = () => import('@/components/opportunity/DECOpportunity')
const AddDECOpportunity = () => import('@/components/opportunity/AddDECOpportunity')
const CarbonOpportunity = () => import('@/components/opportunity/CarbonOpportunity')
const AddCarbonOpportunity = () => import('@/components/opportunity/AddCarbonOpportunity')
const EduOpportunity = () => import('@/components/opportunity/EduOpportunity')
const AddEduOpportunity = () => import('@/components/opportunity/AddEduOpportunity')
const ESOSOpportunity = () => import('@/components/opportunity/ESOSOpportunity')
const AddESOSOpportunity = () => import('@/components/opportunity/AddESOSOpportunity')
const MidMarketOpportunity = () => import('@/components/opportunity/MidMarketOpportunity')
const AddMidMarketOpportunity = () => import('@/components/opportunity/AddMidMarketOpportunity')
const BusinessOpportunity = () => import('@/components/opportunity/BusinessOpportunity')
const AddBusinessOpportunity = () => import('@/components/opportunity/AddBusinessOpportunity')
const ESOSDeliveryOpportunity = () => import('@/components/opportunity/ESOSDeliveryOpportunity')
const AddESOSDeliveryOpportunity = () => import('@/components/opportunity/AddESOSDeliveryOpportunity')
const SECROpportunity = () => import('@/components/opportunity/SECROpportunity')
const AddSECROpportunity = () => import('@/components/opportunity/AddSECROpportunity')
const SDMPOpportunity = () => import('@/components/opportunity/SDMPOpportunity')
const AddSDMPOpportunity = () => import('@/components/opportunity/AddSDMPOpportunity')
const TenantOpportunity = () => import('@/components/opportunity/TenantOpportunity')
const AddTenantOpportunity = () => import('@/components/opportunity/AddTenantOpportunity')
const AddSite = () => import('@/components/site/AddSite')
const SiteDetail = () => import('@/components/site/SiteDetail')
const WaterMeter = () => import('@/components/water/Meter')
const WaterMeterAdd = () => import('@/components/water/AddMeter')
const WaterMeterId = () => import('@/components/water/Meter')
const WaterNewBill = () => import('@/components/water/NewBill')
const WaterViewBill = () => import('@/components/water/ViewBill')
const WaterBillHistory = () => import('@/components/water/BillHistory')
const WaterBillHistorySimple = () => import('@/components/water/BillHistorySimple')
const WaterTariff = () => import('@/components/water/Tariff')
const WaterTariffAdd = () => import('@/components/water/AddTariff')
const WaterViewCreditNote = () => import('@/components/water/ViewCN')

const GasMeter = () => import('@/components/gas/Meter')
const GasMeterAdd = () => import('@/components/gas/AddMeter')
const GasMeterId = () => import('@/components/gas/Meter')
const GasTariff = () => import('@/components/gas/Tariff')
const GasTariffAdd = () => import('@/components/gas/AddTariff')
const GasBillHistory = () => import('@/components/gas/BillHistory')
const GasBillHistorySimple = () => import('@/components/gas/BillHistorySimple')
const GasNewBill = () => import('@/components/gas/NewBill')
const GasViewBill = () => import('@/components/gas/ViewBill')
const GasViewCreditNote = () => import('@/components/gas/ViewCN')


const ElecMeter = () => import('@/components/elec/Meter')
const ElecMeterAdd = () => import('@/components/elec/AddMeter')
const ElecMeterid = () => import('@/components/elec/Meter')
const ElecTariff = () => import('@/components/elec/Tariff')
const ElecTariffAdd = () => import('@/components/elec/AddTariff')
const ElecBillHistory = () => import('@/components/elec/BillHistory')
const ElecBillHistorySimple = () => import('@/components/elec/BillHistorySimple')
const ElecNewBill = () => import('@/components/elec/NewBill')
const ElecViewBill = () => import('@/components/elec/ViewBill')
const ElecViewCreditNote = () => import('@/components/elec/ViewCN')
//01 Jul 2022: Mop DC / DA Changes. Senthil Raja
const ElecPricingHHMopdcda = () => import('@/components/elec/mopdcda')
const ElecPricingHHAddMopdcda = () => import('@/components/elec/addmopdcda')
const ElecPricingNHHAmr = () => import('@/components/elec/amr')
const ElecPricingNHHAddAmr = () => import('@/components/elec/addamr')
//18 Jul 2022.
const GasPricingAmr = () => import('@/components/gas/amr')
const GasPricingAddAmr = () => import('@/components/gas/addamr')

const ElecHHTariff = () => import('@/components/elec/HHTariff')
const ElecHHTariffAdd = () => import('@/components/elec/AddHHTariff')
const ElecHHNewBill = () => import('@/components/elec/NewBillHH')
const ElecViewHHBill = () => import('@/components/elec/ViewBillHH')

import Login from '@/components/shared/Login'
const NotFound = () => import('@/components/shared/NotFound')
const UnAuthorized = () => import('@/components/shared/UnAuthorized')

const Release = () => import('@/components/shared/Release')
const ReleaseNotes = () => import('@/components/shared/ReleaseNotes')
const Profile = () => import('@/components/user/Profile')
const Feedback = () => import('@/components/user/MyFeedbacks')

//Water Module . 10062022
/*const WaterDetails = () => import('@/components/water/waterDetails')
const WaterAddMeter = () => import('@/components/water/AddMeter')
const WaterTariff = () => import('@/components/water/Tariff')
const WaterAddTariff = () => import('@/components/water/AddTariff')
const WaterNewbill = () => import('@/components/water/NewBill')
const WaterBillHistory = () => import('@/components/water/BillHistory')
const WaterViewBill = () => import('@/components/water/ViewBill')*/

const routes = [
  {
    path: '/account',
    name: 'Account',
    component: Accounts
  },
  {
    path: '/account/:id',
    name: 'AccountDetail',
    component: AccountDetail
  },
  {
    path: '/opportunity',
    name: 'Opportunities',
    component: Opportunities
  }, 
  {
    path: '/AddCROpportunity',
    name: 'AddCROpportunity',
    component: AddCROpportunity
  },   
  {
    path: '/cropportunity/:id',
    name: 'CROpportunity',
    component: CROpportunity
  },
  {
    path: '/amropportunity/:id',
    name: 'AMROpportunity',
    component: AMROpportunity
  },
  {
    path: '/AddAMROpportunity',
    name: 'AddAMROpportunity',
    component: AddAMROpportunity
  },   
  {
    path: '/bdmopportunity/:id',
    name: 'BDMOpportunity',
    component: BDMOpportunity
  },  
  {
    path: '/AddBDMOpportunity',
    name: 'AddBDMOpportunity',
    component: AddBDMOpportunity
  },
  {
    path: '/carbonopportunity/:id',
    name: 'CarbonOpportunity',
    component: CarbonOpportunity
  },  
  {
    path: '/AddCarbonOpportunity',
    name: 'AddCarbonOpportunity',
    component: AddCarbonOpportunity
  },
  {
    path: '/decopportunity/:id',
    name: 'DECOpportunity',
    component: DECOpportunity
  },  
  {
    path: '/AddDECOpportunity',
    name: 'AddDECOpportunity',
    component: AddDECOpportunity
  },
  {
    path: '/esosopportunity/:id',
    name: 'ESOSOpportunity',
    component: ESOSOpportunity
  },  
  {
    path: '/AddESOSOpportunity',
    name: 'AddESOSOpportunity',
    component: AddESOSOpportunity
  },
  {
    path: '/eduopportunity/:id',
    name: 'EduOpportunity',
    component: EduOpportunity
  },  
  {
    path: '/AddEduOpportunity',
    name: 'AddEduOpportunity',
    component: AddEduOpportunity
  },
  {
    path: '/midmarketopportunity/:id',
    name: 'MidMarketOpportunity',
    component: MidMarketOpportunity
  },  
  {
    path: '/AddMidMarketOpportunity',
    name: 'AddMidMarketOpportunity',
    component: AddMidMarketOpportunity
  },
  {
    path: '/businessopportunity/:id',
    name: 'BusinessOpportunity',
    component: BusinessOpportunity
  },  
  {
    path: '/AddBusinessOpportunity',
    name: 'AddBusinessOpportunity',
    component: AddBusinessOpportunity
  },  
  {
    path: '/esosdeliveryOpportunity/:id',
    name: 'ESOSDeliveryOpportunity',
    component: ESOSDeliveryOpportunity
  },  
  {
    path: '/AddESOSDeliveryOpportunity',
    name: 'AddESOSDeliveryOpportunity',
    component: AddESOSDeliveryOpportunity
  },
  {
    path: '/secrOpportunity/:id',
    name: 'SECROpportunity',
    component: SECROpportunity
  },  
  {
    path: '/AddSECROpportunity',
    name: 'AddSECROpportunity',
    component: AddSECROpportunity
  },
  {
    path: '/sdmpOpportunity/:id',
    name: 'SDMPOpportunity',
    component: SDMPOpportunity
  },  
  {
    path: '/AddSDMPOpportunity',
    name: 'AddSDMPOpportunity',
    component: AddSDMPOpportunity
  },
  {
    path: '/tenantOpportunity/:id',
    name: 'TenantOpportunity',
    component: TenantOpportunity
  },  
  {
    path: '/AddTenantOpportunity',
    name: 'AddTenantOpportunity',
    component: AddTenantOpportunity
  },  
  {
    path: '/contact',
    name: 'Contact',
    component: Contacts
  },
  {
    path: '/contact/:id',
    name: 'ContactDetail',
    component: ContactDetail
  }, 
  {
    path: '/AddAccount',
    name: 'AddAccount',
    component: AddAccount
  },
  {
    path: '/account/:id/site',
    name: 'Site',
    component: AddSite
  },
  {
    path: '/site/:id',
    name: 'SiteDetail',
    component: SiteDetail,
    meta: { tabIndex: 1 }
  },
  {
    path: '/site/:id/water',
    name: 'WaterHome',
    component: WaterMeter,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/water/add',
    name: 'WaterMeterAdd',
    component: WaterMeterAdd,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/water/:wtdtid/newbill',
    name: 'WaterNewBill',
    component: WaterNewBill,
    meta: { tabIndex: 4 }
  },  
  {
    path: '/site/:id/water/:waterdetailsId',
    name: 'WaterMeterId',
    component: WaterMeter,
    meta: { tabIndex: 4 }
  },  
  {
    path: '/site/:id/water/:wtdtid/bill/:billId',
    name: 'WaterViewBill',
    component: WaterViewBill,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/water/:wtdtid/BillHistory',
    name: 'WaterBillHistory',
    component: WaterBillHistory,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/water/:wtdtid/billhistorysimple',
    name: 'WaterBillHistorySimple',
    component: WaterBillHistorySimple,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/water/:wtdtid/tariff',
    name: 'WaterTariff',
    component: WaterTariff,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/water/:wtdid/tariff/add',
    name: 'WaterTariffAdd',
    component: WaterTariffAdd,
    meta: { tabIndex: 4 }
  },     
  {
    path: '/site/:id/water/:wtdtid/cbill/:billId',
    name: 'WaterViewCreditNote',
    component: WaterViewCreditNote,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/gas',
    name: 'GasHome',
    component: GasMeter,
    meta: { tabIndex: 3 }
  },
  
  {
    path: '/site/:id/gas/add',
    name: 'GasMeterAdd',
    component: GasMeterAdd,
    meta: { tabIndex: 3 }
  },
  {
    path: '/site/:id/gas/:gasdetailsId',
    name: 'GasMeterId',
    component: GasMeterId,
    meta: { tabIndex: 3 }
  },
  {
    path: '/site/:id/gas/:gi/tariff',
    name: 'GasTariff',
    component: GasTariff,
    meta: { tabIndex: 3 }
  },
  {
    path: '/site/:id/gas/:gi/tariff/add',
    name: 'GasTariffAdd',
    component: GasTariffAdd,
    meta: { tabIndex: 3 }
  },
  {
    path: '/site/:id/gas/:gi/billhistory',
    name: 'GasBillHistory',
    component: GasBillHistory,
    meta: { tabIndex: 3 }
  },
  {
    path: '/site/:id/gas/:gi/billhistorysimple',
    name: 'GasBillHistorySimple',
    component: GasBillHistorySimple,
    meta: { tabIndex: 3 }
  },
  {
    path: '/site/:id/gas/:gi/newbill',
    name: 'GasNewBill',
    component: GasNewBill,
    meta: { tabIndex: 3 }
  },
  {
    path: '/site/:id/gas/:gi/bill/:billId',
    name: 'GasViewBill',
    component: GasViewBill,
    meta: { tabIndex: 3 }
  },
  {
    path: '/site/:id/gas/:gi/cbill/:billId',
    name: 'GasViewCreditNote',
    component: GasViewCreditNote,
    meta: { tabIndex: 3 }
  },
  {
    path: '/site/:id/elec',
    name: 'ElecHome',
    component: ElecMeter,
    meta: { tabIndex: 2 }
  },
  
  {
    path: '/site/:id/elec/add',
    name: 'ElecMeterAdd',
    component: ElecMeterAdd,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:elecdetailsId',
    name: 'ElecMeterid',
    component: ElecMeterid,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/tariff',
    name: 'ElecTariff',
    component: ElecTariff,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/hhtariff',
    name: 'ElecHHTariff',
    component: ElecHHTariff,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/tariff/add',
    name: 'ElecTariffAdd',
    component: ElecTariffAdd,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/hhtariff/add',
    name: 'ElecHHTariffAdd',
    component: ElecHHTariffAdd,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/billhistory',
    name: 'ElecBillHistory',
    component: ElecBillHistory,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/billhistorysimple',
    name: 'ElecBillHistorySimple',
    component: ElecBillHistorySimple,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/newbill',
    name: 'ElecNewBill',
    component: ElecNewBill,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/bill/:billId',
    name: 'ElecViewBill',
    component: ElecViewBill,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/hh/newbill',
    name: 'ElecHHNewBill',
    component: ElecHHNewBill,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/hh/bill/:billId',
    name: 'ElecViewHHBill',
    component: ElecViewHHBill,
    meta: { tabIndex: 2 }
  },
  {
    path: '/site/:id/elec/:ei/cbill/:billId',
    name: 'ElecViewCreditNote',
    component: ElecViewCreditNote,
    meta: { tabIndex: 2, isHH: false }
  },
  {
    path: '/site/:id/elec/:ei/hh/cbill/:billId',
    name: 'ElecViewCreditNote',
    component: ElecViewCreditNote,
    meta: { tabIndex: 2, isHH: true }
  },
  //01 Jul 2022: Mop Dc / Da changes. Senthil Raja.
  {
    path: '/site/:id/elec/:ei/pricing/mopdcda',
    name: 'ElecPricingHHMopdcda',
    component: ElecPricingHHMopdcda,
    meta: { tabIndex: 2, isHH: true }
  },
  {
    path: '/site/:id/elec/:ei/pricing/addmopdcda',
    name: 'ElecPricingHHAddMopdcda',
    component: ElecPricingHHAddMopdcda,
    meta: { tabIndex: 2, isHH: true }
  },
  {
    path: '/site/:id/elec/:ei/pricing/amr',
    name: 'ElecPricingNHHAmr',
    component: ElecPricingNHHAmr,
    meta: { tabIndex: 2, isNHH: true }
  },
  {
    path: '/site/:id/elec/:ei/pricing/addamr',
    name: 'ElecPricingNHHAddAmr',
    component: ElecPricingNHHAddAmr,
    meta: { tabIndex: 2, isNHH: true }
  },
  //GAS AMR : 18 Jul 2022
  {
    path: '/site/:id/gas/:gi/pricing/amr',
    name: 'GasPricingAmr',
    component: GasPricingAmr,
    meta: { tabIndex: 3 }
  },
  {
    path: '/site/:id/gas/:gi/pricing/addamr',
    name: 'GasPricingAddAmr',
    component: GasPricingAddAmr,
    meta: { tabIndex: 3 }

  },
  {
    path: '/login',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      store.dispatch(actions.auth.logout)
      next()
    }
  },
  {
    path: '/',
    name: 'Login',
    component: Login,
    beforeEnter: (to, from, next) => {
      store.dispatch(actions.auth.logout)
      next()
    }
  },
  {
    path: '/releasenotes',
    name: 'ReleaseNotes',
    component: ReleaseNotes
  },
  {
    path: '/releasenotes/:version',
    name: 'Release',
    component: Release
  },
  {
    path: '/myprofile',
    name: 'Profile',
    component: Profile
  },
  {
    path: '/myfeedbacks',
    name: 'Feedback',
    component: Feedback
  },
  {
    path: '/noaccess',
    name: 'UnAuthorized',
    component: UnAuthorized
  },
  {
    path: '*',
    name: 'NotFound',
    component: NotFound
  },

  //Water Module.
  /*{
    path: '/site/:id/water',
    name: 'WaterDetails',
    component: WaterDetails,
    meta: { tabIndex: 4 }
  }, 
  {
    path: '/site/:id/water/AddMeter',
    name: 'WaterAddMeter',
    component: WaterAddMeter,
    meta: { tabIndex: 4 }
  }, 
  {
    path: '/site/:id/water/:gi/Tariff',
    name: 'WaterTariff',
    component: WaterTariff,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/water/:gi/Tariff/addTariff',
    name: 'WaterAddTariff',
    component: WaterAddTariff,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/water/NewBill',
    name: 'WaterNewbill',
    component: WaterNewbill,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/water/BillHistory',
    name: 'WaterBillHistory',
    component: WaterBillHistory,
    meta: { tabIndex: 4 }
  },
  {
    path: '/site/:id/water/ViewBill/:wid/:billId',
    name: 'WaterViewBill',
    component: WaterViewBill,
    meta: { tabIndex: 4 }
  }, */ 
]

export default routes
