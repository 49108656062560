<template>
  <div v-if="content.length" class="vtimeline">
    <div class="timeline is-centered">
      <header class="timeline-header">
        <span class="tag is-medium">
          End
        </span>
      </header>
      <template v-for="item in content">
        <header v-if="item.index > 0 && item.year !== content[item.index - 1].year" :key="`h${item.index}`" class="timeline-header">
          <span class="tag is-info">
            {{ item.year }}
          </span>
        </header>
        <div :key="`t${item.index}`" class="timeline-item">
          <div class="timeline-marker is-icon is-primary">
            <i class="fa fa-flag" />
          </div>
          <div class="timeline-content">
            <p class="heading">
              {{ item.createdDate }}
              <small>
                {{ item.createdBy }}
              </small>
            </p>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </template>
      <header class="timeline-header">
        <span class="tag is-medium">
          Start
        </span>
      </header>
    </div>
  </div>
</template>

<script>
import { util } from '../../../services'
export default {
  name: 'VTimeline',
  props: {
    content: {
      type: Array,
      required: true
    },
  }
}
</script>
<style lang="scss">
  #app {
    .vtimeline {
      .timeline-header > .tag {
        font-size: 12px;
      }
      .timeline-content {
        font-size: 13px;
      }
      .heading {
        text-transform: capitalize;
        small {
          color: #337ab7;
        }
      }
    }
  }
</style>