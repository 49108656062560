import { BaseResolver } from './BaseResolver'
import { util } from '..'

export class WaterBillResolver extends BaseResolver {
  constructor () {
    super('WaterBill')
  }

  resolve (item) {

    const bill = item.Bill || {}
    const calculated = item.Calculated || {}
    const invoice = item.Invoice || {}
    return {
      Bill: {
        BillId: bill.BillId,
        AccountId: bill.AccountId ,
        SiteId: bill.SiteId,
        WaterDetailId: bill.WaterDetailId,
        SupplierId: bill.SupplierId,
        InvoiceDate: bill.TaxPointDate,
        InvoiceNo: bill.InvoiceNo,
        PeriodFrom: bill.FromDate ? new Date(bill.FromDate) : null,
        PeriodTo: bill.ToDate ? new Date(bill.ToDate) : null,
        InvoiceType: bill.InvoiceType,
        WFStanding: bill.WFStanding,
        WCUnitCharge: bill.WCUnitCharge,
        SFStanding: bill.SFStanding,
        SCUnitCharge: bill.SCUnitCharge,
        Other: bill.Other,
        NetTotal: bill.Net || 0,
        VatTotal: bill.Vat1 || 0,
        Vat2: bill.Vat2 || 0,
        Total: bill.Total || 0,
        Adjustments: bill.Adjustment || 0,
        TotalInvoicedkWh: bill.TotalInvoicedkWh || 0,
        Approved: bill.Approved,
        ApprovedBy: bill.ApprovedBy,
        ApprovedDate: bill.ApprovedDate,
        ReportId: bill.ReportId,
        ReportBy: bill.ReportBy,
        ReportDate: bill.ReportDate,
        ZendeskId: bill.ZendeskId,
        ZendeskStatus: bill.ZendeskStatus,
        CreditNoteId: bill.CreditNoteId,
        CreatedBy: bill.CreatedBy,
        CreatedDate: bill.CreatedDate,
        UpdatedBy: bill.UpdatedBy,
        UpdatedDate: bill.UpdatedDate,
        IsHistoricData: bill.IsOldSystemData ? bill.IsOldSystemData : false
      },
      Calculated: {
        BillId: calculated.BillId,
        WFStanding: calculated.WFStanding,
        WCUnitCharge: calculated.WCUnitCharge,
        SFStanding: calculated.SFStanding,
        SCUnitCharge: calculated.SCUnitCharge,
        
        Other: calculated.Other,
        NetTotal: calculated.Net || 0,
        VatTotal: calculated.Vat1 || 0,
        Vat2: calculated.Vat2 || 0,
        Total: calculated.Total || 0,
        Adjustments: calculated.Adjustment || 0,
        // Net: (calculated.Net + bill.Adjustment).toFixed(2),
        // Vat1: calculated.Vat1,
        // Vat2: calculated.Vat2,
        // Total: (calculated.Total + bill.Adjustment).toFixed(2),
        Ratio: util.getRatio(calculated.Net + invoice.Adjustment,invoice.Net)  
      },
      Inv: {
        BillId: invoice.BillId,
        WFStanding: invoice.WFStanding,
        WCUnitCharge: invoice.WCUnitCharge,
        SFStanding: invoice.SFStanding,
        SCUnitCharge: invoice.SCUnitCharge,
        
        Other: invoice.Other,
        NetTotal: invoice.Net || 0,
        VatTotal: invoice.Vat1 || 0,
        Vat2: invoice.Vat2 || 0,
        Total: invoice.Total || 0,
        Adjustments: invoice.Adjustment || 0,
        TotalInvoicedkWh: invoice.TotalInvoicedkWh || 0,
        // Net: (calculated.Net + bill.Adjustment).toFixed(2),
        // Vat1: calculated.Vat1,
        // Vat2: calculated.Vat2,
        // Total: (calculated.Total + bill.Adjustment).toFixed(2),
        Ratio: util.getRatio(invoice.Net + bill.Adjustment,bill.Net)
      },
      meters: (item.Readings || []).map(m => {
        return {
          WaterMeterId: m.WaterMeterId,
          MSN: m.MSN,
          DynamicWaterCharges: m.DynamicWaterCharges,
          DynamicSewerageCharges:m.DynamicSewerageCharges,
          Periods: (m.Periods || []).map(p => {
            return {
              BillTariffId: p.BillTariffId,
              TariffId: p.TariffId,
              StartDate: p.StartDate ? new Date(p.StartDate) : null,
              EndDate: p.EndDate ? new Date(p.EndDate) : null,
              MeterReading: (p.MeterReading || []).map(r => {
                return {
                  Id: r.Id,
                  TariffId: p.TariffId,
                  BillTariffId: r.BillTariffId,
                  StartDate: r.StartDate ? new Date(r.StartDate) : null,
                  EndDate: r.EndDate ? new Date(r.EndDate) : null,
                  From: r.From,
                  To: r.To,
                  ReadType: r.ReadType,
                  CorFactor: r.CorFactor,
                  CalValue: r.CalValue,
                  disabledDates: {
                    from: new Date(r.EndDate),
                    to: new Date(r.StartDate)
                  }
                }
              })
            }
          }),
          FixedCharges: (item.FACCharges || []).filter(x => x.Type === 1 && x.WaterMeterId === m.WaterMeterId).map(f => {
            return {
              Id: f.Id,
              WaterMeterId: f.WaterMeterId,
              Code: f.Code,
              ChargeName: f.ChargeName,
              Price: f.Price,
              Calculated: f.Calculated
            }
          }),
          ConsumptionCharges: (item.FACCharges || []).filter(x => x.Type === 2 && x.WaterMeterId === m.WaterMeterId).map(f => {
            return {
              Id: f.Id,
              WaterMeterId: f.WaterMeterId,
              Code: f.Code,
              ChargeName: f.ChargeName,
              Price: f.Price,
              Calculated: f.Calculated,
            }
          })
        }
      })
    }
  }
}
