import { BaseResolver } from './BaseResolver'
import { ContactRoleResolver } from './ContactRoleResolver'
import { util } from '../../services'

const contactRoleResolver = new ContactRoleResolver()

export class ESOSOpportunityResolver extends BaseResolver {
  constructor () {
    super('esosopportunity')
  }

  resolve (item) {
    return {
      OpportunityId: item.OpportunityId,
      BDMOpportunityId: item.BDMOpportunityId,
      OpportunityName: item.OpportunityName,
      OpportunityTypeId: item.OpportunityTypeId,
      OpportunityTypeName: item.OpportunityTypeName,
      AccountId: item.AccountId,
      AccountName: item.AccountName,
      OpportunityStage: item.OpportunityStage,
      OpportunityStageName: item.OpportunityStageName,
      OpportunityOwnerId: item.OpportunityOwnerId,
      OpportunityOwnerName: item.OpportunityOwnerName,
      ESOSOpportunityId: item.ESOSOpportunityId,
      Probability: item.Probability,
      ClosedDate: item.ClosedDate,
      OpportunityCurrency: item.OpportunityCurrency,
      NewCNBNumber: item.NewCNBNumber,
      CNBNumber: item.CNBNumber,
      DateFinalReportSigned: item.DateFinalReportSigned,
      AuditFinalMI: item.AuditFinalMI,
      ProfitValue: item.ProfitValue,
      TotalExpense: item.TotalExpense,
      TotalProjectCost: item.TotalProjectCost,
      NetProfit: item.NetProfit,
      NoOfSites: item.NoOfSites,
      NoOfNHHMeters: item.NoOfNHHMeters,
      NoOfHHMeters: item.NoOfHHMeters,
      NoOfGasMeters: item.NoOfGasMeters,
      NoOfVehicles: item.NoOfVehicles,
      DieselConsumption: item.DieselConsumption,
      PetrolConsumption: item.PetrolConsumption,
      LPGConsumption: item.LPGConsumption,
      NoOfCars: item.NoOfCars,
      NoOfVans: item.NoOfVans,
      Service: item.Service,
      AcceptanceSignedDate: item.AcceptanceSignedDate,
      EICforESOSP2: item.EICforESOSP2,
      Fee: item.Fee,
      ReasonForLoss: item.ReasonForLoss,
      CreatedBy: item.CreatedBy,
      CreatedDate: item.CreatedDate,
      LastModifiedBy: item.LastModifiedBy,
      LastModifiedDate: item.LastModifiedDate,

      ContactRoles: (item.ContactRoles || []).map(map => contactRoleResolver.resolve(map))     
    }
  }
}
