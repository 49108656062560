var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "select", class: _vm.isFullwidth ? "is-fullwidth" : "" },
    [
      _c(
        "select",
        {
          attrs: { disabled: _vm.disabled, tabindex: _vm.tabindex },
          domProps: { value: _vm.value },
          on: {
            change: function($event) {
              return _vm.$emit("change")
            },
            keydown: function($event) {
              if (
                !$event.type.indexOf("key") &&
                _vm._k($event.keyCode, "tab", 9, $event.key, "Tab")
              ) {
                return null
              }
              return _vm.$emit("ontab", _vm.tabindex)
            },
            input: function($event) {
              return _vm.$emit(
                "input",
                $event.target.value === "" ? null : $event.target.value
              )
            }
          }
        },
        [
          _vm.showDefault
            ? _c("option", { domProps: { value: null } }, [
                _vm._v("\n      " + _vm._s(_vm.defaultValue) + "\n    ")
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm._l(_vm.options, function(option, index) {
            return _c(
              "option",
              { key: index, domProps: { value: option[_vm.id] } },
              [_vm._v("\n      " + _vm._s(option[_vm.label]) + "\n    ")]
            )
          })
        ],
        2
      )
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }